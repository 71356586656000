import fetch from 'isomorphic-fetch';
import { API_ROOT, version } from '../../api-config';
import { fetch_options } from '../utils';

import { getPlatform, openLink } from "../../utils";
import moment from 'moment';

declare let window: any;
declare let LaunchReview: any;

let ratingTimerId;

export const USER_POINTS_TO_ASK_REVIEW = 50;

export const REVIEW_REQUESTED = 'review/REVIEW_REQUESTED';
export const REVIEW_RECEIVED = 'review/REVIEW_RECEIVED';


const MAX_DIALOG_WAIT_TIME_IOS = 5 * 1000; //max time to wait for rating dialog to display on iOS
const MAX_DIALOG_WAIT_TIME_ANDROID = 60 * 1000; //max time to wait for rating dialog to display on Android and be submitted by user

const os = getPlatform();
const RATE_URL =
    os === 'web' ? 'https://www.misoroban.com/rating/' : (os === 'ios' ? 'market ://details?id=com.enmotionvalue.misoroban' : 'https://play.google.com/store/apps/details?id=com.enmotionvalue.misoroban');


const initialState = {
    data: null,
    isFetching: false,
    lastUpdated: null,
    error: null
};


export default (state: any = initialState, action: any) => {
    switch (action.type) {
        case REVIEW_REQUESTED:
            return {
                ...state,
                isFetching: true,
                data: null,
                lastUpdated: null
            };

        case REVIEW_RECEIVED:
            return {
                ...state,
                isFetching: false,
                data: action.response,
                lastUpdated: action.receivedAt
            };

        default:
            return state;
    }
};

function clearRatingDialogTimer() {
    if (ratingTimerId) {
        clearTimeout(ratingTimerId);
        ratingTimerId = null;
    }
}

function showAlert(msg) {
    clearRatingDialogTimer();
    // hidePreloader();
    // navigator.notification.alert(msg);
    // console.log('showAlert:', msg);
}

export function launchReview() {
    if (window.cordova) {
        LaunchReview.launch(function () {
            showAlert("Successfully launched review app");
        }, function (err) {
            showAlert("Error launching review app: " + err);
        });
    } else {
        openLink(RATE_URL);
    }
}

export function launchRating() {
    console.log('launchRating....');

    if (window.cordova.platformId === "android") {
        ratingTimerId = setTimeout(ratingDialogNotShown, MAX_DIALOG_WAIT_TIME_ANDROID);
    }

    // Request the rating dialog
    LaunchReview.rating(function (status) {
        if (status === "requested") {
            if (window.cordova.platformId === "android") {
                showAlert("Displayed rating dialog");
            } else if (window.cordova.platformId === "ios") {
                console.log("Requested rating dialog");
                ratingTimerId = setTimeout(ratingDialogNotShown, MAX_DIALOG_WAIT_TIME_IOS);
                // showPreloader();
            }
        } else if (status === "shown") {
            showAlert("Rating dialog displayed");
        } else if (status === "dismissed") {
            showAlert("Rating dialog dismissed");
        }
    }, function (err) {
        showAlert("Error launching rating dialog: " + err);
    });
}

export function isRatingSupported() {
    var supported = LaunchReview.isRatingSupported();
    showAlert("Rating dialog supported: " + (supported ? "YES" : "NO"));
    return supported;
}

function ratingDialogNotShown() {
    var msg;
    if (window.cordova.platformId === "android") {
        msg = "Rating dialog outcome not received (after " + MAX_DIALOG_WAIT_TIME_ANDROID + "ms)";
    } else if (window.cordova.platformId === "ios") {
        msg = "Rating dialog was not shown (after " + MAX_DIALOG_WAIT_TIME_IOS + "ms)";
    }
    console.warn(msg);
    showAlert(msg);
}

export const saveReview = (review) => {
    return (dispatch, getState) => {

        const userStore: any = getState().user;
        const user = userStore.user;

        dispatch({
            type: REVIEW_REQUESTED,
        });

        const body = {
            data: review
        };

        const _fetch_options = {
            ...fetch_options,
            method: review.id ? 'PUT' : 'POST',
            body: JSON.stringify(body)
        };

        const url = review.id?`${API_ROOT}/reviews/${review.id}`:`${API_ROOT}/reviews`;
        return fetch(url, _fetch_options)
            .then(response => {
                if (response.ok) return response.json();
                else throw new Error(response);
            })
            .then(response => {
                console.log('saveReview received:', response);

                dispatch({
                    type: REVIEW_RECEIVED,
                    response: response.data,
                    receivedAt: moment()
                });

            })
            .catch(error => {
                console.error('saveResponses error fetching:', error, ':: Saving from local');
            });

    }
}