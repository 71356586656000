const es = {
  /* WELLCOME */
  start: 'Comenzar',
  makeurkids: 'Mejora la atención y la concentración con el sorobán',
  funlearning: 'Aprendizaje divertido',
  willlearntoopperate: 'Aprende a operar con los números de manera visual y divertida',
  dontworry: 'No os preocupéis',
  secure100pc: 'Esta aplicación es 100 % segura, sin molestos banners ni anuncios',

  /* REGISTER STEPS */
  language: 'Idioma',
  agelevel: 'Rango de edad',
  chooseagerange: 'Escoge tu rango de edad para adaptar el curso y los retos',
  chooseanavatar: 'Escoge tu avatar',

  wellcome: 'Bienvenidos/as',
  alwaysyouwant: 'Siempre que quieras recibirás información del progreso de tu hijo/ hija',
  yourname: '¿Cómo te llamas?',
  country: 'País',
  continue: 'Continuar',
  alwaysconnected: 'Conéctate desde cualquier móvil o desde la web con tu cuenta',
  changeyourname: '¿Cómo te quieres llamar?',

  loginacc: 'Iniciar sesión con mi cuenta',
  readAndAccept: 'He leído y acepto los',
  TOS: 'Términos y condiciones de uso.',

  morestars: 'MiSoroban no es solo para un único hijo/ hija, puedes añadir más protagonistas',
  howmanystars: '¿Cuántos protagonistas son ?',

  tellusname: 'Dinos cómo podemos llamar a las estrellas de esta aventura',
  gamer: 'Jugador',
  nickname: 'Apodo del jugador/a',
  age: 'Edad del jugador/a',
  other: 'Otro',

  loading: 'Cargando',
  almostthere: 'Ya casi estamos',
  preparing: 'Estamos preparando los últimos detalles para tu zona de aprendizaje',

  /* SECCIONES */
  learn: 'Aprende',
  practice: 'Practica',
  challengeyourself: 'Ponte a prueba',
  course: 'Curso',

  /* LEARN */
  levels: 'Niveles',
  upgradelevel: 'Asciende de nivel',
  paper: 'Nivel Papel',
  wood: 'Nivel Madera',
  iron: 'Nivel Hierro',
  wool: 'Nivel Lana',
  bronze: 'Nivel Bronce',
  clay: 'Nivel Arcilla',
  steel: 'Nivel Acero',
  ivory: 'Nivel Marfil',
  glass: 'Nivel Cristal',
  silver: 'Nivel Plata',
  pearl: 'Nivel Perla',
  coral: 'Nivel Coral',
  ruby: 'Nivel Rubí',
  sapphire: 'Nivel Zafiro',
  gold: 'Nivel Oro',
  emerald: 'Nivel Esmeralda',
  diamond: 'Nivel Diamante',

  /*UNITS*/
  numbers1to10: 'Números del 1 al 10',
  knowtheabacus: 'Conoce el soroban',
  numbersinabacus: 'Los números',
  quantitygame: 'Juego de cantidades',
  realtionsgame: 'Juego de relaciones',
  idunits: 'Identifica números',
  repunits: 'Representa números',
  visualizeabacus: 'Visualiza el soroban',

  easysumsandsubs: 'Suma y resta',
  sumsubsboard: 'Suma y resta en el tablero',
  practiceboard: 'Practica en el tablero',
  sumsubsabacus: 'Suma y resta en el ábaco',
  practiceabacus: 'Practica en el ábaco',
  visualizebeads: 'Juego de las cuentas',

  strengthenlearnt: 'Practica',
  matchchall: 'Reto de las parejas',
  baloonschall: 'Reto de globos',
  mentalsumchall: 'Reto de la suma mental',
  mentalsubchall: 'Reto de la resta mental',
  mentalsumsubchall: 'Reto de la suma y resta mental',

  sumswith5: 'Suma con el 5',

  subswith5: 'Resta con el 5',

  numberstill99: 'Las decenas',

  sumswith10: 'Suma con el 10',

  subswith10: 'Resta con el 10',

  numberstill999: 'Las centenas',

  sumsandsubswith2: 'Suma y resta 2 cifras',

  sumsandsubswith3: 'Suma y resta 3 cifras',

  multi1: 'Multiplica x 1 cifra',

  divi1: 'Divide / 1 cifra',

  sumsandsubswith4: 'Suma y resta 4 cifras',

  multi2: 'Multiplica x 2 cifra',

  divi2: 'Divide / 2 cifra',

  learnnumbers: 'Aprende los números',

  // Edad 2
  numbers1_2_3_figs: 'Números en el ábaco',
  idnumbers: 'Identifica números',
  repnumbers: 'Representa números',


  /* 1 */
  sumboard: 'Suma en el tablero',
  sumabacus: 'Suma en el ábaco',
  subsboard: 'Resta en el tablero',
  subsabacus: 'Resta en el ábaco',
  sumserieschall: 'Reto de las sumas',
  subsserieschall: 'Reto de las restas',

  tens: 'Decenas',
  idtens: 'Identifica decenas',
  reptens: 'Representa decenas',
  practiceboardI: 'Práctica simple en el tablero',
  practiceboardII: 'Práctica avanzada en el tablero',
  practiceabacusI: 'Práctica simple en el ábaco',
  practiceabacusII: 'Práctica avanzada en el ábaco',

  sumserieschallI: 'Reto de la sumas básicas',
  sumserieschallII: 'Reto de la sumas intermedias',
  sumserieschallIII: 'Reto de la sumas avanzadas',
  subsserieschallI: 'Reto de la restas básicas',
  subsserieschallII: 'Reto de la restas intermedias',
  subsserieschallIII: 'Reto de la restas avanzadas',

  hundreds: 'Centenas',
  idhundreds: 'Identifica centenas',
  rephundreds: 'Representa centenas',
  pathchall: 'Reto del camino',
  practicesums: 'Practica las sumas',
  practicesubs: 'Practica las restas',

  multi1x2: 'Multiplicaciones 1x2 cifras',
  practiceabacus1x2: 'Practica 1x2 en el ábaco',
  multi1x3: 'Multiplicaciones 1x3 cifras',
  practiceabacus1x3: 'Practica 1x3 en el ábaco',
  challmultI: 'Reto inicial de las multiplicaciones',
  challmultII: 'Reto avanzado de las multiplicaciones',

  baloonschallI: 'Reto de globos inicial',
  baloonschallII: 'Reto de globos intermedio',

  'divide2:1': 'Divisiones 2÷1 cifras',
  'practiceabacus2:1': 'Practica 2÷1 en el ábaco',
  'divide3:1': 'Divisiones 3÷1 cifras',
  'practiceabacus3:1': 'Practica 3÷1 en el ábaco',
  challdivI: 'Reto inicial de las divisiones',
  challdivII: 'Reto avanzado de las divisiones',

  idthous: 'Identifica millares',
  repthous: 'Representa millares',

  'divide:2': 'Divisiones entre 2 cifras',

  /* LOGIN */
  profile: 'Perfil',
  editProfile: 'Editar Perfil',
  forgotPsw: '¿Has olvidado tu contraseña?',
  login: 'Login',
  noId: 'Datos de acceso incorrectos.',
  loginWith: 'Iniciar sesión con',
  createProfile: 'Crea un usuario',

  email: 'Email',
  password: 'Contraseña',

  data: 'Datos',
  protagonists: 'Protagonistas',
  logout: 'Salir',
  deleteAccount: 'Borrar cuenta',
  sureDeleteAccount: '¿Está seguro que quiere borrar esta cuenta?',
  yes: 'Sí',
  no: 'No',

  loginerror: 'Los datos de acceso son incorrectos',

  /* REMEMBER */
  wesendpass: 'Te hemos enviado un mensaje a tu email con tu nueva contraseña',
  recovepass: 'Recupera tu contraseña',

  /* GAMEZ*/
  next: 'Siguiente',
  grade: 'Validar',
  ready: 'Listo',

  number: 'número',

  bravoin: '¡¡Bravo!!, has superado el reto en',
  surpassedin: 'has superado el reto en',
  great: 'Genial, sigue así!',
  nodiscorage: `No te desanimes, a por la próxima!`,

  rightanswer: 'Respuesta correcta!',
  wrong: 'Te has equivocado',
  yourwrong: 'Te has equivocado, el número es el',
  wronganswer: 'Oh!, Respuesta incorrecta, el número es el',
  theansweris: 'Respuesta incorrecta, la solución es',
  is: 'es',

  rightserie: 'Serie correcta!',

  havedone: 'Has realizado',
  exercises: 'ejercicios',
  havegotten: 'Has tenido',
  havemade: 'Has cometido',
  hits: 'aciertos',
  errors: 'errores',
  in: 'En un total de',
  seconds: 'segundos',
  repeat: 'Repetir',
  accept: 'Aceptar',
  timeout: '¡Te has quedado sin tiempo!',
  overcomechall: '¡Has superado el reto!',
  noovercomechall: 'No has superado el reto',

  bravo: '¡¡Bravo!!',
  oops: 'Oops :-(',
  haveovercomelevel: 'Has superado el nivel',
  nothaveovercomelevel: 'No has superado el nivel',
  watchexers: 'Ver ejercicios',
  watchlevels: 'Ver niveles',

  rightpath: 'Muy bien! Has encontrado el camino!',
  wrongpath: 'Oh!, Camino incorrecto. Te lo marcamos...',

  changeorientation: 'Cambia la orientación',

  nowcloseyesandvisualize: '...ahora cierra los ojos y visualiza el ábaco',

  module: 'resto',

  rightpos: 'Posición correcta :) !',
  wrongpos: 'Oh!, Posición incorrecta :/',

  verywellkeepon: 'Muy bien! sigue así!',
  yujumakeit: '¡¡Yuju!! Lo lograste :D !',
  almostyou: 'Ya casi lo tienes!',

  /* NEXT VERSION */
  levelnoaviable: 'Nivel no disponible',
  levelnoaviabletxt: 'Este nivel estará disponible en la siguiente versión de la app',
  rate: 'Valora MiSoroban',

  /* SUBSCRIPTION */
  try3days: 'Prueba MiSoroban 3 días gratis.',
  onallplans: 'En todos los planes.',
  cancelwhenyouwant: 'Cancela cuando quieras.',
  subscribe: 'Subscríbete',
  subscribetoactivate: 'Suscríbete para activar',
  subscribetoactivateGoogleplay: 'Activa en Google Play',
  subscribetoactivateAppleStore: 'Activa en Apple Store',
  morethan25: 'Más de 25 lecciones y decenas de ejercicios para disfrutar con el soroban.',
  startnow: 'Empezar ahora',
  autorenewable: 'Subscripción auto-renovable.',
  month: 'mes',
  months: 'meses',
  year: 'año',
  montly: 'Acceso Mensual',
  quaterly: 'Acceso Trimestral',
  yearly: 'Acceso Anual',
  managesubscription: 'Gestionar subscripción',
  howfreetrialworks: '¿Cómo funciona la prueba gratuita?',
  paymentDetailApple: 'Al confirmar la subscripción, tendrás 3 días gratis de prueba donde no se harán cargos (*). Tras el periodo de prueba gratuito, el pago se cargará a tu cuenta de iTunes. Las suscripciones se renovarán automáticamente a menos que la renovación automática se desactive al menos 24 horas antes del final del período actual. Tu cuenta será cargada de acuerdo a tu plan para la renovación dentro de las 24 horas previas al final del período actual. Puedes gestionar o desactivar la renovación automática en la configuración de tu cuenta de Apple ID en cualquier momento después de la compra.',
  onlyfirstsubscription: '(* válido sólo en tu primera subscripción)',
  termsandconditions: 'Términos & Condiciones',
  privacypolicy: 'Política de privacidad',
  ifalreadysubscribed: 'Si ya estás suscrito',
  restorePurchases: 'Restaurar',
  forparentsonly: `Esta área es solo para padres`,
  dragshapes: `Arrastra las formas en el siguiente orden`,
  airplane: 'avión',
  automobile: 'automóvil',
  cat: 'gato',
  notcorrect: 'No es correcto',
  tryagain: 'Nuevo intento',
  download_app: 'Activa la app',

  /* PRACTICES */
  practicesumsp: 'Sumas',
  practicesubstractions: 'Restas',
  practicemultiplications: 'Multiplicaciones',
  practicedivisions: 'Divisiones',
  easysums: '1 y 2 cifras',
  intermediatesums: '2 y 3 cifras',
  hardsums: '3 y 4 cifras',
  easysubs: '1 y 2 cifras',
  intermediatesubs: '2 y 3 cifras',
  hardsubs: '3 y 4 cifras',
  easymults: '1 x 2 cifras',
  easymults2: '1 x 3 cifras',
  intermediatemults: '2 x 2 cifras',
  hardmults: '2 x 3 cifras',
  easydivs: '2 / 1 cifras',
  easydivs2: '3 / 1 cifras',
  intermediatedivs: '3 / 2 cifras',
  harddivs: '4 / 2 cifras',

  /* CHALLENGES */
  getmorepoints: 'Consigue más puntos',
  completeoperations: 'Completa las operaciones',
  solveequation: 'Resuelve la ecuación',
  findpath: 'Encuentra el camino',
  challengevisualmem: 'Reta tu memoria visual',
  findvalue: 'Descubre el valor',
  youearned: 'Has conseguido',

  /* COURSE */
  numbers_sums_subtractions: 'Números, sumas y restas',
  multiply_divide: 'Multiplica y divide',
  numbers: 'Números',
  simplesumsubs: 'Sumas y restas sencillas',
  sumsubwith5: 'Sumas y restas con el 5',
  sumsubwith10: 'Sumas y restas con el 10',
  sumsub2c: 'Suma y resta con 2 cifras',
  sumsub3c: 'Suma y resta con 3 cifras',
  mult1c: 'Multiplicaciones 1 cifra',
  div1c: 'Divisiones 1 cifra',
  mult2c: 'Multiplicaciones 2 cifras',
  div2c: 'Divisiones 2 cifras',
  welcome: 'Bienvenid@',
  welcomefromtutor: 'Bienvenida del tutor',
  presentationModule1: ' Presentación Módulo 1',
  presentationModule2: ' Presentación Módulo 2',
  module1title: 'Módulo 1- Representar, identificar, sumar y restar',
  module1intro: `En este módulo aprenderás a mover las cuentas para representar números en el ábaco japonés, y también a sumar y restar con números de 1, 2 y 3 cifras. Los ejercicios además de ayudarte a redescubrir los números y las operaciones con esta herramienta, te ayudarán a trabajar el cálculo mental, la atención y la concentración.
Accede a la plataforma del curso con tu usuario y contraseña. Entra en la primera unidad del módulo y después de visualizar los videos para formarte, practica con los ejercicios. Cuando creas que estás preparado, accede a la siguiente unidad y avanza a tu ritmo.
Al final encontrarás una unidad especial donde puedes ponerte a prueba y ver todo lo que has aprendido.`,
  module2title: 'Módulo 2- Multiplicar y dividir',
  module2intro: `En este módulo aprenderás a realizar diferentes multiplicaciones y divisiones en el ábaco japonés. Aprenderás a estimar resultados y a visualizar el proceso de la operación.
Accede a las unidades de este segundo módulo. Comienza con la primera y avanza a la siguiente cuando consideres que estás preparado. En cada unidad, después de visualizar los vídeos practica con los ejercicios.
La última unidad te servirá para ponerte a prueba y ver todo lo que has aprendido.`,

  /* RESOURCES */
  resources: 'Recursos para enseñar',
  howtouseresources: 'Cómo usar estos recursos',
  intro: 'Introducción',
  discover: 'Descubre',

  /* RANKING */
  yourpoints: 'Tus puntos',
  yourposition: 'Tu posición',
  top10ranking: 'Top ranking', //'Top 10 ranking',

  /* REVIEW */
  issatisfied: '¿Te está gustando MiSoroban?',
  wannarate: '!Excelente¡ Nos gustaría que nos pudieras puntuar.',
  howcanimprove: 'Sentimos oir esto. ¿Cómo podemos mejorar?',
  tellushowimprove: 'Escribe tu respuesta...',
  thanksforyourfeedback: '!Gracias por tu feddback!',
  ok: 'OK',
  ratenow: 'Puntuar',
  later: 'Más tarde',
  send: 'Enviar',

  /* DAILY GOAL */
  dailygoal: 'Meta diaria',
  hi: 'Hola',
  selectdailygoal: 'Selecciona una meta diaria para mantener tu cerebro activo.',
  done: 'Hecho!',
  dailygoalreached: '¡¡Meta diaria alcanzada!!',
  youreachurdailygoal: 'has logrado tu meta diaria de',
  youwon: 'Has ganado',
  match: 'Logrado',
  youhavebeenworking: 'Has trabajado',
  outof: 'de',
  keepon: '¡Sigue así!',
  halfway: '¡Ya estás a mitad de camino!',
  almosthere: '¡Ya casi has llegado!',

  /* SHARE */
  sharemis: 'Comparte MiSoroban',
};

export default es;
