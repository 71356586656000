import React from 'react'

import { Row, Col, Button, Progress, Input } from 'antd';
import AbacusView from '../../base/abacus';
import Result from '../../base/result';
import { checkiOSVersion, generateRandomNumberSeries } from '../../../utils';

import './index.less';
import { t } from '../../../languages';
import ExerciseBlocker from '../../Utils/ExerciseBlocker';
import NumPad from '../../Utils/NumPad';
import { motion } from 'framer-motion';
import ChangeOrientation from '../../Utils/ChangeOrientation';

class WhatNumberIs extends React.Component {
    state = {
        eid: 0,
        series: [],
        current: 0,
        results: [],
        times: [],
        numberinbox: null,
        message: null,
        showresults: false,
        moved: 0
    }

    UNSAFE_componentWillMount() {
        /* const params = this.props.params;
        const series = params ? generateRandomNumberSeries(params.num, params.init, params.finish, null) : generateRandomNumberSeries(9, 1, 9, null);
        // console.log('WhatNumberIs.componentWillMount:', params, series);
        this.setState({ series }); */
        this.handleRepeat();
    }

    handleRate = () => {
        const current = this.state.current;
        const results = this.state.results;
        // console.log('WhatNumberIs.handleNext:', this.state);
        const currentNumber = this.state.series[current];
        const current_result = currentNumber === this.state.numberinbox ? 1 : 0;
        const message = current_result ? t(this.props.lang, 'rightanswer') : `${t(this.props.lang, 'yourwrong')} ${currentNumber}`;
        const message2 = current_result ? t(this.props.lang, 'great') : t(this.props.lang, 'nodiscorage');

        results.push(current_result);
        this.setState({
            results,
            message, message2
        });
    }

    handleNext = () => {
        const current = this.state.current;
        if (current < this.state.series.length - 1) {
            this.setState({
                current: current + 1,
                numberinbox: null,
                message: null,
                message2: null,
                moved: 0
            });
        } else {
            const num = this.props.params.num;
            const hits = this.state.results.reduce((acc, aR) => acc += aR);
            const errors = num - hits;
            const passed = errors > this.props.params.errors ? false : true;
            const uid = this.props.uid;
            const eid = this.props.eid;
            const coins = this.props.params.coins;

            this.props.saveResult(uid, `${uid}-${eid}`, { uid, eid, p: passed, h: hits, e: errors, c: (passed ? coins : 0) });
            this.setState({ showresults: true });
        }
        // this.nameInput.focus();

    }

    handleRepeat = () => {
        const params = this.props.params;
        let series = [];
        const configSeries = params.series;
        // console.log('configSeries:', configSeries);
        if (configSeries) {
            for (let idx = 0; idx < configSeries.length; idx++) {
                const aSerie = configSeries[idx];
                series = series.concat(generateRandomNumberSeries(aSerie.num, aSerie.init, aSerie.finish, null));
            }
        } else {
            series = params ? generateRandomNumberSeries(params.num, params.init, params.finish, null) : generateRandomNumberSeries(9, 1, 9, null);
        }

        // console.log('serires:', series);

        this.setState({
            series: series,
            current: 0,
            numberinbox: 0,
            results: [],
            message: null,
            message2: null,
            showresults: false,
            moved: 0
        });
    }

    handleChange = (val) => {
        this.setState({ numberinbox: parseInt(val), moved: 1 });
    }

    componentDidMount() {
        // this.nameInput.focus();
    }

    render() {
        // console.log('props:', this.props);

        const props = this.props;
        const currentNumber = this.state.series[this.state.current];
        const message = this.state.message;
        const message2 = this.state.message2;
        const rate = this.state.results[this.state.current];
        const messagestyle = message ? (!rate ? ' error' : ' valid') : '';
        const showresults = this.state.showresults;
        const percent = parseInt((this.state.current) / props.params.num * 100);

        const is_test = props.params.is_test ? props.params.is_test : false;
        const stillrows = Array.from(Array(props.params.rows).keys());

        const params = { ...props.params, rows: (props.params ? props.params.rows : 1), number: currentNumber, stillrows };
        // console.log('WhatNumberIs.render:', props, this.state, currentNumber, params, percent);

        const iOSVer = checkiOSVersion();
        // console.log('iOSVer:', iOSVer);
        const scaleTpApply = (iOSVer === 0 || iOSVer > 14) ? 2 : 1;

        if (!showresults) {
            return (
                <Row className="exercise">
                    <ChangeOrientation to={'portrait'} />

                    {!is_test ? <Col span={22} className='progress-bar'>
                        <Progress percent={percent} showInfo={false} />
                    </Col> : null}

                    <Col span={24} className="exercise-body">
                        <section className="whatnumberis">
                            <ExerciseBlocker show={messagestyle} />
                            <article className="abacus-cover animated bounceIn" key={currentNumber}>
                                <motion.div
                                    animate={{ scale: scaleTpApply }}
                                    transition={{ duration: 1 }}
                                    className="the-number"
                                >
                                    <AbacusView params={params} isactive={false} hidelatframe={true} hideheaderlabels={true} />
                                </motion.div>
                            </article>
                            <Row className="input-number">
                                <Col span={24}>
                                    <NumPad clickHandler={(val) => { this.handleChange(val); }} clear={!this.state.moved} />
                                </Col>
                            </Row>
                        </section>
                    </Col>
                    <Col span={24}>
                        <Row className={'botones' + messagestyle}>
                            <Col span={24} className={'message'}>{message}</Col>
                            {!message ? <Col span={24}><Button key="submit" type="primary" onClick={this.handleRate} style={{ 'width': '90%' }} disabled={!this.state.moved}>{t(props.lang, 'grade')}</Button></Col> : null}
                            {message ? <Col span={24}><Button key="submit" type="primary" onClick={this.handleNext} style={{ 'width': '90%' }}>{t(props.lang, 'next')}</Button></Col> : null}
                            {message2 ? <Col span={24} className={'message'}>{message2}</Col> : null}
                        </Row>
                    </Col>
                </Row >
            );
        } else {
            return (
                <Result params={props.params} results={this.state.results} handleRepeat={this.handleRepeat} handleCancel={props.handleCancel} lang={props.lang} />
            );
        }
    };
}

export default WhatNumberIs;

