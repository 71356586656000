/* eslint no-eval: 0 */
import React, { useEffect, useState } from 'react'

import { Row, Col, Button, Progress } from 'antd';
import AbacusView from '../../../base/abacus';
import Result from '../../../base/result';
import { generateEquationSeries, isPortrait } from '../../../../utils';

import './index.less';
import { motion } from 'framer-motion';
import { t } from '../../../../languages';
import ExerciseBlocker from '../../../Utils/ExerciseBlocker';
import ChangeOrientation from '../../../Utils/ChangeOrientation';
import MyTimer from '../../../Utils/time/MyTimer';

const Equation = (props) => {

    const [eid, setEid] = useState(0);
    const [series, setSeries] = useState([]);
    const [current, setCurrent] = useState(0);
    const [results, setResults] = useState([]);
    const [numberinbox, setNumberinbox] = useState(0);
    const [message, setMessage] = useState(null);
    const [message2, setMessage2] = useState(null);
    const [showresults, setShowresults] = useState(false);
    const [moved, setMoved] = useState(0);

    const [times, setTimes] = useState([]);
    const [stopTime, setStopTime] = useState(false);
    const [isTimeout, setIsTimeout] = useState(false);


    useEffect(() => {
        handleRepeat();
    }, []);

    const handleRate = () => {
        const currEq = series[current];
        const currentNumber = eval(currEq);

        const current_result = currentNumber === numberinbox ? 1 : 0;

        const message = current_result ? `${t(props.lang, 'rightanswer')}` : `${t(props.lang, 'theansweris')} ${currentNumber}`;
        const message2 = current_result ? t(props.lang, 'great') : t(props.lang, 'nodiscorage');

        results.push(current_result);

        setResults(results);
        setMessage(message);
        setMessage2(message2);

        setStopTime(true);
    }

    const handleNext = () => {
        if (current < series.length - 1) {
            setCurrent(current + 1);
            setNumberinbox(0);
            setMessage(null);
            setMessage2(null);
            setMoved(0);
            setStopTime(false);

        } else {
            const num = props.params.num;
            const hits = results.reduce((acc, aR) => acc += aR);
            const errors = num - hits;
            const passed = errors > props.params.errors ? false : true;
            const uid = props.uid;
            const eid = props.eid;
            const coins = props.params.coins;

            const is_test = props.params.is_test ? props.params.is_test : false;

            const totalTime = times.reduce((acc, aT) => acc += aT, 0);
            props.saveResult(uid, `${uid}-${eid}`, { uid, eid, p: passed, h: hits, e: errors, time: totalTime, c: (passed ? coins : 0) }, times, false);

            if (!is_test) {
                setShowresults(true);
            }
        }
    }

    const handleRepeat = () => {

        const params = props.params;
        const init=params.init | 21;
        const finish=params.finish | 99;
        
        const series = generateEquationSeries(params.num, params.ops, init, finish, params.permOps);

        // console.log('Equation series:', series);

        setSeries(series);
        setCurrent(0);
        setResults([]);
        setNumberinbox(0);
        setMessage(null);
        setMessage2(null);
        setShowresults(false);
        setMoved(0);

        setStopTime(false);
        setTimes([]);
        setIsTimeout(false);
    }

    const handleChange = (num) => {
        setNumberinbox(num);
        setMoved(1);
    }

    const handleStop = (time) => {
        const lTimes = [...times];
        lTimes.push(time);
        setTimes(lTimes);
    }

    const handleExpire = () => {

        const num = props.params.num;
        const hits = results && results.length > 0 ? results.reduce((acc, aR) => acc += aR) : 0;
        const errors = num - hits;
        const passed = errors > props.params.errors ? false : true;
        const uid = props.uid;
        const eid = props.eid;

        const is_test = props.params.is_test ? props.params.is_test : false;

        props.saveResult(uid, `${uid}-${eid}`, { uid, eid, p: passed, h: hits, e: errors, time: props.params.timeout }, [], true);

        if (!is_test) {
            setTimes([props.params.timeout]);
            setStopTime(true);
            setIsTimeout(true);
            setShowresults(true);
        }
    }

    if (!showresults) {
        const currentNumber = series[current];

        // console.log('Equation.render....', currentNumber, eval(currentNumber));

        const rate = results[current];
        const messagestyle = message ? (!rate ? ' error' : ' valid') : '';
        const percent = parseInt((current) / props.params.num * 100);

        const is_test = props.params.is_test ? props.params.is_test : false;

        const rows = props.params.rows || 3;

        const paramsToPass = { ...props.params, rows, number: numberinbox };

        const portrait = isPortrait();

        const time = new Date();
        time.setSeconds(time.getSeconds() + props.params.timeout);

        return (
            <Row className="exercise">

                <ChangeOrientation to={'portrait'} />

                {!is_test ? <Col span={22} className='progress-bar'>
                    <div className='progress'><Progress percent={percent} showInfo={false} /> </div>
                </Col> : null}

                <Col span={24} className='exercise-timer'>
                    <MyTimer expiryTimestamp={time} stopTime={stopTime} handleExpire={handleExpire} handleStop={(t) => handleStop(t)} />
                </Col>

                <Col span={24} className="exercise-body">
                    <ExerciseBlocker show={messagestyle} />
                    <section className="equationnumber">
                        <article className="number" key={currentNumber}>
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1, scale: 1.4 }}
                                transition={{
                                    delay: 0.3,
                                    duration: 1,
                                    ease: [0, 0.71, 0.2, 1.01],
                                    scale: {
                                        type: "spring",
                                        damping: 3,
                                        stiffness: 100,
                                        restDelta: 0.001
                                    }
                                }}
                                className="the-number"
                            >
                                <span>{currentNumber?.replace('*','x')}</span>
                                <br />
                                <span className='equals'> =</span><span className='interrogant'></span>
                            </motion.div>
                        </article>

                        <AbacusView params={paramsToPass} isactive={true} hidelatframe={true} hideheaderlabels={true} onChange={handleChange} />

                    </section>
                </Col>
                {portrait || message || moved ? <Col span={24} className={'botonera'}>
                    <Row className={'botones equationnumber' + messagestyle}>
                        <Col span={portrait ? 24 : 12} className={'message'}>{message}</Col>
                        {!message ? <Col span={24}><Button key="submit" type="primary" onClick={handleRate} style={{ 'width': '90%' }} disabled={!moved}>{t(props.lang, 'grade')}</Button></Col> : null}
                        {message ? <Col span={portrait ? 24 : 12}><Button key="submit" type="primary" onClick={handleNext} style={{ 'width': '90%' }}>{t(props.lang, 'next')}</Button></Col> : null}
                        {message2 ? <Col span={portrait ? 24 : 12} className={'message'}>{message2}</Col> : null}
                    </Row>
                </Col> : null}
            </Row >
        );
    } else {
        return (
            <Result params={props.params} results={results} show_time={true} times={times} is_timeout={isTimeout} handleRepeat={handleRepeat} handleCancel={props.handleCancel} lang={props.lang} />
        );
    }

}

export default Equation;

