import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Select, Input, Spin, notification } from 'antd';

import { t } from '../../../languages/index';

import { User } from '../../../models/user';
import { getMobileOperatingSystem } from '../../../utils';

const { Option } = Select;

const options = [5, 6, 7, 8, 9, 10, 11, 12];

const openNotificationWithIcon = (type, message, description, closeCallback) => {
    notification[type]({
        message: message,
        description: description,
        duration: 2,
        key: 'registernotification',
        onClose: closeCallback
    });
};

const Step3 = ({ lang, user, save, isLoading, saved, error }) => {

    if (error) openNotificationWithIcon('error', 'No ha sido posible identificarse. Revisa tus datos', '', () => { });


    const numChildren = user && user.children ? user.children.length : 0;
    const initData: any = [];
    for (let idx = 0; idx < numChildren; idx++) {
        initData[idx] = { name: '', age: 0 };
    }

    const [data, setData] = useState(initData);
    const [valid, setValid] = useState(false);

    const validateForm = () => {
        let valid = true;
        if (!data) valid = false;
        else for (let idx = 0; idx < data.length; idx++) {
            const aChild = data[idx];
            if (!(aChild.name && aChild.age)) {
                valid = false;
                break;
            }
        }

        setValid(valid);
    }

    const updateData = (idx, value, item) => {
        const newData = [...data];
        newData[idx][item] = value;
        setData(newData);
        validateForm();
    }

    const saveStep = () => {
        const aUser: User = user;
        console.log('Step3 aUser:', aUser, data);
        aUser.children = data;
        aUser.source = getMobileOperatingSystem();
        console.log('Step3 final aUser:', aUser);
        save(aUser);
    }

    if (!user) return <Redirect to="/register/step1" />
    else if (saved) return <Redirect to="/register/step4" />
    else return (
        <section className="register-boy">
            {isLoading ? <Spin size="large" className="spinner" /> : null}
            <h2>{t(lang, 'wellcome')}</h2>
            <p>{t(lang, 'tellusname')}</p>
            <form>
                <section>
                    {user && user.children ? user.children.map((aC, idx) => <article key={idx}>
                        <div className="jugador1">
                            <span><hr /></span>
                            <span>{t(lang, 'gamer')} {idx + 1}</span>
                            <span><hr /></span>
                        </div>
                        <div>
                            <Input placeholder={t(lang, 'nickname')} className="apodo" value={data[idx].name} onChange={(e) => updateData(idx, e.target.value, 'name')} />

                            <h3 className="age-h3">{t(lang, 'age')}</h3>
                            <Select defaultValue="" placeholder={t(lang, 'age')} className="edad" onChange={(val) => updateData(idx, val, 'age')}>
                                {options.map(aO => <Option key={aO} value={aO}>{aO}</Option>)}
                                <Option value="other">{t(lang, 'other')}</Option>
                            </Select>
                        </div>
                    </article>) : null}
                </section>
                <div>
                    <Button type="primary" disabled={!valid} onClick={saveStep}>{t(lang, 'continue')}</Button>
                </div>
            </form>
        </section>
    );
};
export default Step3;