import { Collapse, Spin } from 'antd';
import { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getMobileOperatingSystem, isValidActiveClient, userHasSubscription } from '../../utils';

import ModalLoader from '../../components/Course/ModalLoader';

import { loadResponses, saveResponses } from '../../modules/response';

import plan from '../../data/plan';

import showLevelTest from '../../components/Course/LevelTest';
import showLevelUnits from '../../components/Course/LevelUnits';
import BottomMenu from '../../components/Menu';


import { Redirect, RouteComponentProps } from 'react-router';
import SectionHeader from '../../components/Utils/SectionHeader';
import { User } from '../../models/user';
import { loadSubscriptions } from '../../modules/subscription';
import { lockPortrait, unlockScreen } from '../../utils/screen';

import SubscribeButton from '../../components/Subscription/SubscribeButton';
import { recordPageGA } from '../../modules/ga';
import './index.less';

import { useHistory } from "react-router-dom";
import { getUserData } from '../../modules/user';

const innerHeight = window.innerHeight;

interface IProps extends RouteComponentProps<any> {
  user: User;
  results: any;
  lang: string;
  saveUser: (user: User) => void;
  loadResponses: (activeChild: any) => void;
  saveResponses: (uid, ex, result) => void;
  isLoading: boolean;
  saved: boolean;
  error: boolean;
  transactions: any;
  products: any;
  loadSubscriptions: () => void;
  getUserData: () => void;
}

const LIMITLEVEL = 3;
const LIMITUNIT = 0;
const HASSUBSCRIPTION = false;


const moveToScrollPoition = () => {
  const courseElem = document.querySelector('.course');
  const lastScrPos = localStorage.getItem("my-last-scroll-position");
  if (courseElem && lastScrPos) {
    // console.log('load lastScrPos:', lastScrPos);
    courseElem.scrollTop = parseInt(lastScrPos);
  }
}

const Learn = ({
  user,
  results,
  lang,
  saveUser,
  loadResponses,
  saveResponses,
  isLoading,
  saved,
  error,
  transactions,
  products,
  loadSubscriptions,
  getUserData
}: IProps) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const [currentPlan, setcurrentPlan]: [any, any] = useState(null);
  const [hasSubscription, setHasSubscription]: [any, any] = useState(HASSUBSCRIPTION);
  const [limitUnit, setLimitUnit]: [any, any] = useState(LIMITUNIT);

  const history = useHistory();

  useEffect(() => {
    // console.log('Learn useEffect[]:', user);

    // getUserData();

    recordPageGA('/course');

    /* const activeChild = user ? (user.isParent ? user.activeChild : user.id) : null;          

    if (activeChild) {
      loadResponses(activeChild);
    } */

    if (user) {
      loadResponses(user.id);
      setcurrentPlan(plan[user.age ? user.age : 1]);
    }

    // load products for ios
    // console.log('Learn: products::', products, 'transactions::', transactions);
    const os = getMobileOperatingSystem().toLocaleLowerCase();
    if (/* os=='ios' &&  */!products) loadSubscriptions();

  }, []);

  const evalHasSubscription = () => {
    let hasSubscription = userHasSubscription(transactions, user);
    setHasSubscription(hasSubscription);
    setLimitUnit(hasSubscription ? 0 : LIMITUNIT)
  }

  useEffect(() => {
    // console.log('useEffect[transactions]...');
    evalHasSubscription();
  }, [transactions]);

  useEffect(() => {
    // console.log('useEffect[user]...');
    evalHasSubscription();
  }, [user]);


  useEffect(() => {
    // console.log('Learn useEffect[results]:', results);
    moveToScrollPoition();
  }, [results]);

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (modalVisible) {
        setModalVisible(false);
        return false; // Prevent navigation
      }
    });

    return () => {
      unblock();
    };
  }, [modalVisible, setModalVisible, history]);

  const showModal = cont => {
    setModalContent(cont);
    setModalVisible(true);
  };

  useEffect(() => {
    if (!modalVisible) unlockScreen();
  }, [modalVisible]);

  const handleOk = e => {
    // console.log('Learn.handleOk', e);
    setModalVisible(false);
  };

  const handleCancel = e => {
    // console.log('Learn.handleCancel', e);
    setModalVisible(false);
    lockPortrait();
  };

  const handleAccChange = panel => { };

  const userId = user ? user.id : null;
  const current_level = results ? results['current_level'] : null;
  const currentLevel = (current_level && currentPlan) ? currentPlan.find(aL => aL.level === current_level) : null;
  const activeKey = (currentLevel && currentLevel.open) ? (results && results['current'] ? results['current'] : '') : '';

  const divStyle: any = {
    // height: `${innerHeight - 53}px`,
    overflowX: 'hidden',
    overflowY: 'scroll',
    paddingBottom: '53px'
  };

  // console.log('Learn: current_level:', current_level);
  // console.log('Learn: trace....', isLoading, results, currentPlan);

  if (!user) return <Redirect to="/profile/login" />;
  else
    return (
      <section className='MiSoroban'>
        <ModalLoader
          title={modalTitle}
          visible={modalVisible}
          handleOk={handleOk}
          handleCancel={handleCancel}
          content={modalContent}
          className="exercise-modal"
        />

        <div style={divStyle} className="learn">
          <div className='mis-wrapper'>
            <SectionHeader section={'learn'} />


            {isLoading ? <Spin size="large" className="spinner" /> : null}
            {!results || !currentPlan ? null : currentPlan.map((aP, idx) => {
              const current_level = results.current_level;
              // console.log('current_level:', idx, current_level, ':: hasSubscription:', hasSubscription, 'aP.level:', aP.level);

              return (
                <article className="level" data-level={idx + 1} key={idx}>
                  {aP.level == LIMITLEVEL && !hasSubscription && current_level >= LIMITLEVEL - 1 ? (
                    <SubscribeButton lang={lang} show={hasSubscription} />
                  ) : null}

                  <Collapse
                    key={idx}
                    accordion
                    bordered={false}
                    defaultActiveKey={activeKey}
                    onChange={handleAccChange}>
                    {showLevelUnits(
                      aP,
                      showModal,
                      handleCancel,
                      results,
                      saveResponses,
                      lang,
                      limitUnit,
                      (idx < LIMITLEVEL - 1 ? true : hasSubscription)
                    )}
                    {showLevelTest(
                      aP,
                      showModal,
                      handleCancel,
                      results,
                      saveResponses,
                      lang,
                      (idx < LIMITLEVEL - 1 ? true : hasSubscription)
                    )}
                  </Collapse>

                  <div
                    className={`level-separator ${(current_level > aP.level && hasSubscription) ? 'passed' : ''
                      }`}>
                    <span key="1"></span>
                    <span key="2">{aP.level}</span>
                    <span key="3"></span>
                  </div>
                </article>
              );
            })}
          </div>
        </div>

        <BottomMenu section={'learn'} />
      </section>
    );
};

const mapStateToProps = ({ user, response, subscription }) => ({
  user: user.user,
  // results: user.results,
  results: response.data,
  isLoading: response.isFetching,
  lang: user.lang,
  products: subscription.products,
  transactions: subscription.transactions
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadResponses,
      saveResponses,
      loadSubscriptions,
      getUserData
    },
    dispatch
  );

export default memo(connect(mapStateToProps, mapDispatchToProps)(Learn));
