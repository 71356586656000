import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux';

import user from './user';
import response from './response';
import aclass from './aclass';
import subscription from './subscription';
import review from './review';

export default combineReducers({
  router: routerReducer,
  user,
  aclass,
  response,
  subscription,
  review
})
