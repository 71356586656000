import React from 'react';
import { Link } from 'react-router-dom';

const NoMatch = () => (
    <section>
        <h2>Get Lost</h2>
        <p>
            <Link to="/">Go to Home </Link>
        </p>
    </section>
);

export default NoMatch;

