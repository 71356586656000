import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Collapse, Spin } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { openBrowser } from '../../InAppBrowser';
import { getMobileOperatingSystem, isValidActiveClient } from '../../utils';

import ModalLoader from '../../components/Course/ModalLoader';

import { loadResponses, saveResponses } from '../../modules/response';

import challenges from '../../data/challenges';
import { t } from '../../languages';

import showLevelUnits from '../../components/Course/LevelUnits';
import BottomMenu from '../../components/Menu';

import '../Learn/index.less';
import './index.less';

import { Redirect, RouteComponentProps } from 'react-router';
import UserRanking from '../../components/Challenge/UserRanking';
import SectionHeader from '../../components/Utils/SectionHeader';
import { User } from '../../models/user';
import { recordPageGA } from '../../modules/ga';
import { lockPortrait, unlockScreen } from '../../utils/screen';

const innerHeight = window.innerHeight;

interface IProps extends RouteComponentProps<any> {
  user: User;
  results: any;
  lang: string;
  saveUser: (user: User) => void;
  loadResponses: (activeChild: any) => void;
  saveResponses: (uid, ex, result) => void;
  isLoading: boolean;
  saved: boolean;
  error: boolean;
  transactions: any;
}

const LIMITLEVEL = 20;
const LIMITUNIT = 0;
const HASSUBSCRIPTION = false;


const rateUrl =
  getMobileOperatingSystem() == 'web'
    ? 'market://details?id=com.enmotionvalue.misoroban'
    : 'https://play.google.com/store/apps/details?id=com.enmotionvalue.misoroban';

const moveToScrollPoition = () => {
  const courseElem = document.querySelector('.course');
  const lastScrPos = localStorage.getItem("my-last-scroll-position");
  if (courseElem && lastScrPos) {
    // console.log('load lastScrPos:', lastScrPos);
    courseElem.scrollTop = parseInt(lastScrPos);
  }
}

const WarningLevel = ({ lang }) => (
  <article className="warning-level">
    <h5 className="title">
      <ExclamationCircleOutlined /> {t(lang, 'levelnoaviable')}
    </h5>
    <div>{t(lang, 'levelnoaviabletxt')}</div>
    <Button ghost onClick={() => openBrowser(rateUrl)}>
      {t(lang, 'rate')}
    </Button>
  </article>
);

const Challenge = ({
  user,
  results,
  lang,
  saveUser,
  loadResponses,
  saveResponses,
  isLoading,
  saved,
  error,
  transactions
}: IProps) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const [currentPlan, setcurrentPlan]: [any, any] = useState(null);
  const [hasSubscription, setHasSubscription]: [any, any] = useState(HASSUBSCRIPTION);
  const [limitUnit, setLimitUnit]: [any, any] = useState(LIMITUNIT);

  useEffect(() => {
    recordPageGA('/challenge');

    // console.log('Challenge useEffect[]:', user);

    /* const activeChild = user ? (user.isParent ? user.activeChild : user.id) : null;
          
    if (activeChild) {
      loadResponses(activeChild);
    } */

    if (user) {
      loadResponses(user.id);
      setcurrentPlan(challenges[user.age ? user.age : 1]);
    }

  }, []);

  useEffect(() => {
    const isActiveClient = isValidActiveClient(user);
    // console.log('Challenge useEffect[transactions]:', transactions, user, isActiveClient);

    /* REAL */
    let hasSubscription = (transactions && transactions.length > 0) || isActiveClient;

    /* test NO SUBSCRIPTIONS */
    // hasSubscription = false;


    /* test with SUBSCRIPTIONS */
    // hasSubscription = true;


    setHasSubscription(hasSubscription);
    setLimitUnit(hasSubscription ? 0 : LIMITUNIT)
  }, [transactions]);


  useEffect(() => {
    // console.log('Challenge useEffect[results]:', results);
    // moveToScrollPoition();
  }, [results]);

  const showModal = cont => {
    setModalContent(cont);
    setModalVisible(true);
  };

  useEffect(() => {
    if (!modalVisible) unlockScreen();
  }, [modalVisible]);

  const handleOk = e => {
    // console.log('Challenge.handleOk', e);
    setModalVisible(false);
  };

  const handleCancel = e => {
    // console.log('Challenge.handleCancel', e);
    setModalVisible(false);
    lockPortrait();
  };

  const handleAccChange = panel => { };

  const userId = user ? user.id : null;
  const current_level = results ? results['current_level'] : null;
  const currentLevel = (current_level && currentPlan) ? currentPlan.find(aL => aL.level === current_level) : null;
  const activeKey = (currentLevel && currentLevel.open) ? (results && results['current'] ? results['current'] : '') : '';

  const divStyle: any = {
    // height: `${innerHeight - 53}px`,
    overflowX: 'hidden',
    overflowY: 'scroll',
    paddingBottom: '53px'
  };

  // console.log('Challenge: current_level:', current_level);
  // console.log('Challenge: trace....', isLoading, results, currentPlan);

  const gridStyle: React.CSSProperties = {
    width: '33%',
    textAlign: 'center',
  };

  if (!user) return <Redirect to="/profile/login" />;
  else
    return (
      <section className='MiSoroban'>
        <ModalLoader
          title={modalTitle}
          visible={modalVisible}
          handleOk={handleOk}
          handleCancel={handleCancel}
          content={modalContent}
          className="exercise-modal"
        />


        <div style={divStyle} className="learn challenge">
          <div className='mis-wrapper'>

            <SectionHeader section={'challengeyourself'} />

            <UserRanking />

            <section className='section-2'>
              <h2>{t(lang, 'getmorepoints')}</h2>
            </section>


            {isLoading ? <Spin size="large" className="spinner" /> : null}
            {!results || !currentPlan ? null : currentPlan.map((aP, idx) => {
              const current_level = results.current_level;

              return (
                <article className="level" data-level={idx + 1} key={idx}>
                  <Collapse
                    key={idx}
                    accordion
                    bordered={false}
                    defaultActiveKey={0}
                    onChange={handleAccChange}>
                    {showLevelUnits(
                      aP,
                      showModal,
                      handleCancel,
                      results,
                      saveResponses,
                      lang,
                      limitUnit,
                      (idx == 0 ? true : hasSubscription),
                      'challenge'
                    )}

                  </Collapse>


                </article>
              );
            })}
          </div>
        </div>

        <BottomMenu section={'challenge'} />
      </section>
    );
};

const mapStateToProps = ({ user, response, subscription }) => ({
  user: user.user,
  // results: user.results,
  results: response.data,
  isLoading: response.isFetching,
  lang: user.lang,
  products: subscription.products,
  transactions: subscription.transactions
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadResponses,
      saveResponses,
    },
    dispatch
  );

export default memo(connect(mapStateToProps, mapDispatchToProps)(Challenge));
