export class User {
  constructor(
    public id?: string,
    public name?: string,
    public email?: string,
    public password?: string | null,
    public language?: string,
    public country?: string | null,
    public acceptConditions?: boolean,
    public token?: string | null,
    public app_version?: string,
    public children?: Array<User>,
    public activeChild?: string,
    public age?: number,
    public currentClass?: string,
    public isParent?: boolean,
    public isClient?: boolean,
    public clientSource?: string,
    public expirationDate?: Date,
    public active?: boolean,
    public avatar?: number |  null,
    public points?: number | null,
    public source?: string | null,
    public rateState?: number | null,
    public uses?: number | null,
    public dailyGoal?: number | null,
    public products?: string[] | null,
  ) { }
}
