import React, { useEffect, useState } from 'react'

import { Row, Col, Button, Progress } from 'antd';
import Result from '../../base/result';
import { genExerciseComponent } from '../../../utils/componentwrapper';

import './index.less';
import { t } from '../../../languages';

const TestChallenge = (props) => {
  const [tid, setTid] = useState(0);
  const [exercises, setExercises] = useState([]);
  const [current, setCurrent] = useState(0);
  const [results, setResults] = useState([]);
  const [times, setTimes] = useState([]);
  const [message, setMessage] = useState(null);
  const [timeout, setTimeout] = useState(false);
  const [showresults, setShowresults] = useState(false);
  const [coins, setCoins] = useState(0);

  useEffect(() => {
    const exercises = props.params;

    const coins = exercises.reduce((acc, aE) => aE.params.coins + acc, 0);
    // console.log('TestChallenge.useEffect coins:', coins);

    setExercises(exercises);
    setCoins(coins)
  }, []);

  const handleRepeat = () => {
    setTid(0);
    setCurrent(0);
    setResults([]);
    setTimes([]);
    setMessage(null);
    setTimeout(false);
    setShowresults(false);
  }

  const handleResult = (uid, ex, result, times, timeout) => {
    const lresults = [...results];
    lresults.push(result.p ? 1 : 0);

    const num = exercises.length;

    if (current >= num - 1) {
      const hits = lresults.reduce(((acc, aR) => acc += aR), 0);
      const errors = num - hits;
      const passed = errors > 0 ? false : true;
      const uid = props.uid;

      // console.log('TestChallenge.handleResult save...', coins,'::',num, hits);


      props.saveResult(uid, uid, { uid, p: passed, h: hits, e: errors, c: (passed ? coins : 0) });
    }

    // console.log('TestChallenge.handleResult', props.uid, lresults, times, timeout);

    setTimes(times);
    setTimeout(timeout);

    setResults(lresults);
    setCurrent(current + 1);
    setShowresults(current >= num - 1);

  }

  // console.log('TestChallenge.render:', props, '::', exercises);

  if (!showresults) {
    const uid = props.uid;
    const currentExer = exercises[current];
    const exerParams = currentExer ? { ...currentExer.params, is_test: true } : null;

    const rate = results[current];
    const num = exercises.length;
    const percent = parseInt((current) / num * 100);
    if (!currentExer) return null;
    else return (
      <Row className="exercise">
        <Col span={24} className="test-body" key={current}>
          {genExerciseComponent(currentExer.impl, uid, current, exerParams, props.handleCancel, handleResult, props.lang)}
        </Col>
      </Row >
    );
  } else {
    const params = { ...props.params, num: exercises.length, errors: 0 }

    return (
      <Result
        params={params}
        results={results}
        handleRepeat={handleRepeat}
        handleCancel={props.handleCancel}
        is_test={false}
        lang={props.lang}
        show_time={true}
        times={times}
        is_timeout={timeout}
        coins={coins}
      />

    );
  }
}

export default TestChallenge;


