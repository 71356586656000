/* eslint no-eval: 0 */
import React from 'react'

import { Row, Col, Button, Progress } from 'antd';
import Result from '../../base/result';
import { getSeriesFromCouples } from '../../../utils';

import Box from '../../base/DragThingsToBoxes/Box';

import './index.less';
import { motion } from 'framer-motion';
import ExerciseBlocker from '../../Utils/ExerciseBlocker';
import BoxBag from '../../base/DragThingsToBoxes/BoxBag';
import BoxC1 from '../../base/DragThingsToBoxes/BoxC1';
import BoxC5 from '../../base/DragThingsToBoxes/BoxC5';
import BoxC10 from '../../base/DragThingsToBoxes/BoxC10';
import ChangeOrientation from '../../Utils/ChangeOrientation';
import { t } from '../../../languages';

class CartoonLayOut extends React.Component {
    state = {
        eid: 0,
        series: [],
        current: 0,
        results: [],
        times: [],
        numberinbox: 0,
        message: null,
        message2: null,
        showresults: false,
        moved: 0
    }

    UNSAFE_componentWillMount() {
        const params = this.props.params;
        const couples = params.couples;
        const series = getSeriesFromCouples(couples, params.num);

        const splitSerie = series[0].split(/(\+|-|\*|\/)/sg);

        this.setState({
            series,
            numberinbox: parseInt(splitSerie[0])
        });
    }

    handleRate = () => {
        const current = this.state.current;
        const results = this.state.results;
        const curr_serie = this.state.series[current];
        //console.log('SumSubstractionNumber.handleNext:', this.state);
        const currentNumber = eval(this.state.series[current]);
        const current_result = currentNumber === this.state.numberinbox ? 1 : 0;
        // const message = current_result ? 'Respuesta correcta!' : `Te has equivocado, ${curr_serie} es ${currentNumber}`;
        // const message2 = current_result ? 'Genial, sigue así!' : `No te desanimes, ánimo!`;


        const message = (current_result ? t(this.props.lang, 'rightanswer') : t(this.props.lang, 'wrong')) + `, ${curr_serie} ${t(this.props.lang, 'is')} ${currentNumber}`;
        const message2 = current_result ? t(this.props.lang, 'great') : t(this.props.lang, 'nodiscorage');

        results.push(current_result);
        this.setState({
            results,
            message, message2
        });
    }

    handleNext = () => {
        const current = this.state.current;
        if (current < this.state.series.length - 1) {
            const splitSerie = this.state.series[current + 1].split(/(\+|-|\*|\/)/sg);

            this.setState({
                current: current + 1,
                numberinbox: parseInt(splitSerie[0]),
                message: null,
                message2: null,
                moved: 0
            });
        } else {
            const num = this.props.params.num;
            const hits = this.state.results.reduce((acc, aR) => acc += aR);
            const errors = num - hits;
            const passed = errors > this.props.params.errors ? false : true;
            const uid = this.props.uid;
            const eid = this.props.eid;
            const coins = this.props.params.coins;

            this.props.saveResult(uid, `${uid}-${eid}`, { uid, eid, p: passed, h: hits, e: errors, c: (passed ? coins : 0) });
            this.setState({ showresults: true });
        }
    }

    handleRepeat = () => {
        const params = this.props.params;
        const couples = params.couples;

        const series = getSeriesFromCouples(couples, params.num);
        const splitSerie = series[0].split(/(\+|-|\*|\/)/sg);

        this.setState({
            series: series,
            current: 0,
            numberinbox: parseInt(splitSerie[0]),
            results: [],
            message: null,
            message2: null,
            showresults: false,
            moved: 0
        });
    }

    handleMove = (items, box) => {
        const currentSerie = this.state.series[this.state.current];
        const splitSerie = currentSerie.split(/(\+|-|\*|\/)/sg);
        const fn = splitSerie[0];
        const currentResponse = eval(currentSerie);
        const baseNum = (fn >= 10 || currentResponse >= 10) ? 19 : 9;

        const number = items.reduce(((acc, item) => acc + item.value), 0);

        // console.log('CartoonLayOut.handleMove:', items, box, number, '::', baseNum, this.state.numberinbox);

        if (box === 'bag') this.setState({ numberinbox: baseNum - number, moved: 1 });
        else this.setState({ numberinbox: this.state.numberinbox + number, moved: 1 });

    }

    render() {
        const props = this.props;
        // console.log('CartoonLayOut:', props, this.state);
        const rows = props.params.rows;
        const lang = props.lang;
        const currentSerie = this.state.series[this.state.current];
        const splitSerie = currentSerie.split(/(\+|-|\*|\/)/sg);

        const fn = splitSerie[0];
        const op = splitSerie[1];
        const currentNumber = op + splitSerie[2];
        const currentResponse = eval(currentSerie);
        // console.log('CartoonLayOut currentResponse....:', currentSerie, currentResponse);

        const message = this.state.message;
        const message2 = this.state.message2;
        const rate = this.state.results[this.state.current];
        const messagestyle = message ? (!rate ? ' error' : ' valid') : '';
        const showresults = this.state.showresults;
        const percent = parseInt((this.state.current) / props.params.num * 100);

        const is_test = props.params.is_test ? props.params.is_test : false;

        const baseNum = (fn >= 10 || currentResponse >= 10) ? 19 : 9;

        const bagNumber = baseNum - fn;
        // console.log('CartoonLayOut currentResponse bagNumber....:', currentResponse, bagNumber);

        let bagItems = [];
        let restoBag = bagNumber;
        // console.log('CartoonLayOut restoBag....:', restoBag);

        if (bagNumber >= 10) {
            bagItems.push({ uid: 6, value: 10, img: './static/board/c10.png', itemTarget: 'c10' });
            restoBag -= 10;
        }
        // console.log('CartoonLayOut restoBag....:', restoBag);

        if (restoBag >= 5) {
            bagItems.push({ uid: 5, value: 5, img: './static/board/c5.png', itemTarget: 'c5' });
            restoBag -= 5;
        }
        // console.log('CartoonLayOut restoBag....:', restoBag);


        if (restoBag < 5) {
            for (let idx = 0; idx < restoBag; idx++) {
                bagItems.push({ uid: idx, value: 1, img: './static/board/c1.png', itemTarget: 'c1' });
            }
        }

        const shouldAdd10 = fn >= 10;
        const tenthItem = shouldAdd10 ? [{ uid: 6, value: 10, img: './static/board/c10.png', itemTarget: 'c10' }] : null;
        const shoudAdd5 = (fn >= 10 && fn - 10 >= 5) || (fn < 10 && fn >= 5);
        const fifthItem = shoudAdd5 ? [{ uid: 5, value: 5, img: './static/board/c5.png', itemTarget: 'c5' }] : null;

        const restoNumber = fn - (shouldAdd10 ? 10 : 0) - (shoudAdd5 ? 5 : 0);
        // console.log('CartoonLayOut restoNumber....:', restoNumber);

        const firstItem = (restoNumber >= 1) ? [{ uid: 1, value: 1, img: './static/board/c1.png', itemTarget: 'c1' }] : null;
        const secItem = (restoNumber >= 2) ? [{ uid: 2, value: 1, img: './static/board/c1.png', itemTarget: 'c1' }] : null;
        const thirdItem = (restoNumber >= 3) ? [{ uid: 3, value: 1, img: './static/board/c1.png', itemTarget: 'c1' }] : null;
        const fourthItem = (restoNumber >= 4) ? [{ uid: 4, value: 1, img: './static/board/c1.png', itemTarget: 'c1' }] : null;

        // console.log('CartoonLayOut numberinbox....:', this.state.numberinbox);


        if (!showresults) {
            return (
                <Row className="exercise">
                    <ChangeOrientation to={'portrait'} />

                    {!is_test ? <Col span={22} className='progress-bar'>
                        <Progress percent={percent} showInfo={false} />
                    </Col> : null}

                    <Col span={24} className="exercise-body">
                        <ExerciseBlocker show={messagestyle} />
                        <section className={`cartoonlayout ${rows > 1 ? 'b10' : 'b5'}`} key={currentSerie}>
                            <div className="left">
                                <BoxBag id="bag" items={bagItems} onMove={this.handleMove} />
                            </div>
                            <div className="right">
                                <div>
                                    <BoxC1 key={1} id="one" items={firstItem} onMove={this.handleMove} />
                                    <BoxC1 key={2} id="one" items={secItem} onMove={this.handleMove} />
                                    <BoxC1 key={3} id="one" items={thirdItem} onMove={this.handleMove} />
                                    <BoxC1 key={4} id="one" items={fourthItem} onMove={this.handleMove} />
                                </div>
                                <div>
                                    <BoxC5 key={5} id="five" itemTarget="c5" items={fifthItem} onMove={this.handleMove} className="five" />
                                    {fn >= 10 || currentResponse >= 10 ? <BoxC10 key={10} id="ten" itemTarget="c10" items={tenthItem} onMove={this.handleMove} className="ten" /> : null}
                                </div>
                            </div>
                        </section>
                    </Col>

                    <Col span={24} className="">
                        <article className="number cartoon-specyfic" key={currentNumber}>
                            <motion.div
                                animate={{ scale: 3 }}
                                transition={{ duration: 1 }}
                                className="the-number"
                            >{currentNumber}</motion.div>
                        </article>
                    </Col>
                    <Col span={24}>
                        <Row className={'botones' + messagestyle}>
                            <Col span={24} className={'message'}>{message}</Col>
                            {!message ? <Col span={24}><Button key="submit" type="primary" onClick={this.handleRate} style={{ 'width': '90%' }} disabled={!this.state.moved}>{t(props.lang, 'grade')}</Button></Col> : null}
                            {message ? <Col span={24}><Button key="submit" type="primary" onClick={this.handleNext} style={{ 'width': '90%' }}>{t(props.lang, 'next')}</Button></Col> : null}
                            {message2 ? <Col span={24} className={'message'}>{message2}</Col> : null}
                        </Row>
                    </Col>
                </Row >
            );
        } else {
            // console.log('CartoonLayOut result:', this.state);
            return (
                <Result params={props.params} results={this.state.results} handleRepeat={this.handleRepeat} handleCancel={props.handleCancel} lang={lang} />
            );
        }
    };
}

export default CartoonLayOut;
