import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// import { Suspense, lazy } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import Remember from '../containers/Register/Remember';
import { User } from '../models/user';
import Learn from './Learn';
import NoMatch from './NotFound';
import Profile from './Profile';
import Wellcome from './Wellcome';

import { changeLanguage, PRODUCTS, updateUserFields } from '../modules/user';

import { Layout } from 'antd';
import RegisterClass from './Classes';
import Homework from './Homework';
import Register from './Register';
// import TouchAbacus from '../components/base/TouchAbacus';
import { getPlatform } from '../utils';
import Challenge from './Challenge';
import Course from './Course';
import FreeMode from './FreeMode';
import Practice from './Practice';
import TestingScreen from './TestingScreen';
import { initChat, showChat } from '../modules/chat';
import Resources from './Resources';


const { Content } = Layout;


const App = ({ user, updateUserFields }) => {
  useEffect(() => {

    if (user) {
      const uses = user.uses || 0;
      // console.log('App uses:', uses);
      console.log('App process.env:', process.env);
      updateUserFields({ uses: uses + 1 });
    }

    window.scrollTo(0, 0);
    initChat();
  }, []);


  const isProductCourse = (process.env.MIS_PRODUCT == PRODUCTS.COURSE);
  const isProductTeacher = (process.env.MIS_PRODUCT == PRODUCTS.TEACHER);

  const includeCourse = user && user.products && user.products.includes(PRODUCTS.COURSE);
  const includeTeacher = user && user.products && user.products.includes(PRODUCTS.TEACHER);

  const whereRedirect = user ? ((includeCourse || includeTeacher) ? '/course' : '/learn') : (!(isProductCourse || isProductTeacher) ? '/wellcome' : '/profile/login');

  const mobileClass = getPlatform() !== 'web' ? 'platform-mobile' : '';
  const productsClass = user && user.products ? user.products.join(' ') : '';
  const appClassName = `main-layout platform-${getPlatform()} ${mobileClass} ${productsClass} ${process.env.MIS_PRODUCT}`;

  return (
    <Layout className={appClassName}>
      <Content>
        <Switch>
          <Route exact path="/">
            <Redirect to={whereRedirect} />
          </Route>
          <Route path="/wellcome"><Wellcome /></Route>
          <Route path="/register/:step?"><Register /></Route>
          <Route path="/joinclass"><RegisterClass /></Route>
          <Route path="/learn"><Learn /></Route>
          <Route path="/practice"><Practice /></Route>
          <Route path="/challenge"><Challenge /></Route>
          <Route path="/course"><Course /></Route>
          <Route path="/resources"><Resources /></Route>
          <Route path="/homework"><Homework /></Route>
          <Route path="/freeMode"><FreeMode /></Route>
          <Route path="/profile/" render={props => <Profile {...props} />}></Route>
          <Route path="/user/remember" ><Remember /></Route>

          <Route path="/testscreen"><TestingScreen /></Route>
          <Route path="*" component={NoMatch} />
        </Switch>
      </Content>
    </Layout>
  );

}

const mapStateToProps = ({ user }) => ({
  user: user.user,
  lang: user.lang
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changeLanguage,
      updateUserFields
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);

// export default App;
