/* eslint no-eval: 0 */
import React, { useEffect, useState } from 'react'

import { Row, Col, Button, Progress } from 'antd';
import AbacusView from '../../../base/abacus';
import Result from '../../../base/result';
import { OPERATIONS, OPS_IMGS, generateEquationSeries, isPortrait } from '../../../../utils';

import './index.less';
import { motion } from 'framer-motion';
import { t } from '../../../../languages';
import ExerciseBlocker from '../../../Utils/ExerciseBlocker';
import ChangeOrientation from '../../../Utils/ChangeOrientation';
import MyTimer from '../../../Utils/time/MyTimer';
import BoxC1 from '../../../base/DragThingsToBoxes/BoxC1';
import BoxBag from '../../../base/DragThingsToBoxes/BoxBag';

const CompleteOperations = (props) => {

    const [bagItems, setBagItems] = useState([]);
    const [selectedOps, setSelectedOps] = useState({});

    const [eid, setEid] = useState(0);
    const [series, setSeries] = useState([]);
    const [current, setCurrent] = useState(0);
    const [results, setResults] = useState([]);
    const [numberinbox, setNumberinbox] = useState(0);
    const [message, setMessage] = useState(null);
    const [messagesol, setMessagesol] = useState(null);
    const [message2, setMessage2] = useState(null);
    const [showresults, setShowresults] = useState(false);
    const [moved, setMoved] = useState(0);

    const [times, setTimes] = useState([]);
    const [stopTime, setStopTime] = useState(false);
    const [isTimeout, setIsTimeout] = useState(false);

    useEffect(() => {
        handleRepeat();
    }, []);

    const handleMove = (items, box) => {
        // console.log('CompleteOperations.handleMove:', items, box);

        const lSelOps = { ...selectedOps };

        for (let idx = 0; idx < items.length; idx++) {
            const aI = items[idx];
            for (let key in lSelOps) {
                let anOp = lSelOps[key];
                if (anOp == aI.img) {
                    delete (lSelOps[key]);
                    break;
                }
            }
        }

        if (box !== 'bag') {
            lSelOps[box] = items[0].img;
        }

        if (Object.keys(lSelOps).length >= props.params.ops) setMoved(true);
        else setMoved(false);

        setSelectedOps(lSelOps);

    }

    const handleRate = () => {
        const currEq = series[current];

        let current_result = 1;

        for (let idx = 0; idx < currEq.length; idx++) {
            if (typeof currEq[idx] !== 'number') {
                const eImg = OPS_IMGS[currEq[idx]];
                // console.log('idx:', idx, '::op:', currEq[idx], ':: eImg:', eImg, '::', selectedOps);
                if (!selectedOps[`box[${idx}]`].includes(eImg)) {
                    current_result = 0;
                    break;
                }
            }
        }

        const correctAnswer = currEq.join(' ').replace("*", "x");

        const message = current_result ? `${t(props.lang, 'rightanswer')}` : `${t(props.lang, 'theansweris')}`;
        const messagesol = current_result ? null : correctAnswer;
        const message2 = current_result ? t(props.lang, 'great') : t(props.lang, 'nodiscorage');

        results.push(current_result);

        setResults(results);
        setMessage(message);
        setMessagesol(messagesol);
        setMessage2(message2);

        setStopTime(true);
    }

    const handleNext = () => {
        if (current < series.length - 1) {
            const lbagItems = generatBagItems();
            setBagItems(lbagItems);
            setSelectedOps([]);

            setCurrent(current + 1);
            setNumberinbox(0);
            setMessage(null);
            setMessagesol(null);
            setMessage2(null);
            setMoved(0);
            setStopTime(false);

        } else {
            const num = props.params.num;
            const hits = results.reduce((acc, aR) => acc += aR);
            const errors = num - hits;
            const passed = errors > props.params.errors ? false : true;
            const uid = props.uid;
            const eid = props.eid;
            const coins = props.params.coins;

            const is_test = props.params.is_test ? props.params.is_test : false;

            const totalTime = times.reduce((acc, aT) => acc += aT, 0);
            props.saveResult(uid, `${uid}-${eid}`, { uid, eid, p: passed, h: hits, e: errors, time: totalTime, c: coins }, times, false);

            if (!is_test) {
                setShowresults(true);
            }
        }
    }

    const generatBagItems = () => {
        const lbagItems = [];
        const params = props.params;
        const operations = params.permOps ? params.permOps : OPERATIONS;

        for (let idx = 0; idx < operations.length; idx++) {
            const anOp = operations[idx];
            const aImg = OPS_IMGS[anOp];
            lbagItems.push({ uid: idx, value: 1, props: { op: anOp }, img: `./static/operations/${aImg}.svg`, itemTarget: 'c1' });
        }

        return lbagItems;
    }

    const handleRepeat = () => {

        const params = props.params;
        const init = params.init | 21;
        const finish = params.finish | 99;
        const series = generateEquationSeries(params.num, params.ops, init, finish, params.permOps, 'simple');
        // console.log('CompleteOperations.handleRepeat series:', series);

        const lbagItems = generatBagItems();
        setBagItems(lbagItems);
        setSelectedOps([]);

        setSeries(series);
        setCurrent(0);
        setResults([]);
        setNumberinbox(0);
        setMessage(null);
        setMessagesol(null);
        setMessage2(null);
        setShowresults(false);
        setMoved(0);

        setBagItems(lbagItems);
        setSelectedOps([]);

        setStopTime(false);
        setTimes([]);
        setIsTimeout(false);
    }


    const handleStop = (time) => {
        const lTimes = [...times];
        lTimes.push(time);
        setTimes(lTimes);
    }

    const handleExpire = () => {

        const num = props.params.num;
        const hits = results && results.length > 0 ? results.reduce((acc, aR) => acc += aR) : 0;
        const errors = num - hits;
        const passed = errors > props.params.errors ? false : true;
        const uid = props.uid;
        const eid = props.eid;
        const coins = props.params.coins;

        const is_test = props.params.is_test ? props.params.is_test : false;

        props.saveResult(uid, `${uid}-${eid}`, { uid, eid, p: passed, h: hits, e: errors, time: props.params.timeout, c: (passed ? coins : 0) }, [], true);

        if (!is_test) {
            setTimes([props.params.timeout]);
            setStopTime(true);
            setIsTimeout(true);
            setShowresults(true);
        }
    }

    if (!showresults) {
        const currentNumber = series[current];
        // console.log('CompleteOperations.render....', currentNumber, selectedOps);
        const equalsNumber = currentNumber ? eval(currentNumber.join('')) : null;


        const rate = results[current];
        const messagestyle = message ? (!rate ? ' error' : ' valid') : '';
        const percent = parseInt((current) / props.params.num * 100);

        const is_test = props.params.is_test ? props.params.is_test : false;

        const rows = props.params.rows || 3;

        const portrait = isPortrait();

        const time = new Date();
        time.setSeconds(time.getSeconds() + props.params.timeout);

        if (!currentNumber) return null;
        else return (
            <Row className="exercise">

                <ChangeOrientation to={'portrait'} />

                {!is_test ? <Col span={22} className='progress-bar'>
                    <div className='progress'><Progress percent={percent} showInfo={false} /> </div>
                </Col> : null}

                <Col span={24} className='exercise-timer'>
                    <MyTimer expiryTimestamp={time} stopTime={stopTime} handleExpire={handleExpire} handleStop={(t) => handleStop(t)} />
                </Col>

                <Col span={24} className="exercise-body">
                    <ExerciseBlocker show={messagestyle} />
                    <section className="completeoperations">
                        <article className="number" key={currentNumber}>
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{
                                    delay: 0.3,
                                    duration: 2,
                                    ease: [0, 0.71, 0.2, 1.01],
                                }}
                                className="the-number"
                            >
                                <div className='equation'>{currentNumber ? currentNumber.map((aI, idx) => {
                                    if (typeof aI === 'number') return <div className='num' key={idx}>{aI}</div>;
                                    else return <div className='operation' key={idx}>
                                        <BoxC1 key={idx} id={`box[${idx}]`} items={null} onMove={handleMove} />
                                    </div>
                                }) : null}</div>
                                <span className='equals'> =</span> <span className='resultn'>{equalsNumber}</span>
                            </motion.div>
                        </article>

                        <article className="inter-boxes" key={'drop'}>
                            <img src="./static/utils/drag.svg" alt="drag" />
                        </article>

                        <article className="bag" key={'bag' + current}>
                            <BoxBag id="bag" items={bagItems} onMove={handleMove} />
                        </article>

                    </section>
                </Col>
                {portrait || message || moved ? <Col span={24} className={'botonera'}>
                    <Row className={'botones completeoperations' + messagestyle}>
                        <Col span={portrait ? 24 : 12} className={'message'}>{message} <br /> {messagesol}</Col>
                        {!message ? <Col span={24}><Button key="submit" type="primary" onClick={handleRate} style={{ 'width': '90%' }} disabled={!moved}>{t(props.lang, 'grade')}</Button></Col> : null}
                        {message ? <Col span={portrait ? 24 : 12}><Button key="submit" type="primary" onClick={handleNext} style={{ 'width': '90%' }}>{t(props.lang, 'next')}</Button></Col> : null}
                        {message2 ? <Col span={portrait ? 24 : 12} className={'message'}>{message2}</Col> : null}
                    </Row>
                </Col> : null}
            </Row >
        );
    } else {
        return (
            <Result params={props.params} results={results} show_time={true} times={times} is_timeout={isTimeout} handleRepeat={handleRepeat} handleCancel={props.handleCancel} lang={props.lang} />
        );
    }

}

export default CompleteOperations;

