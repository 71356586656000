import { ArrowRightOutlined, RightOutlined, ShareAltOutlined } from '@ant-design/icons';
import { Button, Collapse, Divider, Input, Popconfirm, Radio, Spin } from 'antd';
import { useEffect, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { t } from '../../languages';
import { COUNTRY_ITEMS } from '../../utils/countries';
import SubscriptionOptions from '../Subscription/SubscriptionOptions';
import SectionHeader from '../Utils/SectionHeader';
import AgeButtons from './AgeButtons';
import AvatarPicker from './AvatarPicker';
import LangButtons from './LangButtons';
import GoalOptions from '../DailyGoal/GoalOptions';
import BottomMenu from '../Menu';
import { PRODUCTS } from '../../modules/user';
import SocialShare from '../Utils/SocialShare';

const { Panel } = Collapse;

const ageRange = {
  1: '5-7',
  2: '8-11',
  3: '12 +',
};

const UserProfile = ({ user, logout, lang, changeChild, isApple, changeLanguage, deleteAccount, updateUserFields, isLoading, getUserData }) => {
  let countryName;
  if (user && user.country) {
    if (user.country == 'uk') {
      user.country = 'gb'
    }
    for (var i = 0; i < COUNTRY_ITEMS.length; i++) {
      if (COUNTRY_ITEMS[i].value == user.country.toUpperCase()) {
        countryName = COUNTRY_ITEMS[i].label;
        if (lang == 'es' && countryName == 'Spain') countryName = 'España';
        break;
      }
    };
  }

  useEffect(() => {
    console.log('UserProfile useEffect[]:', user);

    if (user) getUserData();
  }, []);


  useEffect(() => {
    // console.log('isLoading effect...', isLoading);
    if (isLoading) setCollapseOpen(['0']);
  }, [isLoading]);


  const currentClass = user ? user.currentClass : null;
  const children = user && user.isParent ? user.children : [];

  const [activeChild, setActiveChild] = useState(user ? user.activeChild : null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [newName, setNewName] = useState('');
  const [collapseOpen, setCollapseOpen] = useState(['0']);

  const onChangeActiveChild = (e) => {
    const newActiveChild = e.target.value;
    setActiveChild(newActiveChild);
    user.activeChild = newActiveChild;
    changeChild(user);
  };


  const handleCollapse = (key) => {
    // console.log('handleCollapse...', collapseOpen, key);

    if (collapseOpen.join('') == key.join('')) setCollapseOpen(['0']);
    else setCollapseOpen(key);
  }

  const isCourse = user && user.products && user.products.includes(PRODUCTS.COURSE) || false;


  return (
    <div className="profile user-profile">

      {isLoading ? <Spin size="large" className="spinner" /> : null}

      <div className="profile-body">
        <div className='mis-wrapper'>

          <SectionHeader section={'profile'} />

          <div className="my-data profile-data">
            <div>
              <AvatarPicker lang={lang} handleChange={(anV) => updateUserFields({ avatar: anV })} selected={user.avatar} />
            </div>
            <div className="my-data-data">
              <Collapse className='edit-name' activeKey={collapseOpen} onChange={() => handleCollapse(['1'])}>
                <Panel header={<h3 className="bold gamer-nick">{user ? user.name : null}</h3>} key="1" >
                  <div className='name-form'>
                    <Input value={newName} onChange={(e) => setNewName(e.target.value)} placeholder={t(lang, 'changeyourname')} />
                    <button disabled={newName.length < 3} onClick={() => updateUserFields({ name: newName })}><ArrowRightOutlined /></button>
                  </div>
                </Panel>
              </Collapse>
            </div>
            <div className="my-data-data"><h4>{user ? user.email : null}</h4></div>
          </div>

          <div className="my-data">
            <h3 className="my-data-data">{t(lang, 'sharemis')} <ShareAltOutlined style={{fontSize: "1.6em", margin: "3px 8px 0"}}/></h3>
            <div className="my-data-data"><SocialShare lang={lang} /></div>
          </div>

          <div className="my-data">
            <h3 className="my-data-data">{t(lang, 'data')}</h3>
            {countryName ? <h4 className="my-data-data">{t(lang, 'country')}: {countryName ? countryName : null}
              <ReactCountryFlag countryCode={user && user.country ? user.country : 'es'} svg style={{ width: '1.5em', height: '1.5em' }} />
            </h4> : null}

            {isCourse ? null : <div className='age my-data-data'>
              <h4>{t(lang, 'agelevel')}</h4> {user ? (
                <Collapse className='select-age' activeKey={collapseOpen} onChange={() => handleCollapse(['2'])}>
                  <Panel header={<div><img className="age_img" src={`./static/ages/age_${user.age}.svg`} /> {ageRange[user.age]}</div>} key="2">
                    <AgeButtons age={user.age} handleChangeAge={(selAge) => updateUserFields({ age: selAge })} />
                  </Panel>
                </Collapse>
              ) : null}
            </div>}

            <div className='age my-data-data'>
              <h4>{t(lang, 'dailygoal')}</h4>
              <Collapse className='select-age' activeKey={collapseOpen} onChange={() => handleCollapse(['3'])}>
                <Panel header={<div>{user.dailyGoal} min.</div>} key="3">
                  <GoalOptions dailyGoal={user.dailyGoal} handleGoalChange={(e) => updateUserFields({ dailyGoal: e.target.value })} />
                </Panel>
              </Collapse>

            </div>

            {currentClass ? <h4 className="my-data-data">{'Clase'}: {currentClass}</h4> : null}
          </div>

          {user && user.isParent ? <div className="my-data">
            <h3 className="my-data-data">{t(lang, 'protagonists')}</h3>

            <Radio.Group
              onChange={onChangeActiveChild}
              value={activeChild}
            >
              {children.map((aC, idx) => <div key={idx} className="my-data-data">
                <Radio.Button value={aC.id} style={{ margin: "1em 0" }}>{aC.name}</Radio.Button>
              </div>)}
            </Radio.Group>
          </div> : null}

          <div className="my-data">
            <h3 className="my-data-data">{t(lang, 'language')}</h3>
            <LangButtons lang={lang} handleChangeLang={(selLang) => changeLanguage(selLang)} />
          </div>

          <Divider />
          <br />
          <br />

          {isCourse ? null : <div className="logout"><SubscriptionOptions lang={'lang'} /></div>}

          <Divider />

          <div className="logout">
            <Button className="button-next" onClick={() => logout()}>{t(lang, 'logout')}</Button>
          </div>

          <hr />


          <div className="logout">
            <Popconfirm
              title={t(lang, 'sureDeleteAccount')}
              onConfirm={() => { setOpenConfirm(false); deleteAccount(); }}
              onCancel={() => setOpenConfirm(false)}
              okText={t(lang, "yes")}
              cancelText={t(lang, "no")}
              open={openConfirm}
            >
              <Button danger ghost className="button-danger" onClick={() => setOpenConfirm(true)}>{t(lang, 'deleteAccount')}</Button>
            </Popconfirm>
          </div>

        </div>
      </div>

      <BottomMenu section={'profile'} />

    </div>
  );
};

export default UserProfile;
