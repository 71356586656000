/* eslint no-eval: 0 */
import React, { useEffect, useState } from 'react'

import { Row, Col, Button, Progress } from 'antd';
import AbacusView from '../../base/abacus';
import Result from '../../base/result';
import { MIN_EXERS_SCREEN_HEIGHT_PORTRAIT, getSeriesFromCouples, isPortrait } from '../../../utils';

import './index.less';
import { motion } from 'framer-motion';
import { t } from '../../../languages';
import ExerciseBlocker from '../../Utils/ExerciseBlocker';
import ChangeOrientation from '../../Utils/ChangeOrientation';

const generateParamsForNumbers = (operation, rows, onlyAnswer) => {
    const inactiverows = [];
    const stillrows = [];

    let numberinbox = 0;
    let rowcolor = 0;
    let numcolors = 3;
    const showNumbers = !onlyAnswer ? true : false;
    const hidelatframe = onlyAnswer ? true : false;

    if (!onlyAnswer) {
        const splitOp = operation.split(/(\+|-|\*|\/)/sg);
        const rowValues = Array(rows).fill(0);

        const opResult = Math.floor(eval(operation));
        const resNumdigits = new String(opResult).length;

        const theOP = splitOp[1];
        const isDiv = theOP == "/";
        const isMult = theOP == "*";
        const firstOp = isMult ? 2 : 0;
        const secOp = isMult ? 0 : 2;

        const digitsArr1Op = Array.from(splitOp[firstOp].toString()).map(Number);
        const digitsArr2Op = Array.from(splitOp[secOp].toString()).map(Number);
        const op1Length = digitsArr1Op.length;
        const op2Length = digitsArr2Op.length;
        const nrows = rowValues.length;
        const remRows = nrows - op1Length - op2Length - resNumdigits;
        // console.log('generateParamsForNumbers:', nrows, remRows, '::', operation, firstOp, secOp, '::', opResult, resNumdigits, '::', digitsArr1Op, digitsArr2Op);

        const numcolors = (op2Length > 3) ? 3 : op2Length;
        if (isDiv) rowcolor = nrows - op2Length - 4;

        for (let idx = 0; idx < nrows; idx++) {

            if (isMult) {
                if (idx < op1Length) {
                    rowValues[idx] = digitsArr1Op[idx];
                    stillrows.push(idx)
                } else if (idx == op1Length) inactiverows.push(idx);
                else if (idx > op1Length && idx < (op1Length + op2Length + 1)) {
                    rowValues[idx] = digitsArr2Op[idx - op1Length - 1];
                    stillrows.push(idx)
                } else if (idx == (op1Length + op2Length + 1)) {
                    inactiverows.push(idx);
                    rowcolor = idx;
                }
                else break;
            } else {
                if (idx < op2Length) {
                    rowValues[idx] = digitsArr2Op[idx];
                    stillrows.push(idx)
                } else if (idx <= (op2Length + remRows - 2)) {
                    inactiverows.push(idx);
                } else if (idx > (op2Length + remRows - 2) && idx < (nrows - op1Length - 1)) {
                    rowValues[idx] = 0;
                } else if (idx == (nrows - op1Length - 1)) {
                    inactiverows.push(idx);
                } else if (idx > (nrows - op1Length - 1)) {
                    rowValues[idx] = digitsArr1Op[op1Length - (nrows - idx)];
                }
                else break;
            }
        }

        numberinbox = parseInt(rowValues.join(''));
    } else {

    }

    // console.log('generateParamsForNumbers rowValues:', numberinbox, inactiverows, stillrows, rowcolor, numcolors, showNumbers, hidelatframe);
    return { number: numberinbox, inactiverows, stillrows, rowcolor, numcolors, showNumbers, hidelatframe };
}

const extractNumberFromAbacus = (numberinbox, linactiverows, theOP) => {
    const isDiv = theOP == "/";
    const isMult = theOP == "*";

    if (isMult) {
        const initRow = linactiverows[linactiverows.length - 1];
        const digitsArr = Array.from(numberinbox.toString()).map(Number);
        const validArr = digitsArr.slice(initRow);
        return parseInt(validArr.join(''));
    } else {
        const firstRow = linactiverows[0];
        const secondRow = linactiverows[linactiverows.length - 1];
        const digitsArr = Array.from(numberinbox.toString()).map(Number);
        const validArr = digitsArr.slice(firstRow, secondRow);
        return parseInt(validArr.join(''));
    }
}

const MultiplyDivide = (props) => {

    const [eid, setEid] = useState(0);
    const [onlyAnswer, setOnlyAnswer] = useState(false);
    const [series, setSeries] = useState([]);
    const [current, setCurrent] = useState(0);
    const [results, setResults] = useState([]);
    const [times, setTimes] = useState([]);
    const [numberinbox, setNumberinbox] = useState(0);
    const [message, setMessage] = useState(null);
    const [message2, setMessage2] = useState(null);
    const [showresults, setShowresults] = useState(false);
    const [moved, setMoved] = useState(0);
    const [paramsForNumber, setParamsForNumber] = useState(null);

    useEffect(() => {
        /* const params = props.params;
        const couples = params.couples;
        const series = getSeriesFromCouples(couples, params.num, params.init, params.finish, params.ops, params.config, params.ispan, params.fspan);

        setOnlyAnswer(params.only_answer);
        setSeries(series);
        setParamsForNumber( generateParamsForNumbers(series[0], params.rows, params.only_answer) ); */
        handleRepeat();
    }, []);

    const handleRate = () => {
        const currentOp = series[current];
        const splitOp = currentOp.split(/(\+|-|\*|\/)/sg);
        const op = splitOp[1].replace('*', 'x').replace('/', '÷');

        const theOP = splitOp[1];
        const firstOp = theOP === '*' ? 2 : 0;
        const secOp = theOP === '*' ? 0 : 2;
        const splitJoin = `${splitOp[firstOp]} ${op} ${splitOp[secOp]}`;

        const currentRightAnswer = Math.floor(eval(currentOp));
        const currentModule = theOP === '/' ? Math.floor(eval(currentOp.replace("/", "%"))) : null;

        const linactiverows = paramsForNumber.inactiverows;

        const valueInBox = extractNumberFromAbacus(numberinbox, linactiverows, theOP);
        // console.log('valueInBox:', numberinbox, valueInBox, currentRightAnswer, currentModule);

        const current_result = currentRightAnswer === valueInBox ? 1 : 0;

        const module_mess = currentModule ? `, ${t(props.lang, 'module')} ${currentModule}` : '';

        const message = current_result ? `${t(props.lang, 'rightanswer')}: ${splitJoin} = ${currentRightAnswer}${module_mess}` : `${t(props.lang, 'wrong')}, ${splitJoin} = ${currentRightAnswer}${module_mess}`;
        const message2 = current_result ? t(props.lang, 'great') : t(props.lang, 'nodiscorage');

        results.push(current_result);

        setResults(results);
        setMessage(message);
        setMessage2(message2);
    }

    const handleNext = () => {
        if (current < series.length - 1) {
            const splitSerie = series[current + 1].split(/(\+|-|\*|\/)/sg);
            const splitOps = [];

            splitOps.push(splitSerie[0]);
            for (let idx = 1; idx < splitSerie.length; idx += 2) {
                splitOps.push(splitSerie[idx] + splitSerie[idx + 1]);
            }

            setCurrent(current + 1);
            setParamsForNumber(generateParamsForNumbers(series[current + 1], props.params.rows, onlyAnswer));

            setNumberinbox(parseInt(splitSerie[0]));
            setMessage(null);
            setMessage2(null);
            setMoved(0);
        } else {
            const num = props.params.num;
            const hits = results.reduce((acc, aR) => acc += aR);
            const errors = num - hits;
            const passed = errors > props.params.errors ? false : true;
            const uid = props.uid;
            const eid = props.eid;
            const coins = props.params.coins;

            const is_test = props.params.is_test ? props.params.is_test : false;

            props.saveResult(uid, `${uid}-${eid}`, { uid, eid, p: passed, h: hits, e: errors, c: (passed ? coins : 0) });
            if (!is_test) {
                setShowresults(true);
            }
        }
    }

    const handleRepeat = () => {
        const params = props.params;
        const couples = params.couples;
        const series = getSeriesFromCouples(couples, params.num, params.init, params.finish, params.ops, params.config, params.ispan, params.fspan);

        setOnlyAnswer(params.only_answer);
        setSeries(series);
        setParamsForNumber(generateParamsForNumbers(series[0], params.rows, params.only_answer));

        setCurrent(0);
        setResults([]);
        setNumberinbox(0);
        setMessage(null);
        setMessage2(null);
        setShowresults(false);
        setMoved(0);
        setTimes([]);
    }

    const handleChange = (num, rowValues) => {
        // console.log('MultiplyDivide.handleChange:', num, rowValues);
        const lparamsForNumber = { ...paramsForNumber };
        lparamsForNumber.number = num;
        setParamsForNumber(lparamsForNumber);
        setNumberinbox(num);

        setMoved(1);
    }

    if (!showresults) {
        if (series && series.length) {

            const currentOp = series[current];
            const splitOp = currentOp.split(/(\+|-|\*|\/)/sg);
            const op = splitOp[1].replace('*', 'x').replace('/', '÷');

            const theOP = splitOp[1];
            const firstOp = theOP === '*' ? 2 : 0;
            const secOp = theOP === '*' ? 0 : 2;

            const currentNumber = `${splitOp[firstOp]} ${op} ${splitOp[secOp]}`;

            const rate = results[current];
            const messagestyle = message ? (!rate ? ' error' : ' valid') : '';
            const percent = parseInt((current) / props.params.num * 100);

            const is_test = props.params.is_test ? props.params.is_test : false;
            const rows = props.params ? props.params.rows : 1;

            const paramsToPass = { ...props.params, rows, ...paramsForNumber };

            const portrait = isPortrait() || window.innerHeight >= MIN_EXERS_SCREEN_HEIGHT_PORTRAIT;

            if (currentNumber) return (
                <Row className="exercise">

                    {rows > 5 ? <ChangeOrientation to={'landscape'} /> : <ChangeOrientation to={'portrait'} />}

                    {!is_test ? <Col span={22} className='progress-bar'>
                        <div className='progress'><Progress percent={percent} showInfo={false} /> </div>
                    </Col> : null}

                    <Col span={24} className="exercise-body">
                        <ExerciseBlocker show={messagestyle} />
                        <section className="multiplydivide">
                            <AbacusView params={paramsToPass} isactive={true} hideheaderlabels={true} onChange={handleChange} />
                            {!message ? <article className="number" key={currentNumber}>
                                <motion.div
                                    animate={{ scale: 2 }}
                                    transition={{ duration: 1 }}
                                    className="the-number"
                                >{currentNumber}</motion.div>
                            </article> : <article className="number"><div className="the-number transparent">0</div></article>}
                        </section>
                    </Col>

                    {portrait || message || moved ? <Col span={24} className={'botonera'}>
                        <Row className={'botones multiplydivide' + messagestyle}>
                            <Col span={portrait ? 24 : 12} className={'message'}>{message}</Col>
                            {!message ? <Col span={24} className="rate-button"><Button key="submit" type="primary" onClick={handleRate} style={{ 'width': '90%' }} disabled={!moved}>{t(props.lang, 'grade')}</Button></Col> : null}
                            {message ? <Col span={portrait ? 24 : 12} className="next-button"><Button key="submit" type="primary" onClick={handleNext} style={{ 'width': '90%' }}>{t(props.lang, 'next')}</Button></Col> : null}
                            {/* message2 ? <Col span={24} className={'message'}>{message2}</Col> : null */}
                        </Row>
                    </Col> : null}
                </Row >
            );
            else return null;
        } else return null;

    } else {
        return (
            <Result params={props.params} results={results} handleRepeat={handleRepeat} handleCancel={props.handleCancel} lang={props.lang} />
        );
    }

}

export default MultiplyDivide;