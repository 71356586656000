import React, { useEffect, useState } from 'react';
import YouTube from 'react-youtube';
import { isPortrait, vw } from '../../../utils';

const PORTRAITREL = 90;
const LANDSCAPEREL = 60;

const VideoView = props => {
  const [rel, setRel] = useState(isPortrait() ? PORTRAITREL : LANDSCAPEREL);

  const onResize = () => {
    setRel(isPortrait() ? PORTRAITREL : LANDSCAPEREL);
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const finish = () => {
    if (props && props.endHandler) props.endHandler();
  }

  const pause = () => {
    if (props && props.pauseHandler) props.pauseHandler();
  }

  const play = () => {
    if (props && props.playHandler) props.playHandler();
  }

  const opts = {
    height: vw((rel * 11) / 16),
    width: vw(100),
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      controls: 0,
      rel: 0
    }
  };

    // console.log('rel:', rel);
  return <YouTube videoId={props.videoId} opts={opts} onEnd={finish} onPause={pause} onPlay={play} />;
};

export default VideoView;
