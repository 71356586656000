import React from 'react'

import { Row, Col, Button, Progress } from 'antd';
import AbacusView from '../../base/abacus';
import Result from '../../base/result';
import { generateRandomNumberSeries } from '../../../utils';

import './index.less';
import { t } from '../../../languages';
import ExerciseBlocker from '../../Utils/ExerciseBlocker';
import ChangeOrientation from '../../Utils/ChangeOrientation';

class MentalAbacusFixation extends React.Component {
    state = {
        eid: 0,
        series: [],
        current: 0,
        results: [],
        times: [],
        numberinbox: 0,
        message: null,
        message2: null,
        showresults: false,
        moved: 0,
        ready: false,
        timeout: false
    }

    UNSAFE_componentWillMount() {
        /* const params = this.props.params;
        const series = params ? generateRandomNumberSeries(params.num, params.init, params.finish, null) : generateRandomNumberSeries(9, 1, 9, null);
        // console.log('MentalAbacusFixation.componentWillMount:', params, series);
        this.setState({ series }); */
        this.handleRepeat();
    }

    handleReady = () => {
        setTimeout(() => { this.setState({ timeout: true }) }, 5000);
        this.setState({ ready: true });
    }

    handleRate = () => {
        const current = this.state.current;
        const results = this.state.results;
        // console.log('MentalAbacusFixation.handleNext:', this.state);
        const currentNumber = this.state.series[current];
        const current_result = currentNumber === this.state.numberinbox ? 1 : 0;
        const message = current_result ? t(this.props.lang, 'rightanswer') : `${t(this.props.lang, 'yourwrong')} ${currentNumber}`;
        const message2 = current_result ? t(this.props.lang, 'great') : t(this.props.lang, 'nodiscorage');
        results.push(current_result);
        this.setState({
            results,
            message, message2
        });
    }

    handleNext = () => {
        const current = this.state.current;
        if (current < this.state.series.length - 1) {
            this.setState({
                current: current + 1,
                numberinbox: 0,
                message: null,
                message2: null,
                moved: 0,
                ready: false,
                timeout: false
            });
        } else {
            const num = this.props.params.num;
            const hits = this.state.results.reduce((acc, aR) => acc += aR);
            const errors = num - hits;
            const passed = errors > this.props.params.errors ? false : true;
            const uid = this.props.uid;
            const eid = this.props.eid;
            const coins = this.props.params.coins;

            this.props.saveResult(uid, `${uid}-${eid}`, { uid, eid, p: passed, h: hits, e: errors, c: (passed ? coins : 0) });
            this.setState({ showresults: true });
        }
    }

    handleRepeat = () => {
        const params = this.props.params;
        let series = [];
        const configSeries = params.series;
        // console.log('configSeries:', configSeries);
        if (configSeries) {
            for (let idx = 0; idx < configSeries.length; idx++) {
                const aSerie = configSeries[idx];
                series = series.concat(generateRandomNumberSeries(aSerie.num, aSerie.init, aSerie.finish, null));
            }
        } else {
            series = params ? generateRandomNumberSeries(params.num, params.init, params.finish, null) : generateRandomNumberSeries(9, 1, 9, null);
        }

        // console.log('serires:', series);

        this.setState({
            series: series,
            current: 0,
            numberinbox: 0,
            results: [],
            message: null,
            message2: null,
            showresults: false,
            moved: 0,
            ready: false,
            timeout: false
        });
    }

    handleChange = (num) => {
        this.setState({ numberinbox: parseInt(num), moved: 1 });
    }

    render() {
        const props = this.props;
        const currentNumber = this.state.series[this.state.current];
        const message = this.state.message;
        const message2 = this.state.message2;
        const ready = this.state.ready;
        const timeout = this.state.timeout;
        const rate = this.state.results[this.state.current];
        const messagestyle = message ? (!rate ? ' error' : ' valid') : '';
        const showresults = this.state.showresults;
        const percent = parseInt((this.state.current) / props.params.num * 100);

        const is_test = props.params.is_test ? props.params.is_test : false;
        const stillrows = !ready ? Array.from(Array(props.params.rows).keys()) : [];

        const params = { ...props.params, number: currentNumber, stillrows };
        // console.log('MentalAbacusFixation.render:', ready, timeout);

        if (!showresults) {
            return (
                <Row className="exercise">
                    <ChangeOrientation to={'portrait'} />

                    {!is_test ? <Col span={22} className='progress-bar'>
                        <Progress percent={percent} showInfo={false} />
                    </Col> : null}

                    <Col span={24} className="exercise-body">
                        <ExerciseBlocker show={messagestyle} />
                        <section className="mentalabacusaixation">
                            {!ready && !timeout ? <article key={currentNumber}>
                                <AbacusView params={params} isactive={false} hidelatframe={true} hideheaderlabels={true} />
                                <div className='maf_message'>
                                    <img src="./static/myspace/vision.svg" alt="eyebrow" />
                                </div>
                            </article> : null}

                            {ready && !timeout ? <article className="clock">
                                <div className="blank closemessage">
                                    <div>
                                        {/* <img src="./static/myspace/eyebrow.svg" alt="eyebrow" /> */}
                                    </div>
                                </div>
                                <img src="./static/utils/clock.gif" alt="clock" />
                            </article> : null}
                            {ready && timeout ? <article className="setabacus">
                                <AbacusView params={{ ...params, number: this.state.numberinbox }} isactive={true} hidelatframe={true} hideheaderlabels={true} onChange={this.handleChange} />
                                <div className='maf_message'>
                                    <img src="./static/myspace/hand_touch.svg" />
                                </div>
                            </article> : null}

                        </section>
                    </Col>
                    <Col span={24}>
                        <Row className={'botones' + messagestyle}>
                            <Col span={24} className={'message'}>{message}</Col>

                            {!message && !ready ? <Col span={24}><Button key="submit" type="primary" onClick={this.handleReady} style={{ 'width': '90%' }}>{t(props.lang, 'ready')}!</Button></Col> : null}

                            {!message && ready && timeout ? <Col span={24}><Button key="submit" type="primary" onClick={this.handleRate} style={{ 'width': '90%' }} disabled={!this.state.moved}>{t(props.lang, 'grade')}</Button></Col> : null}

                            {message && ready ? <Col span={24}><Button key="submit" type="primary" onClick={this.handleNext} style={{ 'width': '90%' }}>{t(props.lang, 'next')}</Button></Col> : null}

                            {message2 ? <Col span={24} className={'message'}>{message2}</Col> : null}

                        </Row>
                    </Col>
                </Row >
            );
        } else {
            return (
                <Result params={props.params} results={this.state.results} handleRepeat={this.handleRepeat} handleCancel={props.handleCancel} lang={props.lang} />
            );
        }
    };
}

export default MentalAbacusFixation;

