import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Progress } from 'antd';
import Result from '../../../base/result';

import { motion } from 'framer-motion';
import { t } from '../../../../languages';

import './index.less';
import ExerciseBlocker from '../../../Utils/ExerciseBlocker';
import ChangeOrientation from '../../../Utils/ChangeOrientation';
import MyTimer from '../../../Utils/time/MyTimer';
import Mesh from '../../../base/Mesh';
import { ANIMALS, shuffle } from '../../../../utils';


let timer;

const MemoMeshChallenge = ({ lang, params, uid, eid, saveResult, handleCancel }) => {
    // console.log('MemoMeshChallenge:', lang, params, uid, eid);

    const [objects, setObjects] = useState([]);
    const [guess, setGuess] = useState(false);
    const [currentObj, setCurrentObj] = useState(0);

    const [current, setCurrent] = useState(0);
    const [numberinbox, setNumberinbox] = useState(0);
    const [results, setResults] = useState([]);
    const [message, setMessage] = useState(null);
    const [message2, setMessage2] = useState(null);
    const [showresults, setShowresults] = useState(false);
    const [showPath, setShowPath] = useState(false);
    const [moved, setMoved] = useState(false);

    const [times, setTimes] = useState([]);
    const [stopTime, setStopTime] = useState(false);
    const [isTimeout, setIsTimeout] = useState(false);


    useEffect(() => {
        handleRepeat();

        return () => {
            // console.log("MemoMeshChallenge.useEffect clean timeout...");
            clearTimeout(timer);
        };
    }, []);


    const handleRate = (item) => {
        // console.log("MemoMeshChallenge.handleRate:", item, numberinbox);

        const success = item.val === numberinbox ? 1 : 0;

        let message = null;
        let message2 = null;

        if (success) {
            let sucmess = 'verywellkeepon';
            if (currentObj % 2 == 0) sucmess = 'rightpos';
            if (currentObj == objects.length - 2) sucmess = 'almostyou';
            if (currentObj == objects.length - 1) sucmess = 'yujumakeit';

            message = t(lang, sucmess);
            message2 = null; //t(lang, 'great');
        } else {
            message = t(lang, 'wrongpos');
            message2 = null; //t(lang, 'nodiscorage');
            setShowPath(true);
        }

        if (results.length >= current) results[current] = success;
        else results.push(success);

        setResults(results);
        setMessage(message);
        setMessage2(message2);

        setMoved(true);

        setStopTime(true);
    }

    const handleNext = () => {

        const currResultOK = results[current];
        // console.log("MemoMeshChallenge.handleNext:", current, currentObj, results, currResultOK, times);

        let finish = false;
        if (current < params.num) {
            if (currResultOK && currentObj < objects.length - 1) {
                const nextObj = currentObj + 1;
                setNumberinbox(objects[nextObj].val);
                setCurrentObj(nextObj);
            } else {
                const next = current + 1;
                if (next >= params.num) finish = true;
                else setCurrent(next);

                shuffle(ANIMALS);
                const objects = ANIMALS.slice(0, params.num_objects);

                setObjects(objects);
                setGuess(false);

                setCurrentObj(0);
                setNumberinbox(objects[0].val);

                if (!finish) {
                    timer = setTimeout(() => {
                        setGuess(true);
                    }, params.wait * 1000);
                }
            }

            setMessage(null);
            setMessage2(null);
            setShowresults(false);
            setShowPath(false);
            setMoved(false);
            setStopTime(false);

        }

        if (finish) {
            const num = params.num;
            const hits = results.reduce((acc, aR) => acc += aR);
            const errors = num - hits;
            const passed = errors > params.errors ? false : true;
            const coins = params.coins;

            const totalTime = times.reduce((acc, aT) => acc += aT, 0);

            saveResult(uid, `${uid}-${eid}`, { uid, eid, p: passed, h: hits, e: errors, time: totalTime, c: (passed ? coins : 0) }, times, false);
            setShowresults(true);
        }
        setShowPath(false);
    }

    const handleRepeat = () => {
        // console.log('MemoMeshChallenge.handleRepeat:', params.num);

        shuffle(ANIMALS);
        const objects = ANIMALS.slice(0, params.num_objects);

        setObjects(objects);
        setGuess(false);
        // console.log('MemoMeshChallenge.objects:', params.num_objects, objects);

        setCurrent(0);
        setCurrentObj(0);
        setNumberinbox(objects[0].val);
        setResults([]);
        setMessage(null);
        setShowresults(false);
        setShowPath(false);
        setMoved(false);

        setStopTime(false);
        setTimes([]);
        setIsTimeout(false);

        timer = setTimeout(() => {
            setGuess(true);
        }, params.wait * 1000);
    }

    const handleStop = (time) => {
        const lTimes = [...times];
        // lTimes.push(time);
        lTimes[current] = time;
        setTimes(lTimes);
    }

    const handleExpire = () => {

        const num = params.num;
        const hits = results && results.length > 0 ? results.reduce((acc, aR) => acc += aR) : 0;
        const errors = num - hits;
        const passed = errors > params.errors ? false : true;

        const is_test = params.is_test ? params.is_test : false;

        saveResult(uid, `${uid}-${eid}`, { uid, eid, p: passed, h: hits, e: errors, time: params.timeout }, [], true);

        if (!is_test) {
            setTimes([params.timeout]);
            setStopTime(true);
            setIsTimeout(true);
            setShowresults(true);
        }
    }

    const rate = results[current];
    const messagestyle = message ? (!rate ? ' error' : ' valid') : '';
    const percent = parseInt((current) / params.num * 100);

    const is_test = params.is_test ? params.is_test : false;


    if (!showresults) {
        const time = new Date();
        time.setSeconds(time.getSeconds() + params.timeout);

        const theObject = objects[currentObj];

        return (
            <Row className="exercise memomesh">
                <ChangeOrientation to={'portrait'} />

                {!is_test ? <Col span={22} className='progress-bar'>
                    <div className='progress'><Progress percent={percent} showInfo={false} /> </div>
                </Col> : null}

                <Col span={24} className='exercise-timer'>
                    {guess ? <MyTimer expiryTimestamp={time} stopTime={stopTime} handleExpire={handleExpire} handleStop={(t) => handleStop(t)} /> : null}
                </Col>

                <Col span={24} className="exercise-body memomesh-body">

                    <section className="memomesh-section">
                        <ExerciseBlocker show={messagestyle} />

                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 1.5,
                            }}
                            className="the-number"
                        >

                            <Mesh
                                rows={4}
                                cols={4}
                                objects={objects}
                                position={'random'}
                                guess={guess}
                                onClick={handleRate}
                            />
                            <br />
                            <div className='indicators'>
                                {!guess ? <img src="./static/utils/clock.gif" alt="clock" /> : <img src={`./static/animals/${theObject.img}`} />}
                            </div>

                        </motion.div>
                    </section>
                </Col>
                <Col span={24}>
                    <Row className={'botones pathchall' + messagestyle}>
                        <Col span={24} className={'message'}>{message}</Col>
                        {!message ? <Col span={24}><Button key="submit" type="primary" onClick={handleRate} style={{ 'width': '90%' }} disabled={!moved}>{t(lang, 'grade')}</Button></Col> : null}
                        {message ? <Col span={24}><Button key="submit" type="primary" onClick={handleNext} style={{ 'width': '90%' }}>{t(lang, 'next')}</Button></Col> : null}
                        {message2 ? <Col span={24} className={'message'}>{message2}</Col> : null}
                    </Row>
                </Col>
            </Row >
        );
    } else {
        return (
            <Result params={params} results={results} show_time={true} times={times} is_timeout={isTimeout} handleRepeat={handleRepeat} handleCancel={handleCancel} lang={lang} />
        );
    }
};

export default MemoMeshChallenge;
