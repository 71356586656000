/* eslint no-eval: 0 */
import React from 'react'

import { Row, Col, Button, Progress } from 'antd';
import AbacusView from '../../base/abacus';
import Result from '../../base/result';
import { getSeriesFromCouples } from '../../../utils';

import './index.less';
import { motion } from 'framer-motion';
import { t } from '../../../languages';
import ExerciseBlocker from '../../Utils/ExerciseBlocker';
import ChangeOrientation from '../../Utils/ChangeOrientation';

class MentalSumSubstraction extends React.Component {
    state = {
        eid: 0,
        series: [],
        current: 0,
        results: [],
        times: [],
        numberinbox: 0,
        message: null,
        message2: null,
        showresults: false,
        moved: 0,
        ready: false,
        timeout: false,
        splitOps: null,
        currentOps: 0,
        wait: 2
    }

    UNSAFE_componentWillMount() {
        const params = this.props.params;
        const upperlimit = params.upperlimit ? params.upperlimit : (Math.pow(10, params.rows) - 1);
        const series = getSeriesFromCouples(params.couples, params.num, params.init, params.finish, params.ops, params.config, params.ispan, params.fspan, 0, upperlimit);
        const splitSerie = series[0].split(/(\+|-|\*|\/)/sg);
        const splitOps = [];
        const wait = params.wait ? params.wait : 2;

        splitOps.push(splitSerie[0]);
        for (let idx = 1; idx < splitSerie.length; idx += 2) {
            splitOps.push(splitSerie[idx] + splitSerie[idx + 1]);
        }
        // console.log('MentalSumSubstraction.componentDidMount:', series[0],splitSerie, splitOps);

        this.setState({ series, splitOps, wait });
    }

    handleReady = () => {
        setTimeout(() => {
            const currentOps = this.state.currentOps;
            this.setState({ currentOps: currentOps + 1 })
            if (currentOps < this.state.splitOps.length - 1) this.handleReady();
            else this.setState({ timeout: true });
        }, (this.state.wait * 1000));
        this.setState({ ready: true });
    }

    handleRate = () => {
        const results = this.state.results;
        const splitJoin = this.state.splitOps.join('');
        // console.log('MentalSumSubstraction.handleNext:', this.state, splitJoin);
        const currentNumber = eval(splitJoin);
        const current_result = currentNumber === this.state.numberinbox ? 1 : 0;
        const message = current_result ? t(this.props.lang, 'rightanswer') : `${t(this.props.lang, 'yourwrong')} ${currentNumber}`;
        const message2 = current_result ? t(this.props.lang, 'great') : t(this.props.lang, 'nodiscorage');
        results.push(current_result);
        this.setState({
            results,
            message, message2
        });
    }

    handleNext = () => {
        const current = this.state.current;

        if (current < this.state.series.length - 1) {
            const series = this.state.series;
            const splitSerie = series[current + 1].split(/(\+|-|\*|\/)/sg);
            const splitOps = [];

            splitOps.push(splitSerie[0]);
            for (let idx = 1; idx < splitSerie.length; idx += 2) {
                splitOps.push(splitSerie[idx] + splitSerie[idx + 1]);
            }

            this.setState({
                current: current + 1,
                numberinbox: 0,
                message: null,
                message2: null,
                moved: 0,
                ready: false,
                timeout: false,
                splitOps,
                currentOps: 0
            });
        } else {
            const num = this.props.params.num;
            const hits = this.state.results.reduce((acc, aR) => acc += aR);
            const errors = num - hits;
            const passed = errors > this.props.params.errors ? false : true;
            const uid = this.props.uid;
            const eid = this.props.eid;
            const coins = this.props.params.coins;

            const is_test = this.props.params.is_test ? this.props.params.is_test : false;

            this.props.saveResult(uid, `${uid}-${eid}`, { uid, eid, p: passed, h: hits, e: errors, c: (passed ? coins : 0) });
            if (!is_test) this.setState({ showresults: true });
        }
    }

    handleRepeat = () => {
        const params = this.props.params;
        const upperlimit = params.upperlimit ? params.upperlimit : (Math.pow(10, params.rows) - 1);
        const series = getSeriesFromCouples(params.couples, params.num, params.init, params.finish, params.ops, params.config, params.ispan, params.fspan, 0, upperlimit);


        const splitSerie = series[0].split(/(\+|-|\*|\/)/sg);
        const splitOps = [];
        splitOps.push(splitSerie[0]);
        for (let idx = 1; idx < splitSerie.length; idx += 2) {
            splitOps.push(splitSerie[idx] + splitSerie[idx + 1]);
        }

        this.setState({
            series: series,
            current: 0,
            numberinbox: 0,
            results: [],
            message: null,
            message2: null,
            showresults: false,
            moved: 0,
            ready: false,
            timeout: false,
            splitOps,
            currentOps: 0
        });
    }

    handleChange = (num) => {
        this.setState({ numberinbox: parseInt(num), moved: 1 });
    }

    render() {
        const props = this.props;
        const splitOps = this.state.splitOps;
        const current = this.state.current;
        const currentOps = this.state.currentOps;
        const currentNumber = splitOps[currentOps];

        const message = this.state.message;
        const message2 = this.state.message2;

        const ready = this.state.ready;
        const timeout = this.state.timeout;
        const rate = this.state.results[current];
        const messagestyle = message ? (!rate ? ' error' : ' valid') : '';
        const showresults = this.state.showresults;
        const percent = parseInt((current) / props.params.num * 100);

        const is_test = this.props.params.is_test ? this.props.params.is_test : false;
        const stillrows = !ready ? Array.from(Array(props.params.rows).keys()) : [];

        const params = { ...props.params, number: 0, stillrows };
        // console.log('MentalSumSubstraction.render:', props, this.state);

        if (!showresults) {
            return (
                <Row className="exercise">
                    <ChangeOrientation to={'portrait'} />

                    {!is_test ? <Col span={22} className='progress-bar'>
                        <Progress percent={percent} showInfo={false} />
                    </Col> : null}

                    <Col span={24} className="exercise-body">
                        <ExerciseBlocker show={messagestyle} />
                        <section className="mentalsumsubstraction">
                            {!ready && !timeout ? <article className="animated bounceIn" key={currentNumber}>
                                <AbacusView params={params} isactive={false} hidelatframe={true} hideheaderlabels={true} />
                            </article> : null}

                            {ready && !timeout ? <article className="number" key={currentOps}>
                                <motion.div
                                    animate={{ scale: 2 }}
                                    transition={{ duration: 1 }}
                                    className="the-number"
                                >{currentNumber}</motion.div>
                            </article> : null}

                            {ready && timeout ? <article className="setabacus">
                                <AbacusView params={{ ...params, number: this.state.numberinbox }} isactive={true} hidelatframe={true} hideheaderlabels={true} onChange={this.handleChange} />
                            </article> : null}

                        </section>
                    </Col>
                    <Col span={24}>
                        <Row className={'botones' + messagestyle}>
                            <Col span={24} className={'message'}>{message}</Col>

                            {!message && !ready ? <Col span={24}><Button key="submit" type="primary" onClick={this.handleReady} style={{ 'width': '90%' }}>{t(props.lang, 'ready')}!</Button></Col> : null}

                            {!message && ready ? <Col span={24}><Button key="submit" type="primary" onClick={this.handleRate} style={{ 'width': '90%' }} disabled={!this.state.moved}>{t(props.lang, 'grade')}</Button></Col> : null}

                            {message && ready ? <Col span={24}><Button key="submit" type="primary" onClick={this.handleNext} style={{ 'width': '90%' }}>{t(props.lang, 'next')}</Button></Col> : null}

                            {message2 ? <Col span={24} className={'message'}>{message2}</Col> : null}

                        </Row>
                    </Col>
                </Row >
            );
        } else {
            return (
                <Result params={props.params} results={this.state.results} handleRepeat={this.handleRepeat} handleCancel={props.handleCancel} lang={props.lang} />
            );
        }
    };
}

export default MentalSumSubstraction;

