import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Collapse, Spin } from 'antd';
import { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { openBrowser } from '../../InAppBrowser';
import { getMobileOperatingSystem, isValidActiveClient } from '../../utils';

import ModalLoader from '../../components/Course/ModalLoader';

import { loadResponses, saveResponses } from '../../modules/response';

import { t } from '../../languages';

import showLevelUnits from '../../components/Course/LevelUnits';


import { Redirect, RouteComponentProps } from 'react-router';
import SectionHeader from '../../components/Utils/SectionHeader';
import { User } from '../../models/user';
import { lockPortrait, unlockScreen } from '../../utils/screen';

import showLevelTest from '../../components/Course/LevelTest';
import SubscribeButton from '../../components/Subscription/SubscribeButton';
import courses from '../../data/course';
import { recordPageGA } from '../../modules/ga';
import '../Learn/index.less';
import './index.less';
import { PRODUCTS } from '../../modules/user';
import BottomMenu from '../../components/Menu';

const innerHeight = window.innerHeight;

interface IProps extends RouteComponentProps<any> {
  user: User;
  results: any;
  lang: string;
  saveUser: (user: User) => void;
  loadResponses: (activeChild: any) => void;
  saveResponses: (uid, ex, result) => void;
  isLoading: boolean;
  saved: boolean;
  error: boolean;
  transactions: any;
}

const LIMITLEVEL = 20;
const LIMITUNIT = 0;
const HASSUBSCRIPTION = false;


const rateUrl =
  getMobileOperatingSystem() == 'web'
    ? 'market://details?id=com.enmotionvalue.misoroban'
    : 'https://play.google.com/store/apps/details?id=com.enmotionvalue.misoroban';

const moveToScrollPoition = () => {
  const courseElem = document.querySelector('.course');
  const lastScrPos = localStorage.getItem("my-last-scroll-position");
  if (courseElem && lastScrPos) {
    // console.log('load lastScrPos:', lastScrPos);
    courseElem.scrollTop = parseInt(lastScrPos);
  }
}

const WarningLevel = ({ lang }) => (
  <article className="warning-level">
    <h5 className="title">
      <ExclamationCircleOutlined /> {t(lang, 'levelnoaviable')}
    </h5>
    <div>{t(lang, 'levelnoaviabletxt')}</div>
    <Button ghost onClick={() => openBrowser(rateUrl)}>
      {t(lang, 'rate')}
    </Button>
  </article>
);

const Course = ({
  user,
  results,
  lang,
  saveUser,
  loadResponses,
  saveResponses,
  isLoading,
  saved,
  error,
  transactions
}: IProps) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const [currentPlan, setcurrentPlan]: [any, any] = useState(null);
  const [hasSubscription, setHasSubscription]: [any, any] = useState(HASSUBSCRIPTION);
  const [limitUnit, setLimitUnit]: [any, any] = useState(LIMITUNIT);
  const [redirectToRoot, setRedirectToRoot]: [any, any] = useState(false);

  useEffect(() => {
    // console.log('Course useEffect[]:', user);
    recordPageGA('/course');
    /* const activeChild = user ? (user.isParent ? user.activeChild : user.id) : null;
          
    if (activeChild) {
      loadResponses(activeChild);
    } */

    if (user) {
      if (!user.products || (user.products && !user.products.includes(PRODUCTS.COURSE))) {
        setRedirectToRoot(true);
      } else {
        loadResponses(user.id);
        setcurrentPlan(courses[user.age ? user.age : 1]);
      }
    }

  }, []);

  useEffect(() => {
    const isActiveClient = isValidActiveClient(user);
    // console.log('Course useEffect[transactions]:', transactions, user, isActiveClient);

    /* REAL */
    let hasSubscription = (transactions && transactions.length > 0) || isActiveClient;

    /* test NO SUBSCRIPTIONS */
    // hasSubscription = false;


    /* test with SUBSCRIPTIONS */
    // hasSubscription = true;


    setHasSubscription(hasSubscription);
    setLimitUnit(hasSubscription ? 0 : LIMITUNIT)
  }, [transactions]);


  useEffect(() => {
    // console.log('Course useEffect[results]:', results);
    moveToScrollPoition();
  }, [results]);

  const showModal = cont => {
    setModalContent(cont);
    setModalVisible(true);
  };

  useEffect(() => {
    if (!modalVisible) unlockScreen();
  }, [modalVisible]);

  const handleOk = e => {
    // console.log('Course.handleOk', e);
    setModalVisible(false);
  };

  const handleCancel = e => {
    // console.log('Course.handleCancel', e);
    setModalVisible(false);
    lockPortrait();
  };

  const handleAccChange = panel => { };

  const userId = user ? user.id : null;
  const current_level = results ? results['current_level'] : null;
  const currentLevel = (current_level && currentPlan) ? currentPlan.find(aL => aL.level === current_level) : null;
  const activeKey = (currentLevel && currentLevel.open) ? (results && results['current'] ? results['current'] : '') : '';

  const divStyle: any = {
    // height: `${innerHeight - 53}px`,
    overflowX: 'hidden',
    overflowY: 'scroll',
    paddingBottom: '53px'
  };

  // console.log('Course: current_level:', current_level);
  // console.log('Course: trace....', isLoading, results, currentPlan);
  // console.log('Course redirectToRoot:', redirectToRoot);

  if (!user) return <Redirect to="/profile/login" />;
  else if (redirectToRoot) return <Redirect to="/" />;
  else
    return (
      <section className='MiSoroban'>
        <ModalLoader
          title={modalTitle}
          visible={modalVisible}
          handleOk={handleOk}
          handleCancel={handleCancel}
          content={modalContent}
          className="exercise-modal"
        />

        <div style={divStyle} className="learn course">
          <div className='mis-wrapper'>
            <SectionHeader section={'course'} />

            {isLoading ? <Spin size="large" className="spinner" /> : null}
            {!results || !currentPlan ? null : currentPlan.map((aP, idx) => {
              const current_level = results.current_level;

              return (
                <article className="level" data-level={idx + 1} key={idx}>
                  {aP.level == LIMITLEVEL && !hasSubscription ? (
                    <SubscribeButton lang={lang} show={hasSubscription} />
                  ) : null}

                  <div className={`level-separator ${(current_level > aP.level && hasSubscription) ? 'passed' : 'passed'
                    }`}>
                    <span key="1"></span>
                    <span key="2">{t(lang, aP.label)}</span>
                    <span key="3"></span>
                  </div>

                  <Collapse
                    key={idx}
                    accordion
                    bordered={false}
                    defaultActiveKey={activeKey}
                    onChange={handleAccChange}>
                    {aP.units ? showLevelUnits(
                      aP,
                      showModal,
                      handleCancel,
                      results,
                      saveResponses,
                      lang,
                      limitUnit,
                      true,
                      'course'
                    ) : null}
                    {aP.test ? showLevelTest(
                      aP,
                      showModal,
                      handleCancel,
                      results,
                      saveResponses,
                      lang,
                      true,
                      'course'
                    ) : null}

                  </Collapse>

                </article>
              );
            })}
          </div>
        </div>

        <BottomMenu section={'course'} />
      </section>
    );
};

const mapStateToProps = ({ user, response, subscription }) => ({
  user: user.user,
  // results: user.results,
  results: response.data,
  isLoading: response.isFetching,
  lang: user.lang,
  products: subscription.products,
  transactions: subscription.transactions
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadResponses,
      saveResponses
    },
    dispatch
  );

export default memo(connect(mapStateToProps, mapDispatchToProps)(Course));
