import { Button, Checkbox, Input, Modal, Select, Spin, notification } from 'antd';
import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { t } from '../../../languages/index';

import { User } from '../../../models/user';
import { getPlatform } from '../../../utils';
import AvatarPicker from '../AvatarPicker';
import TOS from '../TermsOfService';

const { Option } = Select;

const validateEmail = value => {
  const re = /\S+@\S+\.\S+/;
  return re.test(value);
};

const openNotificationWithIcon = (type, message, description, closeCallback) => {
  notification[type]({
    message: message,
    description: description,
    duration: 2,
    key: 'registernotification',
    onClose: closeCallback
  });
};


const Step1 = ({ lang, user, save, isLoading, saved, error }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [country, setCountry]: [any, any] = useState('none');/* useState(undefined); */
  const [tos, setTos] = useState(false);
  const [tosVisible, setTosVisible] = useState(false);
  const [avatar, setAvatar] = useState(0);
  const [isOk, setIsOk] = useState(false);
  const [next, setNext] = useState(false);

  useEffect(() => {
    if (error) openNotificationWithIcon('error', 'No ha sido posible identificarse. Revisa tus datos', '', () => { });

    // console.log('name, email, country, tos:', name, email, country, tos);
    const isOk: any = name && validateEmail(email) && tos;
    setIsOk(isOk);

  }, [name, email, country, tos]);

  const saveStep = () => {
    const aUser: User = user || new User();
    // aUser.isParent = false;
    aUser.name = name;
    aUser.email = email;
    aUser.language = lang;
    aUser.country = country;
    aUser.avatar = avatar;
    aUser.acceptConditions = tos;
    aUser.source = getPlatform();
    save(aUser);
    // setNext(true);
  }

  if (next) return <Redirect to="/register/step4" />
  else if (saved) return <Redirect to="/register/step4" />
  else return (
    <section className="register">
      {isLoading ? <Spin size="large" className="spinner" /> : null}
      <h2>{t(lang, 'wellcome')}</h2>
      <p>{t(lang, 'alwaysconnected')}</p>
      <form>
        <div>
          <Input placeholder={t(lang, 'yourname')} value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className="gmail-enter">
          <Input placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        {/* <div>
          <h3 className="country-h3">{t(lang, 'country')}</h3>
          <Select
            className="pais"
            showSearch
            onChange={(country) => { setCountry(country) }}
            value={country}
            optionFilterProp="label">
            {COUNTRY_ITEMS.map(aC => (
              <Option key={aC.value} value={aC.value} label={aC.label}>
                {aC.label}
              </Option>
            ))}
          </Select>
        </div> */}
        <div>
          <AvatarPicker lang={lang} handleChange={(anV) => setAvatar(anV)} selected={0} />
          <div>{t(lang,'chooseanavatar')}</div>
        </div>
        <br />
        <div className="TOS">
          <Checkbox onChange={(e) => setTos(e.target.checked)}>
            {t(lang, 'readAndAccept')}
            <span className="tos-name" onClick={(e) => setTosVisible(true)}> {t(lang, 'TOS')}</span>
          </Checkbox>
          <Modal
            title={t(lang, 'TOS')}
            visible={tosVisible}
            className="rules-modal" onCancel={() => setTosVisible(!tosVisible)}
            onOk={() => setTosVisible(!tosVisible)}
          >
            <TOS lang={lang} />
          </Modal>
        </div>
        <br />

        <div className="button-cont">
          <Button className="button-next" type="primary" disabled={!isOk} onClick={saveStep}>{t(lang, 'continue')}</Button>
        </div>
      </form>

    </section>
  );
};
export default Step1;
