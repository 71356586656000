import React, { useEffect, useRef, useState } from 'react'

import { getSeriesFromCouples, shuffle } from '../../../utils';


import { generateRandomNumberSeries } from '../../../utils';
import { randomposition, move } from '../../../utils/move';
import './index.less';

const Baloons = (props) => {
    const [items, setItems] = useState([]);
    const [time, setTime] = useState(0);
    const [timer, setTimer] = useState(null);
    const [good, setGood] = useState(false);
    const [bad, setBad] = useState(false);
    const [remaining, setRemaining] = useState(null);
    const [rows, setRows] = useState(null);

    const containerRef = useRef();

    useEffect(() => {
        // console.log('Baloons.useEffect:', props)
        const num_items = props.items;
        const numbers = generateRandomNumberSeries(num_items, props.init, props.finish, [props.value]);
        // console.log('Baloons.useEffect value numbers:', props.value, numbers);

        const width = 80;
        const height = 130;
        const ops = [-1.5, -1, 1, 1.5];
        const delta = { dx: ops[Math.floor(Math.random() * ops.length)], dy: ops[Math.floor(Math.random() * ops.length)] };

        let lItems = [];
        lItems.push({ val: props.value, pos: { x: 0, y: 0, w: width, h: height }, d: delta });

        for (let idx = 0; idx < num_items; idx++) {
            const delta = { dx: ops[Math.floor(Math.random() * ops.length)], dy: ops[Math.floor(Math.random() * ops.length)] };

            lItems.push({ val: numbers[idx], pos: { x: 0, y: 0, w: width, h: height }, d: delta });
        }

        const boundingClient = containerRef.current.getBoundingClientRect();
        const cont_width = parseInt(boundingClient.width);
        const cont_height = parseInt(boundingClient.height);
        lItems = lItems.map(aI => randomposition(cont_width, cont_height, aI));

        // console.log('Baloons.useEffect lItems:', lItems);

        setItems(shuffle(lItems));

        const date = new Date();
        setTime(date.getTime());
        setRemaining(num_items);

        setRows(props.rows);

    }, []);

    useEffect(() => {
        const boundingClient = containerRef.current.getBoundingClientRect();
        const cont_width = parseInt(boundingClient.width);
        const cont_height = parseInt(boundingClient.height);
        const vel = props.vel;

        const timer = setInterval(() => {
            const tItems = [...items];
            for (let idx = 0; idx < tItems.length; idx++) {
                const item = tItems[idx];
                const delta = move(cont_width, cont_height, item);
                // console.log('Baloons.useEffect[items] delta:',delta);
                item.d = delta;
                item.pos.x += delta.dx;
                item.pos.y += delta.dy;
            }

            setItems(tItems);

        }, vel);

        setTimer(timer);

        return () => {
            clearInterval(timer);
        };

    }, [items]);


    const clickHandle = (idx, item) => {
        // console.log('Baloons.clickHandle:', idx, item, this.props.value);
        if (item.val === props.value) {
            clearInterval(timer);
            const date = new Date();
            setGood(true);
            setBad(false);

            setTimeout(() => {
                props.onComplete(date.getTime() - time);
            }, 200);

        } else {
            setGood(false);
            setBad(true);
        }

        setTimeout(() => {
            setGood(false);
            setBad(false);
        }, 500);

    }

    // console.log('Baloons.render:', items)


    return (
        <div className={`baloon-list ${remaining ? 'completed' : ''}`} ref={containerRef}>

            {good ? <audio src="./static/sounds/pop.mp3" autoPlay={true} loop={false}></audio> : null}
            {bad ? <audio src="./static/sounds/jump.mp3" autoPlay={true} loop={false}></audio> : null}

            {items ? items.map((aI, idx) => {
                const val = aI.val;
                let digits = val.toString().split('');
                if (rows && digits && digits.length < rows) digits = ['0'].concat(digits);

                const pos = aI.pos;
                return (<div key={idx} className={`baloon baloon_back b${idx + 1}`} onClick={() => clickHandle(idx, aI)} style={{ left: pos.x, top: pos.y }}>
                    {digits.map((el, edx) => {
                        return <img key={edx} className="bead" src={`./static/snapshots/a${el}.png`} alt="bead" />
                    })}
                </div>);
            }) : null}
        </div>
    );

}

export default Baloons;