const resource1 = [
    /**level 0: bienvenida **/
    {
        level: 1,
        label: 'welcome',
        open: true,
        test:
        {
            tid: 'rw1',
            label: 'howtouseresources',//'El ábaco japonés',
            icon: 'course/welcome.jpg',
            level: 1,
            exercises: [
                {
                    label: 'welcome',
                    impl: 'theory',
                    icon: 'video',
                    params: { video: 'bKqC87zHt1Y', coins: 10 }
                },

            ]
        },
    },

    /**level 2: base, suma resta sencillas **/
    {
        level: 2,
        label: 'numbers',
        open: true,
        units: [
            {
                uid: 'r1',
                label: 'intro',
                icon: 'course/intro.jpg',
                single: 'true',
                exercises: [
                    {
                        label: 'knowtheabacus',
                        impl: 'theory',
                        icon: 'video',
                        params: { text:{title:'module1title', content:'module1intro'}, coins: 10 }
                    }
                ]
            },

            {
                uid: 'r2',
                label: 'discover',//'El ábaco japonés',
                icon: 'course/numbers.jpg',
                exercises: [
                    {
                        label: 'knowtheabacus',
                        impl: 'theory',
                        icon: 'video',
                        params: { pdf: 'https://misoroban.com/assets/press-kit/files/Nosotros.pdf', coins: 10 }
                    },

                ]
            },
            
            {
                uid: 'r3',
                label: 'practice',//'El ábaco japonés',
                icon: 'course/numbers.jpg',
                exercises: [
                    {
                        label: 'knowtheabacus',
                        impl: 'theory',
                        icon: 'video',
                        params: { pdf: 'https://misoroban.com/assets/press-kit/files/Nosotros.pdf', coins: 10 }
                    },

                ]
            },
            
        ],
        test:
        {
            tid: 'tr1',
            label: 'challengeyourself',//Acceder al nivel,
            level: 2,
            icon: 'course/test.jpg',
            exercises: [
                {
                    label: 'baloonschall',//El reto de los globos,
                    impl: 'baloonschall',
                    params: {
                        couples: 'random', init: 111, finish: 999, rows: 3, valueinabacus: 0, 'upperlimit': 999, coins: 10, num: 1, errors: 0,
                        levels: [{ num: 8, vel: 10 }, { num: 8, vel: 10 }]
                    }
                },

                {
                    label: 'repnumbers',//'Representa',
                    impl: 'representinabacus',
                    icon: 'practica',
                    params: { series: [{ init: 100, finish: 999, num: 2 }], init: 1, finish: 9, rows: 3, coins: 10, num: 5, errors: 2 }
                },

                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: 'random', ops: ['+'], init: 299, finish: 599, ispan: 199, fspan: 399, rows: 3, coins: 10, num: 3, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: 'random', ops: ['-'], init: 599, finish: 899, ispan: 199, fspan: 399, rows: 3, coins: 10, num: 3, errors: 0, cansubstract: true }
                },

                {
                    label: 'mentalsumchall',
                    impl: 'mentalsumsubstraction',
                    params: { couples: 'random', ops: ['+'], init: 111, finish: 299, ispan: 111, fspan: 199, valueinabacus: 0, rows: 3, coins: 10, num: 1, config: [{ digits: 3, num: 3 }, { digits: 2, num: 3 }, { digits: 1, num: 3 }], wait: 5, errors: 0, cansubstract: true }
                },
                {
                    label: 'mentalsubchall',
                    impl: 'mentalsumsubstraction',
                    params: { couples: 'random', ops: ['-'], init: 711, finish: 999, ispan: 111, fspan: 199, valueinabacus: 0, rows: 3, coins: 10, num: 1, config: [{ digits: 3, num: 3 }, { digits: 2, num: 3 }, { digits: 1, num: 3 }], wait: 5, errors: 0, cansubstract: true }
                }
            ]
        },
    },

];


const resources = {
    1: resource1,
    2: resource1,
    3: resource1,
}

export default resources;