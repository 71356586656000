/* eslint no-eval: 0 */
import React from 'react'

import { Row, Col, Button, Progress } from 'antd';
import Baloons from '../../base/Baloons';
import Result from '../../base/result';

import { getSeriesFromCouples } from '../../../utils';

import './index.less';
import { motion } from 'framer-motion';
import { t } from '../../../languages';
import ExerciseBlocker from '../../Utils/ExerciseBlocker';
import ChangeOrientation from '../../Utils/ChangeOrientation';

class BaloonSchall extends React.Component {
    state = {
        eid: 0,
        series: [],
        current: 0,
        results: [],
        message: null,
        message2: null,
        showresults: false,
        levels: []
    }

    UNSAFE_componentWillMount() {
        /* const params = this.props.params;
        const couples = params.couples;
        const series = getSeriesFromCouples(couples, params.levels.length, params.init, params.finish, params.ops, params.config, params.ispan, params.fspan);


        const levels = params ? params.levels : null;
        console.log('BaloonSchall.componentWillMount:', params, series);
        this.setState({ series, levels }); */
        this.handleRepeat();
    }

    handleRate = (timereq) => {
        const results = this.state.results;
        const current_result = timereq;
        const current = this.state.current;
        // console.log('BaloonSchall.handleRate:', this.state.series, current);
        let currentNumber = '' + this.state.series[current];
        const value = Math.floor(eval(currentNumber));
        const ops = currentNumber.match(/(\+|-|\*|\/)/sg);

        currentNumber = currentNumber.replace('*', ' x ').replace('/', ' ÷ ').replace('+', ' + ').replace('-', ' - ');

        const ops_result = ops ? ` ${currentNumber} = ${value}` : '';

        const message = `${t(this.props.lang, 'rightanswer')}: ${ops_result}`; //`¡Bravo ${ops_result}!, ${t(this.props.lang, 'surpassedin')} ${parseInt(current_result / 1000)} ${t(this.props.lang, 'seconds')}`;
        const message2 = current_result ? t(this.props.lang, 'great') : t(this.props.lang, 'nodiscorage');

        results.push(current_result);

        this.setState({
            results,
            message, message2
        });
    }

    handleNext = () => {
        const current = this.state.current;

        if (current < this.state.series.length - 1) {
            this.setState({
                current: current + 1,
                message: null,
                message2: null,
                ready: false
            });

        } else {
            const num = this.props.params.num;
            const hits = this.state.results.reduce((acc, aR) => acc += aR);
            const errors = num - hits;
            const passed = errors > this.props.params.errors ? false : true;
            const uid = this.props.uid;
            const eid = this.props.eid;
            const coins = this.props.params.coins;

            const is_test = this.props.params.is_test ? this.props.params.is_test : false;

            this.props.saveResult(uid, `${uid}-${eid}`, { uid, eid, p: passed, h: hits, e: errors, c: (passed ? coins : 0) });
            if (!is_test) this.setState({ showresults: true });
        }
    }

    handleRepeat = () => {
        const params = this.props.params;
        const couples = params.couples;
        // console.log('BaloonSchall.handleRepeat couples:', couples);

        const series = getSeriesFromCouples(couples, params.levels.length, params.init, params.finish, params.ops, params.config, params.ispan, params.fspan);

        // console.log('BaloonSchall.handleRepeat:', series);

        const levels = params ? params.levels : null;

        this.setState({
            series: series,
            current: 0,
            results: [],
            message: null,
            message2: null,
            showresults: false,
            ready: false,
            levels: levels
        });
    }

    handleComplete = (timereq) => {
        this.handleRate(timereq);
    }


    render() {
        const props = this.props;
        const message = this.state.message;
        const message2 = this.state.message2;
        const current = this.state.current;

        const level = this.state.levels[current];
        const series = this.state.series;
        let currentNumber = series[current];

        const value = Math.floor(eval(currentNumber));

        // console.log('BaloonSchall.render currentNumber:', currentNumber, value, series);

        currentNumber = new String(currentNumber).replace('*', ' x ').replace('/', ' ÷ ').replace('+', ' + ').replace('-', ' - ');

        const rate = this.state.results[this.state.current];
        const messagestyle = message ? (!rate ? ' error' : ' valid') : '';
        const showresults = this.state.showresults;
        const percent = parseInt((this.state.current) / series.length * 100);
        const is_test = this.props.params.is_test ? this.props.params.is_test : false;

        // console.log('BaloonSchall.render:', this.state, percent, level);
        const params = { ...props.params, num: series.length, show_time: true };

        if (!showresults) {
            return (
                <Row className="exercise">
                    {/* <ChangeOrientation to={'portrait'}  /> */}
                    {!is_test ? <Col span={22} className='progress-bar'>
                        <Progress percent={percent} showInfo={false} />
                    </Col> : null}

                    {!message ? <Col span={24} className="exercise-body baloons-body">
                        {/* <ExerciseBlocker show={messagestyle} /> */}
                        <section className="baloonschall">
                            <article className="baloons">
                                <Baloons
                                    key={current}
                                    items={level.num - 1}
                                    rows={props.params.rows}
                                    value={value}
                                    vel={level.vel}
                                    init={props.params.init}
                                    finish={props.params.finish}
                                    onComplete={this.handleComplete}
                                />
                            </article>
                            <article className="number" key={currentNumber}>
                                <motion.div
                                    animate={{ scale: 2 }}
                                    transition={{ duration: 1 }}
                                    className="the-number"
                                >{currentNumber}</motion.div>
                            </article>
                        </section>
                    </Col> : <Col span={24}>
                        <Row className={'botones balloons' + messagestyle}>
                            <Col span={24} className={'message'}>{message}</Col>

                            {message ? <Col span={24}><Button key="submit" type="primary" onClick={this.handleNext} style={{ 'width': '90%' }}>{t(props.lang, 'next')}</Button></Col> : null}
                            {message2 ? <Col span={24} className={'message'}>{message2}</Col> : null}
                        </Row>
                    </Col>}
                </Row >
            );
        } else {
            return (
                <Result params={params} results={this.state.results} handleRepeat={this.handleRepeat} handleCancel={props.handleCancel} lang={props.lang} />
            );
        }
    };
}

export default BaloonSchall;


