import React from 'react'

import { Row, Col, Button, Progress } from 'antd';
import Result from '../base/result';
import { genExerciseComponent } from '../../utils/componentwrapper';

import './index.less';
import { t } from '../../languages';

class Tests extends React.Component {
  state = {
    tid: 0,
    exercises: [],
    current: 0,
    results: [],
    times: [],
    message: null,
    timeout: false,
    showresults: false,
    coins: 0
  }

  UNSAFE_componentWillMount() {
    const params = this.props.params;
    const exercises = params;
    // console.log('Tests.componentWillMount:', exercises);
    const coins = exercises.reduce((acc, aE) => aE.params.coins + acc, 0);
    // console.log('Tests.componentWillMount coins:', coins);

    this.setState({ exercises, coins });
  }

  handleNext = () => {
    const current = this.state.current;
    if (current < this.state.series.length - 1) {
      this.setState({
        current: current + 1,
        numberinbox: 0,
        message: null,
        moved: 0,
        ready: false,
        timeout: false
      });
    } else {
      const num = this.props.params.num;
      const hits = this.state.results.reduce((acc, aR) => acc += aR);
      const errors = num - hits;
      const passed = errors > this.props.params.errors ? false : true;
      const uid = this.props.uid;
      const coins = this.state.coins;

      this.props.saveResult(uid, `${uid}`, { uid, p: passed, h: hits, e: errors, c: (passed ? coins : 0)  });
      this.setState({ showresults: true });
    }
  }

  handleRepeat = () => {

    this.setState({
      tid: 0,
      current: 0,
      results: [],
      times: [],
      message: null,
      timeout: false,
      showresults: false
    });
  }

  handleResult = (uid, ex, result) => {
    const results = this.state.results;
    results.push(result.p ? 1 : 0);

    const current = this.state.current;
    const num = this.state.exercises.length;

    if (current >= num - 1) {
      const hits = results.reduce(((acc, aR) => acc += aR), 0);
      const errors = num - hits;
      const passed = errors > 0 ? false : true;
      const uid = this.props.uid;
      const coins = this.state.coins;

      this.props.saveResult(uid, uid, { uid, p: passed, h: hits, e: errors, c: (passed ? coins : 0) });
    }

    // console.log('tests.handleResult', this.props.uid, results);

    this.setState({
      results,
      current: current + 1,
      showresults: current >= num - 1 ? true : false
    });
  }

  render() {
    const props = this.props;
    const uid = props.uid;
    const current = this.state.current;
    const message = this.state.message;
    const ready = this.state.ready;
    const currentExer = this.state.exercises[current];
    const exerParams = currentExer ? { ...currentExer.params, is_test: true } : null;

    const rate = this.state.results[this.state.current];
    const messagestyle = message ? (!rate ? ' error' : ' valid') : '';
    const showresults = this.state.showresults;
    const num = this.state.exercises.length;
    const percent = parseInt((this.state.current) / num * 100);

    // console.log('Tests.render:', props, this.state, percent, currentExer);

    if (!showresults) {
      return (
        <Row className="exercise">
          <Col span={22} className='progress-bar'>
            <Progress percent={percent} showInfo={false} />
          </Col>


          <Col span={24} className="test-body" key={current}>
            {genExerciseComponent(currentExer.impl, uid, current, exerParams, props.handleCancel, this.handleResult, props.lang)}
          </Col>
          {message && ready ? <Col span={24}>
            <Row className={'botones' + messagestyle}>
              <Col span={12}><Button key="submit" type="primary" onClick={this.handleNext} style={{ 'width': '90%' }}>{t(props.lang, 'next')}</Button></Col>
            </Row>
          </Col> : null}
        </Row >
      );
    } else {
      const params = { ...props.params, num: this.state.exercises.length, errors: 0 }

      return (
        <Result
          params={params}
          results={this.state.results}
          handleRepeat={this.handleRepeat}
          handleCancel={props.handleCancel}
          is_test={true}
          lang={props.lang}
        />
      );
    }
  };
}

export default Tests;


