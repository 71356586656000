const plan1 = [
    /**level 1: base, suma resta sencillas **/
    {
        level: 1,
        label: 'paper',
        open: true,
        units: [
            {
                uid: 1,
                label: 'numbers1to10',//'El ábaco japonés',
                icon: 'levels/1_cifra.png',
                exercises: [
                    {
                        label: 'knowtheabacus',
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'kEYW61DsrYQ', coins: 10 }
                    },
                    {
                        label: 'numbersinabacus',//'Los números',
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'u070dt7DX1Q', coins: 10 }
                    },
                    {
                        label: 'quantitygame',//Practica con el tablero,
                        impl: 'quantitygame',
                        icon: 'cantidad',
                        params: { init: 1, finish: 9, rows: 1, coins: 10, num: 9, errors: 2, subtype: 'objects', cansubstract: true }
                    },
                    {
                        label: 'realtionsgame',//Practica con el tablero,
                        impl: 'quantitygame',
                        icon: 'match',
                        params: { init: 1, finish: 9, rows: 1, coins: 10, num: 9, errors: 2, subtype: 'coins', cansubstract: true }
                    },
                    {
                        label: 'idunits',//'Identifica Unidades',
                        impl: 'whatnumberis',
                        icon: 'identifica',
                        params: { init: 0, finish: 9, rows: 1, coins: 10, num: 10, errors: '2' }
                    },
                    {
                        label: 'repunits',//'Representa Unidades',
                        impl: 'representinabacus',
                        icon: 'practica',
                        params: { init: 1, finish: 9, rows: 1, coins: 10, num: 10, errors: 2 }
                    },
                    {
                        label: 'visualizeabacus',
                        impl: 'mentalabacusfixation',
                        icon: 'visualiza',
                        params: { couples: 'random', init: 1, finish: 9, valueinabacus: 0, rows: 1, coins: 10, num: 5, errors: 2, back: false }
                    },
                ]
            },

            {
                uid: 2,
                label: 'easysumsandsubs',//'Representación de números',
                icon: 'levels/sumas_y_restas.png',
                exercises: [
                    {
                        label: 'sumsubsboard',//Sumas y restas con el tablero,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'Nl3nT3xia68', coins: 10 }
                    },
                    {
                        label: 'practiceboard',//Practica con el tablero,
                        impl: 'cartoonlayout',
                        icon: 'representa',
                        params: { couples: ['1+1', '1+2', '1+3', '1+5', '1+6', '1+7', '1+8', '2+1', '2+2', '2+5', '2+6', '2+7', '3+1', '3+5', '3+6', '4+5', '5+1', '5+2', '5+3', '5+4', '6+1', '6+2', '6+3', '7+1', '7+2', '8+1', '2-1', '3-2', '3-1', '4-1', '4-2', '4-3', '6-1', '6-5', '7-1', '7-2', '7-5', '7-6', '8-1', '8-2', '8-3', '8-5', '8-6', '8-7', '9-1', '9-2', '9-3', '9-4', '9-5', '9-6', '9-7', '9-8'], rows: 1, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'sumsubsabacus',//Sumas y restas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'G_CMa4gePLI', coins: 10 }
                    },
                    {
                        label: 'practiceabacus',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: ['1+1', '1+2', '1+3', '1+5', '1+6', '1+7', '1+8', '2+1', '2+2', '2+5', '2+6', '2+7', '3+1', '3+5', '3+6', '4+5', '5+1', '5+2', '5+3', '5+4', '6+1', '6+2', '6+3', '7+1', '7+2', '8+1', '2-1', '3-2', '3-1', '4-1', '4-2', '4-3', '6-1', '6-5', '7-1', '7-2', '7-5', '7-6', '8-1', '8-2', '8-3', '8-5', '8-6', '8-7', '9-1', '9-2', '9-3', '9-4', '9-5', '9-6', '9-7', '9-8'], rows: 1, coins: 10, num: 10, errors: '2' }
                    },
                    {
                        label: 'visualizebeads',
                        impl: 'coupleschall',
                        icon: 'reto',
                        params: { coins: 10, num: 1, errors: 0, levels: [{ items: 2, beads: 3, time: 2 }, { items: 3, beads: 3, time: 2 }, { items: 4, beads: 3, time: 2 }] }
                    },
                ]
            },

            {
                uid: 3,
                label: 'strengthenlearnt',//Sumar y restar con el 5,
                icon: 'levels/juega.png',
                exercises: [
                    {
                        label: 'matchchall',//El reto de las parejas,
                        impl: 'memoset',
                        icon: 'heart',
                        params: { couples: 'random', init: 0, finish: 9, rows: 1, valueinabacus: 0, coins: 10, num: 4, errors: 0 }
                    },
                    {
                        label: 'baloonschall',//El reto de los globos,
                        impl: 'baloonschall',
                        icon: 'globo',
                        params: { couples: ['2+2', '2+5', '2+6', '2+7', '3+5', '3+6', '4+5', '5+2', '5+3', '5+4', '6+2', '6+3', '7+2', '4-2', '4-3', '6-5', '7-2', '7-5', '7-6', '8-2', '8-3', '8-5', '8-6', '8-7', '9-2', '9-3', '9-4', '9-5', '9-6', '9-7', '9-8'], init: 1, finish: 9, rows: 1, valueinabacus: 0, coins: 10, num: 0, errors: 0, levels: [{ num: 2, vel: 20 }, { num: 4, vel: 20 }, { num: 8, vel: 20 }, { num: 6, vel: 10 }, { num: 8, vel: 10 }] }
                    },
                    {
                        label: 'mentalsumchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: ['2+7', '3+6', '4+5', '5+4', '6+1', '6+2', '6+3', '7+1', '7+2', '8+1', '7+2', '1+8', '1+1+2', '1+3+5', '1+5+2', '1+6+1', '2+2+5', '2+6+1', '3+1+5'], valueinabacus: 0, rows: 1, coins: 10, num: 5, errors: 0 }
                    },
                    {
                        label: 'mentalsubchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: ['9-2', '9-4', '9-5', '9-7', '9-8', '8-1', '8-2', '8-3', '8-5', '8-7', '8-6', '7-2', '7-5', '7-6', '6-1', '6-5', '4-1', '4-2', '4-3', '3-1', '3-2', '9-6-1', '9-1-7'], valueinabacus: 0, rows: 1, coins: 10, num: 5, errors: 0, cansubstract: true }
                    },
                ]
            },
        ],
        test:
        {
            tid: 1,
            label: 'upgradelevel',//Acceder al nivel,
            icon: 'levels/sube_nivel.png',
            level: 1,
            exercises: [
                {
                    label: 'quantitygame',//Practica con el tablero,
                    impl: 'quantitygame',
                    params: { init: 1, finish: 9, rows: 1, coins: 10, num: 2, errors: 0, subops: 'objects' }
                },
                {
                    label: 'realtionsgame',//Practica con el tablero,
                    impl: 'quantitygame',
                    params: { init: 1, finish: 9, rows: 1, coins: 10, num: 2, errors: 0, subops: 'coins' }
                },
                {
                    label: 'baloonschall',//El reto de los globos,
                    impl: 'baloonschall',
                    params: { couples: ['2+2', '2+5', '2+6', '2+7', '3+5', '3+6', '4+5', '5+2', '5+3', '5+4', '6+2', '6+3', '7+2', '4-2', '4-3', '6-5', '7-2', '7-5', '7-6', '8-2', '8-3', '8-5', '8-6', '8-7', '9-2', '9-3', '9-4', '9-5', '9-6', '9-7', '9-8'], init: 1, finish: 9, rows: 1, valueinabacus: 0, coins: 10, num: 0, errors: 0, 'maxfails': 0, levels: [{ num: 3, vel: 20 }, { num: 3, vel: 20 }] }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['2+5-6', '4+5-3', '1+6-7', '4+5-8', '3+6-5', '1+8-9', '7-6+3', '8-7+3', '4-3+6', '9-3-6', '2+7-4', '8-5+6', '7+1-5', '3-1+6', '9-5-2', '4+5-8', '3+5-7', '7+1-3', '8-2-5', '6+2-3', '6+2-3', '5+1-6', '9-3-5', '7-2-5', '9-7+5'], rows: 1, coins: 10, num: 4, errors: 0 }
                },
                {
                    label: 'mental',
                    impl: 'mentalsumsubstraction',
                    params: { couples: ['2+5-6', '4+5-3', '1+6-7', '4+5-8', '3+6-5', '1+8-9', '7-6+3', '8-7+3', '4-3+6', '9-3-6', '2+7-4', '8-5+6', '7+1-5', '3-1+6', '9-5-2', '4+5-8', '3+5-7', '7+1-3', '8-2-5', '6+2-3', '6+2-3', '5+1-6', '9-3-5', '7-2-5', '9-7+5'], valueinabacus: 0, rows: 1, coins: 10, num: 1, vel: 'medium', errors: 0 }
                }
            ]
        }
    },

    /**level 2: suma y resta 5 **/
    {
        level: 2,
        label: 'wood',
        open: true,
        units: [
            {
                uid: 4,
                label: 'sumswith5',//Sumas y restas sencillas,
                level: 2,
                icon: 'levels/sumas_5.png',
                exercises: [
                    {
                        label: 'sumboard',//Sumas con el tablero,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: '4jsnWli3mbI', coins: 10 }
                    },
                    {
                        label: 'practiceboard',//Practica con el tablero,
                        impl: 'cartoonlayout',
                        icon: 'representa',
                        params: { couples: ['1+4', '2+3', '2+4', '3+2', '3+3', '4+1', '4+2', '4+3', '4+4', '4+5'], rows: 1, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'sumabacus',//Sumas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'khGV_lEcYeY', coins: 10 }
                    },
                    {
                        label: 'practiceabacus',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: ['1+4', '2+3', '2+4', '3+2', '3+3', '4+1', '4+2', '4+3', '4+4', '4+5'], rows: 1, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                ]
            },

            {
                uid: 5,
                label: 'subswith5',//Sumas y restas sencillas,
                level: 2,
                icon: 'levels/restas_5.png',
                exercises: [
                    {
                        label: 'subsboard',//Restas con el tablero,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'ktdKDJ93F6Y', coins: 10 }
                    },
                    {
                        label: 'practiceboard',//Practica con el tablero,
                        impl: 'cartoonlayout',
                        icon: 'representa',
                        params: { couples: ['5-1', '5-2', '5-3', '5-4', '6-3', '6-4', '7-3', '7-4', '8-4', '9-4'], rows: 1, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'subsabacus',//Restas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: '81g2Jeo80Zg', coins: 10 }
                    },
                    {
                        label: 'practiceabacus',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: ['5-1', '5-2', '5-3', '5-4', '6-3', '6-4', '7-3', '7-4', '8-4', '9-4'], rows: 1, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                ]
            },

            {
                uid: 6,
                label: 'strengthenlearnt',//Sumar y restar con el 5,
                level: 2,
                icon: 'levels/juega.png',
                exercises: [
                    {
                        label: 'sumserieschall',
                        impl: 'sumsubstractionseries',
                        icon: 'sumas',
                        params: { couples: 'random', ops: '+', init: 1, finish: 4, valueinabacus: 0, 'max': 9, rows: 1, coins: 10, num: 4, errors: 0, show_time: true, cansubstract: true }
                    },
                    {
                        label: 'subsserieschall',
                        impl: 'sumsubstractionseries',
                        icon: 'restas',
                        params: { couples: 'random', ops: '-', init: 1, finish: 4, valueinabacus: 0, 'max': 9, rows: 1, coins: 10, num: 4, errors: 0, show_time: true, cansubstract: true }
                    },
                    {
                        label: 'mentalsumchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: ['1+4', '2+3', '2+4', '3+2', '3+3', '3+4', '4+1'], valueinabacus: 0, rows: 1, coins: 10, num: 5, errors: 0, cansubstract: true }
                    },
                    {
                        label: 'mentalsubchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: ['5-1', '5-2', '6-2', '5-3', '6-3', '7-3', '5-4', '6-4', '7-4', '8-4'], valueinabacus: 0, rows: 1, coins: 10, num: 5, errors: 0, cansubstract: true }
                    },
                ]
            },
        ],
        test:
        {
            tid: 2,
            label: 'upgradelevel',//Acceder al nivel,
            level: 2,

            exercises: [
                {
                    label: 'practiceboard',//Practica con el tablero,
                    impl: 'cartoonlayout',
                    params: { couples: ['1+4', '2+4', '3+4', '4+4', '2+3', '3+3', '4+3', '3+2', '4+2', '4+1'], rows: 1, coins: 10, num: 2, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['1+4', '2+4', '3+4', '4+4'], rows: 1, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['2+3', '3+3', '4+3'], rows: 1, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['3+2', '4+2'], rows: 1, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['4+1'], rows: 1, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceboard',//Practica con el tablero,
                    impl: 'cartoonlayout',
                    params: { couples: ['8-4', '7-4', '6-4', '5-4', '7-3', '6-3', '5-3', '6-2', '5-2', '5-1'], rows: 1, coins: 10, num: 2, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['8-4', '7-4', '6-4', '5-4'], rows: 1, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['7-3', '6-3', '5-3'], rows: 1, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['6-2', '5-2'], rows: 1, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['5-1'], rows: 1, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'mentalsumchall',
                    impl: 'mentalsumsubstraction',
                    params: { couples: ['1+4+1', '2+3+2', '1+1+4', '2+1+2', '3+3+3', '3+4+2', '4+1+1'], valueinabacus: 0, rows: 1, coins: 10, num: 5, errors: 0, cansubstract: true }
                },
                {
                    label: 'mentalsubchall',
                    impl: 'mentalsumsubstraction',
                    params: { couples: ['5-1-2', '5-2-1', '6-2-3', '5-3-1', '6-3-1', '7-3-2', '5-4-1', '6-4-1', '7-4-1', '8-4-2'], valueinabacus: 0, rows: 1, coins: 10, num: 5, errors: 0, cansubstract: true }
                }
            ]
        }
    },

    /**level 3: decenas y suma resta 10 **/
    {
        level: 3,
        label: 'iron',
        open: true,
        units: [
            {
                uid: 7,
                label: 'numberstill99',//Sumar y restar con el 10,
                level: 3,
                icon: 'levels/numeros_2_cifras.png',
                exercises: [
                    {
                        label: 'tens',//'Los números',
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'sJehdB05lyM', coins: 10 }
                    },
                    {
                        label: 'idtens',//'Identifica Unidades',
                        impl: 'whatnumberis',
                        icon: 'identifica',
                        params: { init: 10, finish: 99, rows: 2, coins: 10, num: 10, errors: '2' }
                    },
                    {
                        label: 'reptens',//'Representa Unidades',
                        impl: 'representinabacus',
                        icon: 'practica',
                        params: { init: 10, finish: 99, rows: 2, coins: 10, num: 10, errors: '2' }
                    },
                    {
                        label: 'visualizeabacus',
                        impl: 'mentalabacusfixation',
                        icon: 'visualiza',
                        params: { couples: 'random', init: 10, finish: 99, valueinabacus: 0, rows: 2, coins: 10, num: 5, errors: 2, back: false }
                    }
                ]
            },

            {
                uid: 8,
                label: 'sumswith10',
                level: 3,
                icon: 'levels/sumas_10.png',
                exercises: [
                    {
                        label: 'sumboard',//Sumas con el tablero,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'Ib8lyN0kNI4', coins: 10 }
                    },
                    {
                        label: 'practiceboardI',//Practica con el tablero,
                        impl: 'cartoonlayout',
                        icon: 'representa',
                        params: { couples: ['9+3', '9+4', '9+5', '9+6', '9+7', '9+8', '9+9', '8+2', '8+3', '8+4', '8+5', '8+7', '8+8', '8+9', '7+9', '7+3', '7+4', '7+5', '6+9', '6+5', '6+4', '5+5', '4+9', '4+8', '4+7', '4+6', '3+9', '3+8', '3+7', '2+8', '2+9', '1+9'], rows: 2, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'practiceboardII',//Practica con el tablero,
                        impl: 'cartoonlayout',
                        icon: 'representa',
                        params: { couples: ['8+6', '7+7', '7+6', '6+6', '6+7', '6+8', '5+6', '5+7', '5+8', '5+9'], rows: 2, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'sumabacus',//Sumas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: '2zW7fAVMVQ0', coins: 10 }
                    },
                    {
                        label: 'practiceabacusI',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: ['9+3', '9+4', '9+5', '9+6', '9+7', '9+8', '9+9', '8+2', '8+3', '8+4', '8+5', '8+7', '8+8', '8+9', '7+9', '7+3', '7+4', '7+5', '6+9', '6+5', '6+4', '5+5', '4+9', '4+8', '4+7', '4+6', '3+9', '3+8', '3+7', '2+8', '2+9', '1+9'], rows: 2, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'practiceabacusII',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: ['8+6', '7+7', '7+6', '6+6', '6+7', '6+8', '5+6', '5+7', '5+8', '5+9'], rows: 2, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'visualizebeads',
                        impl: 'coupleschall',
                        icon: 'relaciona',
                        params: { coins: 10, num: 1, errors: 0, levels: [{ items: 3, beads: 3, time: 1.5 }, { items: 4, beads: 3, time: 1.5 }, { items: 2, beads: 3, time: 2 }] }
                    }
                ]
            },


            {
                uid: 9,
                label: 'subswith10',
                level: 3,
                icon: 'levels/restas_10.png',
                exercises: [
                    {
                        label: 'subsboard',//Restas con el tablero,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: '8hfxTJ2Y9fg', coins: 10 }
                    },
                    {
                        label: 'practiceboardI',//Practica con el tablero,
                        impl: 'cartoonlayout',
                        icon: 'representa',
                        params: { couples: ['10-1', '10-2', '10-3', '10-4', '10-5', '10-6', '10-7', '10-8', '10-9', '11-2', '11-3', '11-4', '11-5', '11-7', '11-8', '11-9', '12-3', '12-4', '12-5', '13-4', '13-5', '13-9', '14-5', '15-6', '15-7', '15-8', '15-9', '16-7', '16-8', '16-9', '17-8', '17-9', '18-9'], rows: 2, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'practiceboardII',//Practica con el tablero,
                        impl: 'cartoonlayout',
                        icon: 'representa',
                        params: { couples: ['11-6', '12-6', '12-7', '13-6', '13-7', '13-8', '14-6', '14-7', '14-8', '14-9'], rows: 2, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'subsabacus',//Restas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'zVi2c0d3aSQ', coins: 10 }
                    },
                    {
                        label: 'practiceabacusI',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: ['10-1', '10-2', '10-3', '10-4', '10-5', '10-6', '10-7', '10-8', '10-9', '11-2', '11-3', '11-4', '11-5', '11-7', '11-8', '11-9', '12-3', '12-4', '12-5', '13-4', '13-5', '13-9', '14-5', '15-6', '15-7', '15-8', '15-9', '16-7', '16-8', '16-9', '17-8', '17-9', '18-9'], rows: 2, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'practiceabacusII',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: ['11-6', '12-6', '12-7', '13-6', '13-7', '13-8', '14-6', '14-7', '14-8', '14-9'], rows: 2, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'visualizeabacus',
                        impl: 'mentalabacusfixation',
                        icon: 'visualiza',
                        params: { couples: 'random', init: 10, finish: 99, valueinabacus: 0, rows: 2, coins: 10, num: 5, errors: 2, back: false }
                    }
                ]
            },

            {
                uid: 10,
                label: 'strengthenlearnt',//Sumar y restar con el 5,
                level: 3,
                icon: 'levels/juega.png',
                exercises: [
                    {
                        label: 'matchchall',//El reto de las parejas,
                        impl: 'memoset',
                        icon: 'relaciona',
                        params: { couples: 'random', init: 10, finish: 99, rows: 2, valueinabacus: 0, coins: 10, num: 4, errors: 0 }
                    },
                    {
                        label: 'baloonschall',//El reto de los globos,
                        impl: 'baloonschall',
                        icon: 'globo',
                        params: { couples: ['8+6', '7+7', '7+6', '6+6', '6+7', '6+8', '5+6', '5+7', '5+8', '5+9', '9+3', '9+4', '9+5', '9+6', '9+7', '9+8', '9+9', '8+2', '8+3', '8+4', '8+5', '8+7', '8+8', '8+9', '7+9', '7+3', '7+4', '7+5', '6+9', '6+5', '6+4', '5+5', '4+9', '4+8', '4+7', '4+6', '3+9', '3+8', '3+7', '2+8', '2+9', '1+9', '8+6', '7+7', '7+6', '6+6', '6+7', '6+8', '5+6', '5+7', '5+8', '5+9', '11-6', '12-6', '12-7', '13-6', '13-7', '13-8', '14-6', '14-7', '14-8', '14-9', '10-1', '10-2', '10-3', '10-4', '10-5', '10-6', '10-7', '10-8', '10-9', '11-2', '11-3', '11-4', '11-5', '11-7', '11-8', '11-9', '12-3', '12-4', '12-5', '13-4', '13-5', '13-9', '14-5', '15-6', '15-7', '15-8', '15-9', '16-7', '16-8', '16-9', '17-8', '17-9', '18-9', '11-6', '12-6', '12-7', '13-6', '13-7', '13-8', '14-6', '14-7', '14-8', '14-9'], init: 10, finish: 99, rows: 2, valueinabacus: 0, coins: 10, num: 1, errors: 0, levels: [{ num: 4, vel: 15 }, { num: 6, vel: 15 }, { num: 8, vel: 15 }, { num: 10, vel: 10 }, { num: 12, vel: 10 }] }
                    },
                    {
                        label: 'sumserieschallI',
                        impl: 'sumsubstractionseries',
                        icon: 'sumas',
                        params: { couples: 'random', ops: '+', init: 1, finish: 4, valueinabacus: 0, 'max': 99, rows: 2, coins: 10, num: 4, errors: 0, show_time: true, cansubstract: true }
                    },
                    {
                        label: 'sumserieschallII',
                        impl: 'sumsubstractionseries',
                        icon: 'sumas',
                        params: { couples: 'random', ops: '+', init: 5, finish: 7, valueinabacus: 0, 'max': 99, rows: 2, coins: 10, num: 3, errors: 0, show_time: true, cansubstract: true }
                    },
                    {
                        label: 'sumserieschallIII',
                        impl: 'sumsubstractionseries',
                        icon: 'sumas',
                        params: { couples: 'random', ops: '+', init: 8, finish: 9, valueinabacus: 0, 'max': 99, rows: 2, coins: 10, num: 2, errors: 0, show_time: true, cansubstract: true }
                    },
                    {
                        label: 'subsserieschallI',
                        impl: 'sumsubstractionseries',
                        icon: 'restas',
                        params: { couples: 'random', ops: '-', init: 1, finish: 4, valueinabacus: 0, 'max': 99, rows: 2, coins: 10, num: 4, errors: 0, show_time: true, cansubstract: true }
                    },
                    {
                        label: 'subsserieschallII',
                        impl: 'sumsubstractionseries',
                        icon: 'restas',
                        params: { couples: 'random', ops: '-', init: 5, finish: 7, valueinabacus: 0, 'max': 99, rows: 2, coins: 10, num: 3, errors: 0, show_time: true, cansubstract: true }
                    },
                    {
                        label: 'subsserieschallIII',
                        impl: 'sumsubstractionseries',
                        icon: 'restas',
                        params: { couples: 'random', ops: '-', init: 8, finish: 9, valueinabacus: 0, 'max': 99, rows: 2, coins: 10, num: 2, errors: 0, show_time: true, cansubstract: true }
                    },
                    {
                        label: 'mentalsumchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: 'random', ops: ['+'], init: 30, finish: 60, ispan: 1, fspan: 9, valueinabacus: 0, rows: 2, coins: 10, num: 1, config: [{ digits: 2, num: 5 }], wait: 3, errors: 0, cansubstract: true }
                    },
                    {
                        label: 'mentalsubchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: 'random', ops: ['-'], init: 30, finish: 60, ispan: 1, fspan: 9, valueinabacus: 0, rows: 2, coins: 10, num: 1, config: [{ digits: 2, num: 5 }], wait: 3, errors: 0, cansubstract: true }
                    },
                ]
            },
        ],
        test:
        {
            tid: 3,
            label: 'upgradelevel',//Acceder al nivel,
            level: 3,

            exercises: [
                {
                    label: 'baloonschall',//El reto de los globos,
                    impl: 'baloonschall',
                    params: { couples: ['8+6', '7+7', '7+6', '6+6', '6+7', '6+8', '5+6', '5+7', '5+8', '5+9', '9+3', '9+4', '9+5', '9+6', '9+7', '9+8', '9+9', '8+2', '8+3', '8+4', '8+5', '8+7', '8+8', '8+9', '7+9', '7+3', '7+4', '7+5', '6+9', '6+5', '6+4', '5+5', '4+9', '4+8', '4+7', '4+6', '3+9', '3+8', '3+7', '2+8', '2+9', '1+9', '8+6', '7+7', '7+6', '6+6', '6+7', '6+8', '5+6', '5+7', '5+8', '5+9', '11-6', '12-6', '12-7', '13-6', '13-7', '13-8', '14-6', '14-7', '14-8', '14-9', '10-1', '10-2', '10-3', '10-4', '10-5', '10-6', '10-7', '10-8', '10-9', '11-2', '11-3', '11-4', '11-5', '11-7', '11-8', '11-9', '12-3', '12-4', '12-5', '13-4', '13-5', '13-9', '14-5', '15-6', '15-7', '15-8', '15-9', '16-7', '16-8', '16-9', '17-8', '17-9', '18-9', '11-6', '12-6', '12-7', '13-6', '13-7', '13-8', '14-6', '14-7', '14-8', '14-9'], init: 10, finish: 99, rows: 2, valueinabacus: 0, coins: 10, num: 1, errors: 0, levels: [{ num: 4, vel: 20 }, { num: 4, vel: 10 }] }

                    // params: { couples: 'random', init: 11, finish: 99, rows: 2, valueinabacus: 0, coins: 10, num: 4, errors: 0, 'maxfails': 0, levels: [{ num: 4, vel: 20 }, { num: 4, vel: 10 }] }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['8+6', '7+6', '6+6', '5+6'], rows: 2, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['7+7', '6+7', '5+7'], rows: 2, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['6+8', '5+8', '5+9'], rows: 2, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['9+3', '9+4', '8+3', '8+4', '7+3', '7+4'], rows: 2, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['11-6', '12-6', '13-6', '14-6'], rows: 2, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['12-7', '13-7', '14-7'], rows: 2, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['13-8', '14-8', '14-9'], rows: 2, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['11-2', '11-3', '11-4', '12-3', '12-4', '13-4'], rows: 2, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'mentalsumchall',
                    impl: 'mentalsumsubstraction',
                    icon: 'mental',
                    params: { couples: 'random', ops: ['+'], init: 1, finish: 9, ispan: 1, fspan: 9, valueinabacus: 0, rows: 2, coins: 10, num: 1, config: [{ digits: 1, num: 3 }], wait: 3, errors: 0, cansubstract: true }
                },
                {
                    label: 'mentalsubchall',
                    impl: 'mentalsumsubstraction',
                    icon: 'mental',
                    params: { couples: 'random', ops: ['-'], init: 11, finish: 19, ispan: 1, fspan: 9, valueinabacus: 0, rows: 2, coins: 10, num: 1, config: [{ digits: 1, num: 3 }], wait: 3, errors: 0, cansubstract: true }
                },
            ]
        }
    },

    /**level 4: centenas y suma resta 2c **/
    {
        level: 4,
        label: 'clay',
        open: true,
        units: [
            {
                uid: 11,
                label: 'numberstill999',//Sumar y restar con el 10,
                level: 4,
                icon: 'levels/numeros_3_cifras.png',
                exercises: [
                    {
                        label: 'hundreds',//'Los números',
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'OaQUjesLf5M', coins: 10 }
                    },
                    {
                        label: 'idhundreds',//'Identifica Unidades',
                        impl: 'whatnumberis',
                        icon: 'identifica',
                        params: { init: 100, finish: 999, rows: 3, coins: 10, num: 10, errors: '2' }
                    },
                    {
                        label: 'rephundreds',//'Representa Unidades',
                        impl: 'representinabacus',
                        icon: 'practica',
                        params: { init: 100, finish: 999, rows: 3, coins: 10, num: 10, errors: '2' }
                    },
                    {
                        label: 'visualizeabacus',
                        impl: 'mentalabacusfixation',
                        icon: 'visualiza',
                        params: { couples: 'random', init: 100, finish: 999, valueinabacus: 0, rows: 3, coins: 10, num: 5, errors: 2, back: false }
                    }
                ]
            },

            {
                uid: 12,
                label: 'sumsandsubswith2',
                level: 4,
                icon: 'levels/sumas_y_restas_2_cifras.png',
                exercises: [
                    {
                        label: 'sumabacus',//Sumas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'ADi-O0QfUbc', coins: 10 }
                    },
                    {
                        label: 'practicesums',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: 'random', ops: ['+'], init: 29, finish: 59, ispan: 19, fspan: 39, rows: 2, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'subsabacus',//Restas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: '7mNFip4-6KY', coins: 10 }
                    },
                    {
                        label: 'practicesubs',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: 'random', ops: ['-'], init: 59, finish: 89, ispan: 19, fspan: 39, rows: 2, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                ]
            },

            {
                uid: 13,
                label: 'strengthenlearnt',//practicar
                level: 4,
                icon: 'levels/juega.png',
                exercises: [
                    {
                        label: 'matchchall',//El reto de las parejas,
                        impl: 'memoset',
                        icon: 'relaciona',
                        params: { couples: 'random', init: 10, finish: 99, rows: 2, valueinabacus: 0, coins: 10, num: 4, errors: 0, style: 'small', 'flipbacktime': 2000 }
                    },
                    {
                        label: 'baloonschall',//El reto de los globos,
                        impl: 'baloonschall',
                        icon: 'globo',
                        params: {
                            couples: 'random', ops: ['-', '+'], 'numops': 2, init: 30, finish: 60, ispan: 11, fspan: 29, rows: 2, valueinabacus: 0, coins: 10, num: 1, errors: 0,
                            levels: [{ num: 6, vel: 15 }, { num: 8, vel: 15 }, { num: 10, vel: 15 }, { num: 12, vel: 10 }, { num: 14, vel: 10 }]
                        }
                    },
                    {
                        label: 'pathchall',//El reto del camino,
                        impl: 'pathchall',
                        icon: 'path',
                        params: { couples: 'random', init: 2, finish: 6, op: '+', ispan: 11, fspan: 19, items: 5, num: 2, cols: 6, coins: 10, errors: 0 }
                    },
                    {
                        label: 'mentalsumchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: 'random', ops: ['+'], init: 11, finish: 29, ispan: 11, fspan: 29, valueinabacus: 0, rows: 2, coins: 10, num: 1, config: [{ digits: 2, num: 3 }, { digits: 1, num: 3 }], wait: 4, errors: 0, cansubstract: true }
                    },
                    {
                        label: 'mentalsubchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: 'random', ops: ['-'], init: 71, finish: 99, ispan: 11, fspan: 19, valueinabacus: 0, rows: 2, coins: 10, num: 1, config: [{ digits: 2, num: 3 }, { digits: 1, num: 3 }], wait: 4, errors: 0, cansubstract: true }
                    },
                ]
            },
        ],
        test:
        {
            tid: 4,
            label: 'upgradelevel',//Acceder al nivel,
            level: 4,

            exercises: [
                {
                    label: 'baloonschall',//El reto de los globos,
                    impl: 'baloonschall',
                    params: { couples: 'random', ops: ['-', '+'], 'numops': 2, init: 30, finish: 60, ispan: 11, fspan: 29, rows: 2, valueinabacus: 0, coins: 10, num: 1, errors: 0, levels: [{ num: 6, vel: 20 }, { num: 6, vel: 10 }] }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: 'random', ops: ['+'], init: 29, finish: 59, ispan: 19, fspan: 39, rows: 2, coins: 10, num: 5, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: 'random', ops: ['-'], init: 59, finish: 89, ispan: 19, fspan: 39, rows: 2, coins: 10, num: 5, errors: 0, cansubstract: true }
                },
                {
                    label: 'mentalsumchall',
                    impl: 'mentalsumsubstraction',
                    params: { couples: 'random', ops: ['+'], init: 11, finish: 29, ispan: 11, fspan: 29, valueinabacus: 0, rows: 2, coins: 10, num: 1, config: [{ digits: 2, num: 4 }, { digits: 1, num: 4 }], wait: 4, errors: 0, cansubstract: true }
                },
                {
                    label: 'mentalsubchall',
                    impl: 'mentalsumsubstraction',
                    params: { couples: 'random', ops: ['-'], init: 71, finish: 99, ispan: 11, fspan: 19, valueinabacus: 0, rows: 2, coins: 10, num: 1, config: [{ digits: 2, num: 4 }, { digits: 1, num: 4 }], wait: 4, errors: 0, cansubstract: true }
                }
            ]
        }
    },

    /**level 5: suma resta 3c **/
    {
        level: 5,
        label: 'bronze',
        open: true,
        units: [
            {
                uid: 14,
                label: 'sumsandsubswith3',
                level: 5,
                icon: 'levels/sumas_y_restas_3_cifras.png',
                exercises: [
                    {
                        label: 'sumabacus',//Sumas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'AvFLuBli3Zo', coins: 10 }
                    },
                    {
                        label: 'practicesums',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: 'random', ops: ['+'], init: 299, finish: 599, ispan: 199, fspan: 399, rows: 3, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'subsabacus',//Restas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: '3tfvd4o_G8s', coins: 10 }
                    },
                    {
                        label: 'practicesubs',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: 'random', ops: ['-'], init: 599, finish: 899, ispan: 199, fspan: 399, rows: 3, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                ]
            },

            {
                uid: 15,
                label: 'strengthenlearnt',//Sumar y restar con el 5,
                level: 5,
                icon: 'levels/juega.png',
                exercises: [
                    {
                        label: 'matchchall',//El reto de las parejas,
                        impl: 'memoset',
                        icon: 'heart',
                        params: { couples: 'random', init: 100, finish: 999, rows: 3, valueinabacus: 0, coins: 10, num: 4, errors: 0, style: 'small', 'flipbacktime': 2000 }
                    },
                    {
                        label: 'baloonschall',//El reto de los globos,
                        impl: 'baloonschall',
                        icon: 'globo',
                        params: {
                            couples: 'random', ops: ['-', '+'], 'numops': 2, init: 300, finish: 600, ispan: 111, fspan: 299, rows: 3, valueinabacus: 0, 'upperlimit': 999, coins: 10, num: 1, errors: 0,
                            levels: [{ num: 6, vel: 15 }, { num: 8, vel: 15 }, { num: 10, vel: 15 }, { num: 12, vel: 10 }, { num: 14, vel: 10 }]
                        }
                    },
                    {
                        label: 'pathchall',//El reto del camino,
                        impl: 'pathchall',
                        icon: 'path',
                        params: { couples: 'random', init: 20, finish: 60, op: '+', ispan: 101, fspan: 199, num: 2, items: 7, cols: 4, coins: 10, errors: 0 }
                    },
                    {
                        label: 'mentalsumchall',
                        icon: 'mental',
                        impl: 'mentalsumsubstraction',
                        params: { couples: 'random', ops: ['+'], init: 111, finish: 299, ispan: 111, fspan: 199, valueinabacus: 0, rows: 3, coins: 10, num: 1, config: [{ digits: 3, num: 3 }, { digits: 2, num: 2 }, { digits: 1, num: 2 }], wait: 5, errors: 0, cansubstract: true }
                    },
                    {
                        label: 'mentalsubchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: 'random', ops: ['-'], init: 711, finish: 999, ispan: 111, fspan: 199, valueinabacus: 0, rows: 3, coins: 10, num: 1, config: [{ digits: 3, num: 3 }, { digits: 2, num: 2 }, { digits: 1, num: 2 }], wait: 5, errors: 0, cansubstract: true }
                    },
                ]
            },
        ],
        test:
        {
            tid: 5,
            label: 'upgradelevel',//Acceder al nivel,
            level: 5,

            exercises: [
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: 'random', ops: ['+'], init: 299, finish: 599, ispan: 199, fspan: 399, rows: 3, coins: 10, num: 3, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: 'random', ops: ['-'], init: 599, finish: 899, ispan: 199, fspan: 399, rows: 3, coins: 10, num: 3, errors: 0, cansubstract: true }
                },
                {
                    label: 'baloonschall',//El reto de los globos,
                    impl: 'baloonschall',
                    params: {
                        couples: 'random', ops: ['-', '+'], 'numops': 2, init: 300, finish: 600, ispan: 111, fspan: 299, rows: 3, valueinabacus: 0, 'upperlimit': 999, coins: 10, num: 1, errors: 0,
                        levels: [{ num: 12, vel: 10 }, { num: 14, vel: 10 }]
                    }
                },
                {
                    label: 'mentalsumchall',
                    impl: 'mentalsumsubstraction',
                    params: { couples: 'random', ops: ['+'], init: 111, finish: 299, ispan: 111, fspan: 199, valueinabacus: 0, rows: 3, coins: 10, num: 1, config: [{ digits: 3, num: 3 }, { digits: 2, num: 3 }, { digits: 1, num: 3 }], wait: 5, errors: 0, cansubstract: true }
                },
                {
                    label: 'mentalsubchall',
                    impl: 'mentalsumsubstraction',
                    params: { couples: 'random', ops: ['-'], init: 711, finish: 999, ispan: 111, fspan: 199, valueinabacus: 0, rows: 3, coins: 10, num: 1, config: [{ digits: 3, num: 3 }, { digits: 2, num: 3 }, { digits: 1, num: 3 }], wait: 5, errors: 0, cansubstract: true }
                }
            ]
        },
    }
];


const plan2 = [
    /**level 1: base, suma resta sencillas **/
    {
        level: 1,
        label: 'paper',
        open: true,
        units: [
            {
                uid: 1,
                label: 'numbers1_2_3_figs',//'El ábaco japonés',
                icon: 'levels/numeros_1_2_3_cifras.png',
                exercises: [
                    {
                        label: 'knowtheabacus',
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'kEYW61DsrYQ', coins: 10 }
                    },

                    {
                        label: 'numbersinabacus',//'numbers',
                        impl: 'theory',
                        icon: 'video',
                        params: { video: '_lcc8c90OJY', coins: 10 }
                    },

                    {
                        label: 'idnumbers',//'Identifica',
                        impl: 'whatnumberis',
                        icon: 'identifica',
                        params: { series: [{ init: 0, finish: 9, num: 3 }, { init: 10, finish: 99, num: 3 }, { init: 100, finish: 999, num: 3 }], init: 0, finish: 9, rows: 3, coins: 10, num: 5, errors: '2' }
                    },
                    {
                        label: 'repnumbers',//'Representa',
                        impl: 'representinabacus',
                        icon: 'practica',
                        params: { series: [{ init: 0, finish: 9, num: 3 }, { init: 10, finish: 99, num: 3 }, { init: 100, finish: 999, num: 3 }], init: 1, finish: 9, rows: 3, coins: 10, num: 5, errors: 2 }
                    },

                    {
                        label: 'visualizeabacus',
                        impl: 'mentalabacusfixation',
                        icon: 'visualiza',
                        params: { series: [{ init: 0, finish: 9, num: 2 }, { init: 10, finish: 99, num: 2 }, { init: 100, finish: 999, num: 2 }], couples: 'random', init: 1, finish: 9, valueinabacus: 0, rows: 3, coins: 10, num: 6, errors: 2, back: false }
                    },
                ]
            },

            {
                uid: 2,
                label: 'easysumsandsubs',//'Representación de números',
                icon: 'levels/sumas_y_restas.png',
                exercises: [
                    {
                        label: 'sumsubsboard',//Sumas y restas con el tablero,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'Nl3nT3xia68', coins: 10 }
                    },
                    {
                        label: 'practiceboard',//Practica con el tablero,
                        impl: 'cartoonlayout',
                        icon: 'representa',
                        params: { couples: ['1+1', '1+2', '1+3', '1+5', '1+6', '1+7', '1+8', '2+1', '2+2', '2+5', '2+6', '2+7', '3+1', '3+5', '3+6', '4+5', '5+1', '5+2', '5+3', '5+4', '6+1', '6+2', '6+3', '7+1', '7+2', '8+1', '2-1', '3-2', '3-1', '4-1', '4-2', '4-3', '6-1', '6-5', '7-1', '7-2', '7-5', '7-6', '8-1', '8-2', '8-3', '8-5', '8-6', '8-7', '9-1', '9-2', '9-3', '9-4', '9-5', '9-6', '9-7', '9-8'], rows: 1, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'sumsubsabacus',//Sumas y restas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'G_CMa4gePLI', coins: 10 }
                    },
                    {
                        label: 'practiceabacus',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: ['1+1', '1+2', '1+3', '1+5', '1+6', '1+7', '1+8', '2+1', '2+2', '2+5', '2+6', '2+7', '3+1', '3+5', '3+6', '4+5', '5+1', '5+2', '5+3', '5+4', '6+1', '6+2', '6+3', '7+1', '7+2', '8+1', '2-1', '3-2', '3-1', '4-1', '4-2', '4-3', '6-1', '6-5', '7-1', '7-2', '7-5', '7-6', '8-1', '8-2', '8-3', '8-5', '8-6', '8-7', '9-1', '9-2', '9-3', '9-4', '9-5', '9-6', '9-7', '9-8'], rows: 1, coins: 10, num: 10, errors: '2' }
                    },
                    {
                        label: 'visualizebeads',
                        impl: 'coupleschall',
                        icon: 'reto',
                        params: { coins: 10, num: 1, errors: 0, levels: [{ items: 2, beads: 3, time: 2 }, { items: 3, beads: 3, time: 2 }, { items: 4, beads: 3, time: 2 }] }
                    },
                ]
            },

            {
                uid: 3,
                label: 'strengthenlearnt',//Sumar y restar con el 5,
                icon: 'levels/juega.png',
                exercises: [
                    {
                        label: 'matchchall',//El reto de las parejas,
                        impl: 'memoset',
                        icon: 'heart',
                        params: { series: [{ init: 0, finish: 9, num: 4 }, { init: 10, finish: 99, num: 4 }, { init: 100, finish: 999, num: 4 }], couples: 'random', init: 0, finish: 9, rows: 3, valueinabacus: 0, coins: 10, num: 4, errors: 0 }
                    },
                    {
                        label: 'baloonschall',//El reto de los globos,
                        impl: 'baloonschall',
                        icon: 'globo',
                        params: { couples: ['2+2', '2+5', '2+6', '2+7', '3+5', '3+6', '4+5', '5+2', '5+3', '5+4', '6+2', '6+3', '7+2', '4-2', '4-3', '6-5', '7-2', '7-5', '7-6', '8-2', '8-3', '8-5', '8-6', '8-7', '9-2', '9-3', '9-4', '9-5', '9-6', '9-7', '9-8'], init: 1, finish: 9, rows: 1, valueinabacus: 0, coins: 10, num: 0, errors: 0, levels: [{ num: 2, vel: 20 }, { num: 4, vel: 20 }, { num: 8, vel: 20 }, { num: 6, vel: 10 }, { num: 8, vel: 10 }] }
                    },
                    {
                        label: 'mentalsumchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: ['2+7', '3+6', '4+5', '5+4', '6+1', '6+2', '6+3', '7+1', '7+2', '8+1', '7+2', '1+8', '1+1+2', '1+3+5', '1+5+2', '1+6+1', '2+2+5', '2+6+1', '3+1+5'], valueinabacus: 0, rows: 1, coins: 10, num: 5, errors: 0 }
                    },
                    {
                        label: 'mentalsubchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: ['9-2', '9-4', '9-5', '9-7', '9-8', '8-1', '8-2', '8-3', '8-5', '8-7', '8-6', '7-2', '7-5', '7-6', '6-1', '6-5', '4-1', '4-2', '4-3', '3-1', '3-2', '9-6-1', '9-1-7'], valueinabacus: 0, rows: 1, coins: 10, num: 5, errors: 0, cansubstract: true }
                    },
                ]
            },
        ],
        test:
        {
            tid: 1,
            label: 'upgradelevel',//Acceder al nivel,
            icon: 'levels/sube_nivel.png',
            level: 1,
            exercises: [
                {
                    label: 'idnumbers',//'Identifica',
                    impl: 'whatnumberis',
                    icon: 'identifica',
                    params: { init: 1, finish: 999, rows: 3, coins: 10, num: 3, errors: '2' }
                },
                {
                    label: 'repnumbers',//'Representa',
                    impl: 'representinabacus',
                    icon: 'practica',
                    params: { init: 1, finish: 999, rows: 3, coins: 10, num: 3, errors: 2 }
                },
                {
                    label: 'baloonschall',//El reto de los globos,
                    impl: 'baloonschall',
                    params: { couples: ['2+2', '2+5', '2+6', '2+7', '3+5', '3+6', '4+5', '5+2', '5+3', '5+4', '6+2', '6+3', '7+2', '4-2', '4-3', '6-5', '7-2', '7-5', '7-6', '8-2', '8-3', '8-5', '8-6', '8-7', '9-2', '9-3', '9-4', '9-5', '9-6', '9-7', '9-8'], init: 1, finish: 9, rows: 1, valueinabacus: 0, coins: 10, num: 0, errors: 0, 'maxfails': 0, levels: [{ num: 3, vel: 20 }, { num: 3, vel: 20 }] }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['2+5-6', '4+5-3', '1+6-7', '4+5-8', '3+6-5', '1+8-9', '7-6+3', '8-7+3', '4-3+6', '9-3-6', '2+7-4', '8-5+6', '7+1-5', '3-1+6', '9-5-2', '4+5-8', '3+5-7', '7+1-3', '8-2-5', '6+2-3', '6+2-3', '5+1-6', '9-3-5', '7-2-5', '9-7+5'], rows: 1, coins: 10, num: 4, errors: 0 }
                },
                {
                    label: 'mental',
                    impl: 'mentalsumsubstraction',
                    params: { couples: ['2+5-6', '4+5-3', '1+6-7', '4+5-8', '3+6-5', '1+8-9', '7-6+3', '8-7+3', '4-3+6', '9-3-6', '2+7-4', '8-5+6', '7+1-5', '3-1+6', '9-5-2', '4+5-8', '3+5-7', '7+1-3', '8-2-5', '6+2-3', '6+2-3', '5+1-6', '9-3-5', '7-2-5', '9-7+5'], valueinabacus: 0, rows: 1, coins: 10, num: 1, vel: 'medium', errors: 0 }
                }
            ]
        }
    },

    /**level 2: suma y resta 5 **/
    {
        level: 2,
        label: 'wood',
        open: true,
        units: [
            {
                uid: 4,
                label: 'sumswith5',//Sumas y restas sencillas,
                level: 2,
                icon: 'levels/sumas_5.png',
                exercises: [
                    {
                        label: 'sumboard',//Sumas con el tablero,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: '4jsnWli3mbI', coins: 10 }
                    },
                    {
                        label: 'practiceboard',//Practica con el tablero,
                        impl: 'cartoonlayout',
                        icon: 'representa',
                        params: { couples: ['1+4', '2+3', '2+4', '3+2', '3+3', '4+1', '4+2', '4+3', '4+4', '4+5'], rows: 1, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'sumabacus',//Sumas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'khGV_lEcYeY', coins: 10 }
                    },
                    {
                        label: 'practiceabacus',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: ['1+4', '2+3', '2+4', '3+2', '3+3', '4+1', '4+2', '4+3', '4+4', '4+5'], rows: 1, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                ]
            },

            {
                uid: 5,
                label: 'subswith5',//Sumas y restas sencillas,
                level: 2,
                icon: 'levels/restas_5.png',
                exercises: [
                    {
                        label: 'subsboard',//Restas con el tablero,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'ktdKDJ93F6Y', coins: 10 }
                    },
                    {
                        label: 'practiceboard',//Practica con el tablero,
                        impl: 'cartoonlayout',
                        icon: 'representa',
                        params: { couples: ['5-1', '5-2', '5-3', '5-4', '6-3', '6-4', '7-3', '7-4', '8-4', '9-4'], rows: 1, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'subsabacus',//Restas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: '81g2Jeo80Zg', coins: 10 }
                    },
                    {
                        label: 'practiceabacus',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: ['5-1', '5-2', '5-3', '5-4', '6-3', '6-4', '7-3', '7-4', '8-4', '9-4'], rows: 1, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                ]
            },

            {
                uid: 6,
                label: 'strengthenlearnt',//Sumar y restar con el 5,
                level: 2,
                icon: 'levels/juega.png',
                exercises: [
                    {
                        label: 'sumserieschall',
                        impl: 'sumsubstractionseries',
                        icon: 'sumas',
                        params: { couples: 'random', ops: '+', init: 1, finish: 4, valueinabacus: 0, 'max': 9, rows: 1, coins: 10, num: 4, errors: 0, show_time: true, cansubstract: true }
                    },
                    {
                        label: 'subsserieschall',
                        impl: 'sumsubstractionseries',
                        icon: 'restas',
                        params: { couples: 'random', ops: '-', init: 1, finish: 4, valueinabacus: 0, 'max': 9, rows: 1, coins: 10, num: 4, errors: 0, show_time: true, cansubstract: true }
                    },
                    {
                        label: 'mentalsumchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: ['1+4', '2+3', '2+4', '3+2', '3+3', '3+4', '4+1'], valueinabacus: 0, rows: 1, coins: 10, num: 5, errors: 0, cansubstract: true }
                    },
                    {
                        label: 'mentalsubchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: ['5-1', '5-2', '6-2', '5-3', '6-3', '7-3', '5-4', '6-4', '7-4', '8-4'], valueinabacus: 0, rows: 1, coins: 10, num: 5, errors: 0, cansubstract: true }
                    },
                ]
            },
        ],
        test:
        {
            tid: 2,
            label: 'upgradelevel',//Acceder al nivel,
            level: 2,

            exercises: [
                {
                    label: 'practiceboard',//Practica con el tablero,
                    impl: 'cartoonlayout',
                    params: { couples: ['1+4', '2+4', '3+4', '4+4', '2+3', '3+3', '4+3', '3+2', '4+2', '4+1'], rows: 1, coins: 10, num: 2, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['1+4', '2+4', '3+4', '4+4'], rows: 1, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['2+3', '3+3', '4+3'], rows: 1, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['3+2', '4+2'], rows: 1, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['4+1'], rows: 1, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceboard',//Practica con el tablero,
                    impl: 'cartoonlayout',
                    params: { couples: ['8-4', '7-4', '6-4', '5-4', '7-3', '6-3', '5-3', '6-2', '5-2', '5-1'], rows: 1, coins: 10, num: 2, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['8-4', '7-4', '6-4', '5-4'], rows: 1, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['7-3', '6-3', '5-3'], rows: 1, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['6-2', '5-2'], rows: 1, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['5-1'], rows: 1, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'mentalsumchall',
                    impl: 'mentalsumsubstraction',
                    params: { couples: ['1+4+1', '2+3+2', '1+1+4', '2+1+2', '3+3+3', '3+4+2', '4+1+1'], valueinabacus: 0, rows: 1, coins: 10, num: 5, errors: 0, cansubstract: true }
                },
                {
                    label: 'mentalsubchall',
                    impl: 'mentalsumsubstraction',
                    params: { couples: ['5-1-2', '5-2-1', '6-2-3', '5-3-1', '6-3-1', '7-3-2', '5-4-1', '6-4-1', '7-4-1', '8-4-2'], valueinabacus: 0, rows: 1, coins: 10, num: 5, errors: 0, cansubstract: true }
                }
            ]
        }
    },

    /**level 3: decenas y suma resta 10 **/
    {
        level: 3,
        label: 'iron',
        open: true,
        units: [
            {
                uid: 8,
                label: 'sumswith10',
                level: 3,
                icon: 'levels/sumas_10.png',
                exercises: [
                    {
                        label: 'sumboard',//Sumas con el tablero,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'Ib8lyN0kNI4', coins: 10 }
                    },
                    {
                        label: 'practiceboardI',//Practica con el tablero,
                        impl: 'cartoonlayout',
                        icon: 'representa',
                        params: { couples: ['9+3', '9+4', '9+5', '9+6', '9+7', '9+8', '9+9', '8+2', '8+3', '8+4', '8+5', '8+7', '8+8', '8+9', '7+9', '7+3', '7+4', '7+5', '6+9', '6+5', '6+4', '5+5', '4+9', '4+8', '4+7', '4+6', '3+9', '3+8', '3+7', '2+8', '2+9', '1+9'], rows: 2, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'practiceboardII',//Practica con el tablero,
                        impl: 'cartoonlayout',
                        icon: 'representa',
                        params: { couples: ['8+6', '7+7', '7+6', '6+6', '6+7', '6+8', '5+6', '5+7', '5+8', '5+9'], rows: 2, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'sumabacus',//Sumas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: '2zW7fAVMVQ0', coins: 10 }
                    },
                    {
                        label: 'practiceabacusI',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: ['9+3', '9+4', '9+5', '9+6', '9+7', '9+8', '9+9', '8+2', '8+3', '8+4', '8+5', '8+7', '8+8', '8+9', '7+9', '7+3', '7+4', '7+5', '6+9', '6+5', '6+4', '5+5', '4+9', '4+8', '4+7', '4+6', '3+9', '3+8', '3+7', '2+8', '2+9', '1+9'], rows: 2, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'practiceabacusII',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: ['8+6', '7+7', '7+6', '6+6', '6+7', '6+8', '5+6', '5+7', '5+8', '5+9'], rows: 2, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'visualizebeads',
                        impl: 'coupleschall',
                        icon: 'relaciona',
                        params: { coins: 10, num: 1, errors: 0, levels: [{ items: 3, beads: 3, time: 1.5 }, { items: 4, beads: 3, time: 1.5 }, { items: 2, beads: 3, time: 2 }] }
                    }
                ]
            },


            {
                uid: 9,
                label: 'subswith10',
                level: 3,
                icon: 'levels/restas_10.png',
                exercises: [
                    {
                        label: 'subsboard',//Restas con el tablero,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: '8hfxTJ2Y9fg', coins: 10 }
                    },
                    {
                        label: 'practiceboardI',//Practica con el tablero,
                        impl: 'cartoonlayout',
                        icon: 'representa',
                        params: { couples: ['10-1', '10-2', '10-3', '10-4', '10-5', '10-6', '10-7', '10-8', '10-9', '11-2', '11-3', '11-4', '11-5', '11-7', '11-8', '11-9', '12-3', '12-4', '12-5', '13-4', '13-5', '13-9', '14-5', '15-6', '15-7', '15-8', '15-9', '16-7', '16-8', '16-9', '17-8', '17-9', '18-9'], rows: 2, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'practiceboardII',//Practica con el tablero,
                        impl: 'cartoonlayout',
                        icon: 'representa',
                        params: { couples: ['11-6', '12-6', '12-7', '13-6', '13-7', '13-8', '14-6', '14-7', '14-8', '14-9'], rows: 2, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'subsabacus',//Restas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'zVi2c0d3aSQ', coins: 10 }
                    },
                    {
                        label: 'practiceabacusI',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: ['10-1', '10-2', '10-3', '10-4', '10-5', '10-6', '10-7', '10-8', '10-9', '11-2', '11-3', '11-4', '11-5', '11-7', '11-8', '11-9', '12-3', '12-4', '12-5', '13-4', '13-5', '13-9', '14-5', '15-6', '15-7', '15-8', '15-9', '16-7', '16-8', '16-9', '17-8', '17-9', '18-9'], rows: 2, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'practiceabacusII',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: ['11-6', '12-6', '12-7', '13-6', '13-7', '13-8', '14-6', '14-7', '14-8', '14-9'], rows: 2, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'visualizeabacus',
                        impl: 'mentalabacusfixation',
                        icon: 'visualiza',
                        params: { couples: 'random', init: 10, finish: 99, valueinabacus: 0, rows: 2, coins: 10, num: 5, errors: 2, back: false }
                    }
                ]
            },

            {
                uid: 10,
                label: 'strengthenlearnt',//Sumar y restar con el 5,
                level: 3,
                icon: 'levels/juega.png',
                exercises: [
                    {
                        label: 'matchchall',//El reto de las parejas,
                        impl: 'memoset',
                        icon: 'relaciona',
                        params: { couples: 'random', init: 10, finish: 99, rows: 2, valueinabacus: 0, coins: 10, num: 4, errors: 0 }
                    },
                    {
                        label: 'baloonschall',//El reto de los globos,
                        impl: 'baloonschall',
                        icon: 'globo',
                        params: { couples: ['8+6', '7+7', '7+6', '6+6', '6+7', '6+8', '5+6', '5+7', '5+8', '5+9', '9+3', '9+4', '9+5', '9+6', '9+7', '9+8', '9+9', '8+2', '8+3', '8+4', '8+5', '8+7', '8+8', '8+9', '7+9', '7+3', '7+4', '7+5', '6+9', '6+5', '6+4', '5+5', '4+9', '4+8', '4+7', '4+6', '3+9', '3+8', '3+7', '2+8', '2+9', '1+9', '8+6', '7+7', '7+6', '6+6', '6+7', '6+8', '5+6', '5+7', '5+8', '5+9', '11-6', '12-6', '12-7', '13-6', '13-7', '13-8', '14-6', '14-7', '14-8', '14-9', '10-1', '10-2', '10-3', '10-4', '10-5', '10-6', '10-7', '10-8', '10-9', '11-2', '11-3', '11-4', '11-5', '11-7', '11-8', '11-9', '12-3', '12-4', '12-5', '13-4', '13-5', '13-9', '14-5', '15-6', '15-7', '15-8', '15-9', '16-7', '16-8', '16-9', '17-8', '17-9', '18-9', '11-6', '12-6', '12-7', '13-6', '13-7', '13-8', '14-6', '14-7', '14-8', '14-9'], init: 10, finish: 99, rows: 2, valueinabacus: 0, coins: 10, num: 1, errors: 0, levels: [{ num: 4, vel: 15 }, { num: 6, vel: 15 }, { num: 8, vel: 15 }, { num: 10, vel: 10 }, { num: 12, vel: 10 }] }
                    },
                    {
                        label: 'sumserieschallI',
                        impl: 'sumsubstractionseries',
                        icon: 'sumas',
                        params: { couples: 'random', ops: '+', init: 1, finish: 4, valueinabacus: 0, 'max': 99, rows: 2, coins: 10, num: 4, errors: 0, show_time: true, cansubstract: true }
                    },
                    {
                        label: 'sumserieschallII',
                        impl: 'sumsubstractionseries',
                        icon: 'sumas',
                        params: { couples: 'random', ops: '+', init: 5, finish: 7, valueinabacus: 0, 'max': 99, rows: 2, coins: 10, num: 3, errors: 0, show_time: true, cansubstract: true }
                    },
                    {
                        label: 'sumserieschallIII',
                        impl: 'sumsubstractionseries',
                        icon: 'sumas',
                        params: { couples: 'random', ops: '+', init: 8, finish: 9, valueinabacus: 0, 'max': 99, rows: 2, coins: 10, num: 2, errors: 0, show_time: true, cansubstract: true }
                    },
                    {
                        label: 'subsserieschallI',
                        impl: 'sumsubstractionseries',
                        icon: 'restas',
                        params: { couples: 'random', ops: '-', init: 1, finish: 4, valueinabacus: 0, 'max': 99, rows: 2, coins: 10, num: 4, errors: 0, show_time: true, cansubstract: true }
                    },
                    {
                        label: 'subsserieschallII',
                        impl: 'sumsubstractionseries',
                        icon: 'restas',
                        params: { couples: 'random', ops: '-', init: 5, finish: 7, valueinabacus: 0, 'max': 99, rows: 2, coins: 10, num: 3, errors: 0, show_time: true, cansubstract: true }
                    },
                    {
                        label: 'subsserieschallIII',
                        impl: 'sumsubstractionseries',
                        icon: 'restas',
                        params: { couples: 'random', ops: '-', init: 8, finish: 9, valueinabacus: 0, 'max': 99, rows: 2, coins: 10, num: 2, errors: 0, show_time: true, cansubstract: true }
                    },
                    {
                        label: 'mentalsumchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: 'random', ops: ['+'], init: 1, finish: 9, ispan: 1, fspan: 9, valueinabacus: 0, rows: 2, coins: 10, num: 1, config: [{ digits: 1, num: 3 }], wait: 3, errors: 0, cansubstract: true }
                    },
                    {
                        label: 'mentalsubchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: 'random', ops: ['-'], init: 11, finish: 19, ispan: 1, fspan: 9, valueinabacus: 0, rows: 2, coins: 10, num: 1, config: [{ digits: 1, num: 3 }], wait: 3, errors: 0, cansubstract: true }
                    },
                    
                ]
            },
        ],
        test:
        {
            tid: 3,
            label: 'upgradelevel',//Acceder al nivel,
            level: 3,

            exercises: [
                {
                    label: 'baloonschall',//El reto de los globos,
                    impl: 'baloonschall',
                    params: { couples: ['8+6', '7+7', '7+6', '6+6', '6+7', '6+8', '5+6', '5+7', '5+8', '5+9', '9+3', '9+4', '9+5', '9+6', '9+7', '9+8', '9+9', '8+2', '8+3', '8+4', '8+5', '8+7', '8+8', '8+9', '7+9', '7+3', '7+4', '7+5', '6+9', '6+5', '6+4', '5+5', '4+9', '4+8', '4+7', '4+6', '3+9', '3+8', '3+7', '2+8', '2+9', '1+9', '8+6', '7+7', '7+6', '6+6', '6+7', '6+8', '5+6', '5+7', '5+8', '5+9', '11-6', '12-6', '12-7', '13-6', '13-7', '13-8', '14-6', '14-7', '14-8', '14-9', '10-1', '10-2', '10-3', '10-4', '10-5', '10-6', '10-7', '10-8', '10-9', '11-2', '11-3', '11-4', '11-5', '11-7', '11-8', '11-9', '12-3', '12-4', '12-5', '13-4', '13-5', '13-9', '14-5', '15-6', '15-7', '15-8', '15-9', '16-7', '16-8', '16-9', '17-8', '17-9', '18-9', '11-6', '12-6', '12-7', '13-6', '13-7', '13-8', '14-6', '14-7', '14-8', '14-9'], init: 10, finish: 99, rows: 2, valueinabacus: 0, coins: 10, num: 1, errors: 0, levels: [{ num: 4, vel: 20 }, { num: 4, vel: 10 }] }

                    // params: { couples: 'random', init: 11, finish: 99, rows: 2, valueinabacus: 0, coins: 10, num: 4, errors: 0, 'maxfails': 0, levels: [{ num: 4, vel: 20 }, { num: 4, vel: 10 }] }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['8+6', '7+6', '6+6', '5+6'], rows: 2, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['7+7', '6+7', '5+7'], rows: 2, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['6+8', '5+8', '5+9'], rows: 2, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['9+3', '9+4', '8+3', '8+4', '7+3', '7+4'], rows: 2, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['11-6', '12-6', '13-6', '14-6'], rows: 2, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['12-7', '13-7', '14-7'], rows: 2, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['13-8', '14-8', '14-9'], rows: 2, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['11-2', '11-3', '11-4', '12-3', '12-4', '13-4'], rows: 2, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'mentalsumchall',
                    impl: 'mentalsumsubstraction',
                    params: { couples: 'random', ops: ['+'], init: 10, finish: 30, ispan: 1, fspan: 9, valueinabacus: 0, rows: 2, coins: 10, num: 1, config: [{ digits: 2, num: 6 }], wait: 3, errors: 0, cansubstract: true }
                },
                {
                    label: 'mentalsubchall',
                    impl: 'mentalsumsubstraction',
                    params: { couples: 'random', ops: ['-'], init: 70, finish: 90, ispan: 1, fspan: 9, valueinabacus: 0, rows: 2, coins: 10, num: 1, config: [{ digits: 2, num: 6 }], wait: 3, errors: 0, cansubstract: true }
                }
            ]
        }
    },

    /**level 4: centenas y suma resta 2c **/
    {
        level: 4,
        label: 'clay',
        open: true,
        units: [

            {
                uid: 12,
                label: 'sumsandsubswith2',
                level: 4,
                icon: 'levels/sumas_y_restas_2_cifras.png',
                exercises: [
                    {
                        label: 'sumabacus',//Sumas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'ADi-O0QfUbc', coins: 10 }
                    },
                    {
                        label: 'practicesums',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: 'random', ops: ['+'], init: 29, finish: 59, ispan: 19, fspan: 39, rows: 2, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'subsabacus',//Restas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: '7mNFip4-6KY', coins: 10 }
                    },
                    {
                        label: 'practicesubs',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: 'random', ops: ['-'], init: 59, finish: 89, ispan: 19, fspan: 39, rows: 2, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                ]
            },

            {
                uid: 13,
                label: 'strengthenlearnt',//practicar
                level: 4,
                icon: 'levels/juega.png',
                exercises: [
                    {
                        label: 'matchchall',//El reto de las parejas,
                        impl: 'memoset',
                        icon: 'relaciona',
                        params: { couples: 'random', init: 10, finish: 99, rows: 2, valueinabacus: 0, coins: 10, num: 4, errors: 0, style: 'small', 'flipbacktime': 2000 }
                    },
                    {
                        label: 'baloonschall',//El reto de los globos,
                        impl: 'baloonschall',
                        icon: 'globo',
                        params: {
                            couples: 'random', ops: ['-', '+'], 'numops': 2, init: 30, finish: 60, ispan: 11, fspan: 29, rows: 2, valueinabacus: 0, coins: 10, num: 1, errors: 0,
                            levels: [{ num: 6, vel: 15 }, { num: 8, vel: 15 }, { num: 10, vel: 15 }, { num: 12, vel: 10 }, { num: 14, vel: 10 }]
                        }
                    },
                    {
                        label: 'pathchall',//El reto del camino,
                        impl: 'pathchall',
                        icon: 'path',
                        params: { couples: 'random', init: 2, finish: 6, op: '+', ispan: 11, fspan: 19, items: 5, num: 2, cols: 6, coins: 10, errors: 0 }
                    },
                    {
                        label: 'mentalsumchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: 'random', ops: ['+'], init: 11, finish: 29, ispan: 11, fspan: 29, valueinabacus: 0, rows: 2, coins: 10, num: 1, config: [{ digits: 2, num: 3 }, { digits: 1, num: 3 }], wait: 4, errors: 0, cansubstract: true }
                    },
                    {
                        label: 'mentalsubchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: 'random', ops: ['-'], init: 71, finish: 99, ispan: 11, fspan: 19, valueinabacus: 0, rows: 2, coins: 10, num: 1, config: [{ digits: 2, num: 3 }, { digits: 1, num: 3 }], wait: 4, errors: 0, cansubstract: true }
                    },
                ]
            },
        ],
        test:
        {
            tid: 4,
            label: 'upgradelevel',//Acceder al nivel,
            level: 4,

            exercises: [
                {
                    label: 'baloonschall',//El reto de los globos,
                    impl: 'baloonschall',
                    params: { couples: 'random', ops: ['-', '+'], 'numops': 2, init: 30, finish: 60, ispan: 11, fspan: 29, rows: 2, valueinabacus: 0, coins: 10, num: 1, errors: 0, levels: [{ num: 6, vel: 20 }, { num: 6, vel: 10 }] }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: 'random', ops: ['+'], init: 29, finish: 59, ispan: 19, fspan: 39, rows: 2, coins: 10, num: 5, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: 'random', ops: ['-'], init: 59, finish: 89, ispan: 19, fspan: 39, rows: 2, coins: 10, num: 5, errors: 0, cansubstract: true }
                },
                {
                    label: 'mentalsumchall',
                    impl: 'mentalsumsubstraction',
                    params: { couples: 'random', ops: ['+'], init: 11, finish: 29, ispan: 11, fspan: 29, valueinabacus: 0, rows: 2, coins: 10, num: 1, config: [{ digits: 2, num: 4 }, { digits: 1, num: 4 }], wait: 4, errors: 0, cansubstract: true }
                },
                {
                    label: 'mentalsubchall',
                    impl: 'mentalsumsubstraction',
                    params: { couples: 'random', ops: ['-'], init: 71, finish: 99, ispan: 11, fspan: 19, valueinabacus: 0, rows: 2, coins: 10, num: 1, config: [{ digits: 2, num: 4 }, { digits: 1, num: 4 }], wait: 4, errors: 0, cansubstract: true }
                }
            ]
        }
    },

    /**level 5: suma resta 3c **/
    {
        level: 5,
        label: 'bronze',
        open: true,
        units: [
            {
                uid: 14,
                label: 'sumsandsubswith3',
                level: 5,
                icon: 'levels/sumas_y_restas_3_cifras.png',
                exercises: [
                    {
                        label: 'sumabacus',//Sumas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'AvFLuBli3Zo', coins: 10 }
                    },
                    {
                        label: 'practicesums',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: 'random', ops: ['+'], init: 299, finish: 599, ispan: 199, fspan: 399, rows: 3, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'subsabacus',//Restas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: '3tfvd4o_G8s', coins: 10 }
                    },
                    {
                        label: 'practicesubs',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: 'random', ops: ['-'], init: 599, finish: 899, ispan: 199, fspan: 399, rows: 3, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                ]
            },

            {
                uid: 15,
                label: 'strengthenlearnt',//Sumar y restar con el 5,
                level: 5,
                icon: 'levels/juega.png',
                exercises: [
                    {
                        label: 'matchchall',//El reto de las parejas,
                        impl: 'memoset',
                        icon: 'heart',
                        params: { couples: 'random', init: 100, finish: 999, rows: 3, valueinabacus: 0, coins: 10, num: 4, errors: 0, style: 'small', 'flipbacktime': 2000 }
                    },
                    {
                        label: 'baloonschall',//El reto de los globos,
                        impl: 'baloonschall',
                        icon: 'globo',
                        params: {
                            couples: 'random', ops: ['-', '+'], 'numops': 2, init: 300, finish: 600, ispan: 111, fspan: 299, rows: 3, valueinabacus: 0, 'upperlimit': 999, coins: 10, num: 1, errors: 0,
                            levels: [{ num: 6, vel: 15 }, { num: 8, vel: 15 }, { num: 10, vel: 15 }, { num: 12, vel: 10 }, { num: 14, vel: 10 }]
                        }
                    },
                    {
                        label: 'pathchall',//El reto del camino,
                        impl: 'pathchall',
                        icon: 'path',
                        params: { couples: 'random', init: 20, finish: 60, op: '+', ispan: 101, fspan: 199, num: 2, items: 7, cols: 4, coins: 10, errors: 0 }
                    },
                    {
                        label: 'mentalsumchall',
                        icon: 'mental',
                        impl: 'mentalsumsubstraction',
                        params: { couples: 'random', ops: ['+'], init: 111, finish: 299, ispan: 111, fspan: 199, valueinabacus: 0, rows: 3, coins: 10, num: 1, config: [{ digits: 3, num: 3 }, { digits: 2, num: 2 }, { digits: 1, num: 2 }], wait: 5, errors: 0, cansubstract: true }
                    },
                    {
                        label: 'mentalsubchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: 'random', ops: ['-'], init: 711, finish: 999, ispan: 111, fspan: 199, valueinabacus: 0, rows: 3, coins: 10, num: 1, config: [{ digits: 3, num: 3 }, { digits: 2, num: 2 }, { digits: 1, num: 2 }], wait: 5, errors: 0, cansubstract: true }
                    },
                ]
            },
        ],
        test:
        {
            tid: 5,
            label: 'upgradelevel',//Acceder al nivel,
            level: 5,

            exercises: [
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: 'random', ops: ['+'], init: 299, finish: 599, ispan: 199, fspan: 399, rows: 3, coins: 10, num: 3, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: 'random', ops: ['-'], init: 599, finish: 899, ispan: 199, fspan: 399, rows: 3, coins: 10, num: 3, errors: 0, cansubstract: true }
                },
                {
                    label: 'baloonschall',//El reto de los globos,
                    impl: 'baloonschall',
                    params: {
                        couples: 'random', ops: ['-', '+'], 'numops': 2, init: 300, finish: 600, ispan: 111, fspan: 299, rows: 3, valueinabacus: 0, 'upperlimit': 999, coins: 10, num: 1, errors: 0,
                        levels: [{ num: 12, vel: 10 }, { num: 14, vel: 10 }]
                    }
                },
                {
                    label: 'mentalsumchall',
                    impl: 'mentalsumsubstraction',
                    params: { couples: 'random', ops: ['+'], init: 111, finish: 299, ispan: 111, fspan: 199, valueinabacus: 0, rows: 3, coins: 10, num: 1, config: [{ digits: 3, num: 3 }, { digits: 2, num: 3 }, { digits: 1, num: 3 }], wait: 5, errors: 0, cansubstract: true }
                },
                {
                    label: 'mentalsubchall',
                    impl: 'mentalsumsubstraction',
                    params: { couples: 'random', ops: ['-'], init: 711, finish: 999, ispan: 111, fspan: 199, valueinabacus: 0, rows: 3, coins: 10, num: 1, config: [{ digits: 3, num: 3 }, { digits: 2, num: 3 }, { digits: 1, num: 3 }], wait: 5, errors: 0, cansubstract: true }
                }
            ]
        },
    },

    /**level 6: multiplicacionnes 1 c **/
    {
        level: 6,
        label: 'ivory',
        open: true,
        units: [
            {
                uid: 17,
                label: 'multi1',
                level: 7,
                icon: 'levels/multiplicaciones_1_cifra.png',
                exercises: [
                    {
                        label: 'multi1x2',
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'C5jid2zS6Sc', coins: 10 }
                    },
                    {
                        label: 'practiceabacus1x2',//Practica con el ábaco,
                        impl: 'multiplydivide',
                        icon: 'practica',
                        params: { couples: 'random', ops: ['*'], init: 11, finish: 99, ispan: 2, fspan: 9, rows: 8, coins: 10, num: 5, errors: 1, cansubstract: true, markers: true }
                    },
                    {
                        label: 'multi1x3',//Sumas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'eFc2zcz7BQM', coins: 10 }
                    },
                    {
                        label: 'practiceabacus1x3',//Practica con el ábaco,
                        impl: 'multiplydivide',
                        icon: 'practica',
                        params: { couples: 'random', ops: ['*'], init: 111, finish: 999, ispan: 2, fspan: 9, rows: 10, coins: 10, num: 5, errors: 1, cansubstract: true, markers: true }
                    },
                ]
            },

            {
                uid: 18,
                label: 'strengthenlearnt',//Sumar y restar con el 5,
                level: 7,
                icon: 'levels/juega.png',
                exercises: [
                    {
                        label: 'challmultI',//Practica con el ábaco,
                        impl: 'multiplydivide',
                        icon: 'reto',
                        params: { couples: 'random', ops: ['*'], only_answer: true, init: 11, finish: 99, ispan: 2, fspan: 9, rows: 3, coins: 10, num: 5, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                    },
                    {
                        label: 'challmultII',//Practica con el ábaco,
                        impl: 'multiplydivide',
                        icon: 'reto',
                        params: { couples: 'random', ops: ['*'], only_answer: true, init: 111, finish: 999, ispan: 2, fspan: 9, rows: 4, coins: 10, num: 5, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                    },
                    {
                        label: 'baloonschallI',//El reto de los globos,
                        impl: 'baloonschall',
                        icon: 'globo',
                        params: {
                            couples: 'random', ops: ['*'], 'numops': 2, init: 11, finish: 99, ispan: 2, fspan: 9, rows: 3, valueinabacus: 0, 'upperlimit': 9 * 99, coins: 10, num: 1, errors: 0,
                            levels: [{ num: 6, vel: 15 }, { num: 8, vel: 15 }, { num: 10, vel: 15 }, { num: 12, vel: 10 }, { num: 14, vel: 10 }]
                        }

                    },
                    {
                        label: 'baloonschallII',//El reto de los globos,
                        impl: 'baloonschall',
                        icon: 'globo',
                        params: {
                            couples: 'random', ops: ['*'], 'numops': 2, init: 111, finish: 999, ispan: 2, fspan: 9, rows: 4, valueinabacus: 0, 'upperlimit': 999 * 9, coins: 10, num: 1, errors: 0,
                            levels: [{ num: 6, vel: 15 }, { num: 8, vel: 15 }, { num: 10, vel: 15 }, { num: 12, vel: 10 }, { num: 14, vel: 10 }]
                        }
                    },
                ]
            },
        ],
        test:
        {
            tid: 6,
            label: 'upgradelevel',//Acceder al nivel,
            level: 7,

            exercises: [
                {
                    label: 'practiceabacus I',//Practica con el ábaco,
                    impl: 'multiplydivide',
                    params: { couples: 'random', ops: ['*'], init: 11, finish: 99, ispan: 2, fspan: 9, rows: 8, coins: 10, num: 2, errors: 1, cansubstract: true, markers: true }
                },
                {
                    label: 'practiceabacus II',//Practica con el ábaco,
                    impl: 'multiplydivide',
                    params: { couples: 'random', ops: ['*'], init: 111, finish: 999, ispan: 2, fspan: 9, rows: 10, coins: 10, num: 2, errors: 1, cansubstract: true, markers: true }
                },
                {
                    label: 'challmult I',//Practica con el ábaco,
                    impl: 'multiplydivide',
                    params: { couples: 'random', ops: ['*'], only_answer: true, init: 11, finish: 99, ispan: 2, fspan: 9, rows: 3, coins: 8, num: 2, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                },
                {
                    label: 'challmult II',//Practica con el ábaco,
                    impl: 'multiplydivide',
                    params: { couples: 'random', ops: ['*'], only_answer: true, init: 111, finish: 999, ispan: 2, fspan: 9, rows: 4, coins: 10, num: 2, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                },
                {
                    label: 'baloonschall I',//El reto de los globos,
                    impl: 'baloonschall',
                    params: {
                        couples: 'random', ops: ['*'], 'numops': 2, init: 11, finish: 99, ispan: 2, fspan: 9, rows: 3, valueinabacus: 0, 'upperlimit': 9 * 99, coins: 10, num: 1, errors: 0,
                        levels: [{ num: 14, vel: 10 }]
                    }
                },
                {
                    label: 'baloonschall II',//El reto de los globos,
                    impl: 'baloonschall',
                    params: {
                        couples: 'random', ops: ['*'], 'numops': 2, init: 111, finish: 999, ispan: 2, fspan: 9, rows: 4, valueinabacus: 0, 'upperlimit': 999 * 9, coins: 10, num: 1, errors: 0,
                        levels: [{ num: 14, vel: 10 }]
                    }
                }
            ]
        }
    },

    /**level 7: divisiones 1 c **/
    {
        level: 7,
        label: 'glass',
        open: true,
        units: [
            {
                uid: 19,
                label: 'divi1',
                level: 8,
                icon: 'levels/divisiones_1_cifra.png',
                exercises: [
                    {
                        label: 'divide2:1',
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'qDGRuQjY8Vk', coins: 10 }
                    },
                    {
                        label: 'practiceabacus2:1',//Practica con el ábaco,
                        impl: 'multiplydivide',
                        icon: 'practica',
                        params: { couples: 'random', ops: ['/'], init: 11, finish: 99, ispan: 2, fspan: 9, rows: 8, coins: 10, num: 5, errors: 1, cansubstract: true, markers: true }
                    },
                    {
                        label: 'divide3:1',
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'C47INBFxDKY', coins: 10 }
                    },
                    {
                        label: 'practiceabacus3:1',//Practica con el ábaco,
                        impl: 'multiplydivide',
                        icon: 'practica',
                        params: { couples: 'random', ops: ['/'], init: 111, finish: 999, ispan: 2, fspan: 9, rows: 9, coins: 10, num: 5, errors: 1, cansubstract: true, markers: true }
                    },
                ]
            },

            {
                uid: 20,
                label: 'strengthenlearnt',//Sumar y restar con el 5,
                level: 8,
                icon: 'levels/juega.png',
                exercises: [
                    {
                        label: 'challdivI',
                        impl: 'multiplydivide',
                        icon: 'reto',
                        params: { couples: 'random', ops: ['/'], only_answer: true, init: 11, finish: 99, ispan: 2, fspan: 9, rows: 2, coins: 10, num: 5, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                    },
                    {
                        label: 'challdivII',
                        impl: 'multiplydivide',
                        icon: 'reto',
                        params: { couples: 'random', ops: ['/'], only_answer: true, init: 111, finish: 999, ispan: 2, fspan: 9, rows: 3, coins: 10, num: 5, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                    },
                    {
                        label: 'baloonschallI',//El reto de los globos,
                        impl: 'baloonschall',
                        icon: 'globo',
                        params: {
                            couples: 'random', ops: ['/'], 'numops': 2, init: 11, finish: 99, ispan: 2, fspan: 9, rows: 2, valueinabacus: 0, 'upperlimit': 99 / 9, coins: 10, num: 1, errors: 0,
                            levels: [{ num: 6, vel: 15 }, { num: 8, vel: 15 }, { num: 10, vel: 15 }, { num: 12, vel: 10 }, { num: 14, vel: 10 }]
                        }
                    },
                    {
                        label: 'baloonschallII',//El reto de los globos,
                        impl: 'baloonschall',
                        icon: 'globo',
                        params: {
                            couples: 'random', ops: ['/'], 'numops': 2, init: 111, finish: 999, ispan: 2, fspan: 9, rows: 3, valueinabacus: 0, 'upperlimit': 999 / 9, coins: 10, num: 1, errors: 0,
                            levels: [{ num: 6, vel: 15 }, { num: 8, vel: 15 }, { num: 10, vel: 15 }, { num: 12, vel: 10 }, { num: 14, vel: 10 }]
                        }
                    },
                ]
            },
        ],
        test: {
            tid: 7,
            label: 'upgradelevel',//Acceder al nivel,
            level: 8,

            exercises: [
                {
                    label: 'practiceabacus I',//Practica con el ábaco,
                    impl: 'multiplydivide',
                    params: { couples: 'random', ops: ['/'], init: 11, finish: 99, ispan: 2, fspan: 9, rows: 8, coins: 10, num: 2, errors: 1, cansubstract: true, markers: true }
                },
                {
                    label: 'practiceabacus II',//Practica con el ábaco,
                    impl: 'multiplydivide',
                    params: { couples: 'random', ops: ['/'], init: 111, finish: 999, ispan: 2, fspan: 9, rows: 10, coins: 10, num: 2, errors: 1, cansubstract: true, markers: true }
                },
                {
                    label: 'challdiv I',
                    impl: 'multiplydivide',
                    params: { couples: 'random', ops: ['/'], only_answer: true, init: 11, finish: 99, ispan: 2, fspan: 9, rows: 2, coins: 10, num: 2, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                },
                {
                    label: 'challdiv II',
                    impl: 'multiplydivide',
                    params: { couples: 'random', ops: ['/'], only_answer: true, init: 111, finish: 999, ispan: 2, fspan: 9, rows: 3, coins: 10, num: 2, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                },
                {
                    label: 'baloonschall I',//El reto de los globos,
                    impl: 'baloonschall',
                    params: {
                        couples: 'random', ops: ['/'], 'numops': 2, init: 11, finish: 99, ispan: 2, fspan: 9, rows: 2, valueinabacus: 0, 'upperlimit': 99 / 9, coins: 10, num: 1, errors: 0,
                        levels: [{ num: 14, vel: 10 }]
                    }
                },
                {
                    label: 'baloonschall II',//El reto de los globos,
                    impl: 'baloonschall',
                    params: {
                        couples: 'random', ops: ['/'], 'numops': 2, init: 111, finish: 999, ispan: 2, fspan: 9, rows: 3, valueinabacus: 0, 'upperlimit': 999 / 9, coins: 10, num: 1, errors: 0,
                        levels: [{ num: 14, vel: 10 }]
                    }
                }
            ]
        }
    },

    /**level 8: sumas 4c **/
    {
        level: 8,
        label: 'steel',
        open: true,
        units: [
            {
                uid: 21,
                label: 'sumsandsubswith4',
                level: 6,
                icon: 'levels/sumas_y_restas_4_cifras.png',
                exercises: [
                    {
                        label: 'idthous',//'Identifica Unidades',
                        impl: 'whatnumberis',
                        icon: 'identifica',
                        params: { init: 1000, finish: 9999, rows: 4, coins: 10, num: 10, errors: '2' }
                    },
                    {
                        label: 'repthous',//'Representa Unidades',
                        impl: 'representinabacus',
                        icon: 'practica',
                        params: { init: 1000, finish: 9999, rows: 4, coins: 10, num: 10, errors: '2' }
                    },
                    {
                        label: 'sumabacus',//Sumas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'Vbni35G6Xao', coins: 10 }
                    },
                    {
                        label: 'practicesums',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'sumas',
                        params: { couples: 'random', ops: ['+'], init: 2999, finish: 5999, ispan: 1999, fspan: 3999, rows: 4, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'subsabacus',//Restas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'sQ7v39_ULIQ', coins: 10 }
                    },
                    {
                        label: 'practicesubs',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'restas',
                        params: { couples: 'random', ops: ['-'], init: 5999, finish: 8999, ispan: 1999, fspan: 3999, rows: 4, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'visualizeabacus',
                        impl: 'mentalabacusfixation',
                        icon: 'visualiza',
                        params: { couples: 'random', init: 1000, finish: 9999, valueinabacus: 0, rows: 4, coins: 10, num: 5, errors: 2, back: false }
                    }
                ]
            },

            {
                uid: 24,
                label: 'strengthenlearnt',
                level: 6,
                icon: 'levels/juega.png',
                exercises: [
                    {
                        label: 'baloonschall',//El reto de los globos,
                        impl: 'baloonschall',
                        icon: 'globo',
                        params: {
                            couples: 'random', ops: ['-', '+'], 'numops': 2, init: 3000, finish: 6000, ispan: 111, fspan: 2999, rows: 4, valueinabacus: 0, 'upperlimit': 9999, coins: 10, num: 1, errors: 0,
                            levels: [{ num: 6, vel: 15 }, { num: 8, vel: 15 }, { num: 10, vel: 15 }, { num: 12, vel: 10 }, { num: 14, vel: 10 }]
                        }
                    },
                    {
                        label: 'pathchall',//El reto del camino,
                        impl: 'pathchall',
                        icon: 'path',
                        params: { couples: 'random', init: 200, finish: 600, op: '+', ispan: 1001, fspan: 1999, num: 2, items: 7, cols: 4, coins: 10, errors: 0 }
                    },
                    {
                        label: 'mentalsumchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: 'random', ops: ['+'], init: 1111, finish: 2999, ispan: 1111, fspan: 1999, valueinabacus: 0, rows: 4, coins: 10, num: 1, config: [{ digits: 4, num: 3 }, { digits: 3, num: 2 }, { digits: 2, num: 2 }], wait: 6, errors: 0, cansubstract: true }
                    },
                    {
                        label: 'mentalsubchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: 'random', ops: ['-'], init: 7111, finish: 9999, ispan: 1111, fspan: 1999, valueinabacus: 0, rows: 4, coins: 10, num: 1, config: [{ digits: 4, num: 3 }, { digits: 3, num: 2 }, { digits: 2, num: 2 }], wait: 6, errors: 0, cansubstract: true }
                    },
                ]
            },
        ],
        test: {
            tid: 8,
            label: 'upgradelevel',//Acceder al nivel,
            level: 6,

            exercises: [
                {
                    label: 'sumswith4',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: 'random', ops: ['+'], init: 2999, finish: 5999, ispan: 1999, fspan: 3999, rows: 4, coins: 10, num: 1, errors: 2, cansubstract: true }
                },
                {
                    label: 'subswith4',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: 'random', ops: ['-'], init: 5999, finish: 8999, ispan: 1999, fspan: 3999, rows: 4, coins: 10, num: 1, errors: 2, cansubstract: true }
                },
                {
                    label: 'visualizeabacus',
                    impl: 'mentalabacusfixation',
                    params: { couples: 'random', init: 1000, finish: 9999, valueinabacus: 0, rows: 4, coins: 10, num: 2, errors: 2, back: false }
                },
                {
                    label: 'baloonschall',//El reto de los globos,
                    impl: 'baloonschall',
                    params: {
                        couples: 'random', ops: ['-', '+'], 'numops': 2, init: 3000, finish: 6000, ispan: 111, fspan: 2999, rows: 4, valueinabacus: 0, 'upperlimit': 9999, coins: 10, num: 1, errors: 0,
                        levels: [{ num: 14, vel: 10 }]
                    }
                },
                {
                    label: 'mentalsumchall',
                    impl: 'mentalsumsubstraction',
                    params: { couples: 'random', ops: ['+'], init: 1111, finish: 2999, ispan: 1111, fspan: 1999, valueinabacus: 0, rows: 4, coins: 1, num: 1, config: [{ digits: 4, num: 3 }, { digits: 3, num: 2 }, { digits: 2, num: 2 }], wait: 6, errors: 0, cansubstract: true }
                },
                {
                    label: 'mentalsubchall',
                    impl: 'mentalsumsubstraction',
                    params: { couples: 'random', ops: ['-'], init: 7111, finish: 9999, ispan: 1111, fspan: 1999, valueinabacus: 0, rows: 4, coins: 1, num: 1, config: [{ digits: 4, num: 3 }, { digits: 3, num: 2 }, { digits: 2, num: 2 }], wait: 6, errors: 0, cansubstract: true }
                }
            ]
        }
    },

];

const plan3 = [
    /**level 1: base, suma resta sencillas **/
    {
        level: 1,
        label: 'paper',
        open: true,
        units: [
            {
                uid: 1,
                label: 'numbers1_2_3_figs',//'El ábaco japonés',
                icon: 'levels/numeros_1_2_3_cifras.png',
                exercises: [
                    {
                        label: 'knowtheabacus',
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'kEYW61DsrYQ', coins: 10 }
                    },

                    {
                        label: 'numbersinabacus',//'numbers',
                        impl: 'theory',
                        icon: 'video',
                        params: { video: '_lcc8c90OJY', coins: 10 }
                    },

                    {
                        label: 'idnumbers',//'Identifica',
                        impl: 'whatnumberis',
                        icon: 'identifica',
                        params: { series: [{ init: 0, finish: 9, num: 3 }, { init: 10, finish: 99, num: 3 }, { init: 100, finish: 999, num: 3 }], init: 0, finish: 9, rows: 3, coins: 10, num: 5, errors: '2' }
                    },
                    {
                        label: 'repnumbers',//'Representa',
                        impl: 'representinabacus',
                        icon: 'practica',
                        params: { series: [{ init: 0, finish: 9, num: 3 }, { init: 10, finish: 99, num: 3 }, { init: 100, finish: 999, num: 3 }], init: 1, finish: 9, rows: 3, coins: 10, num: 5, errors: 2 }
                    },

                    {
                        label: 'visualizeabacus',
                        impl: 'mentalabacusfixation',
                        icon: 'visualiza',
                        params: { series: [{ init: 0, finish: 9, num: 2 }, { init: 10, finish: 99, num: 2 }, { init: 100, finish: 999, num: 2 }], couples: 'random', init: 1, finish: 9, valueinabacus: 0, rows: 3, coins: 10, num: 6, errors: 2, back: false }
                    },
                ]
            },

            {
                uid: 2,
                label: 'easysumsandsubs',//'Representación de números',
                icon: 'levels/sumas_y_restas.png',
                exercises: [
                    {
                        label: 'sumsubsboard',//Sumas y restas con el tablero,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'Nl3nT3xia68', coins: 10 }
                    },
                    {
                        label: 'practiceboard',//Practica con el tablero,
                        impl: 'cartoonlayout',
                        icon: 'representa',
                        params: { couples: ['1+1', '1+2', '1+3', '1+5', '1+6', '1+7', '1+8', '2+1', '2+2', '2+5', '2+6', '2+7', '3+1', '3+5', '3+6', '4+5', '5+1', '5+2', '5+3', '5+4', '6+1', '6+2', '6+3', '7+1', '7+2', '8+1', '2-1', '3-2', '3-1', '4-1', '4-2', '4-3', '6-1', '6-5', '7-1', '7-2', '7-5', '7-6', '8-1', '8-2', '8-3', '8-5', '8-6', '8-7', '9-1', '9-2', '9-3', '9-4', '9-5', '9-6', '9-7', '9-8'], rows: 1, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'sumsubsabacus',//Sumas y restas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'G_CMa4gePLI', coins: 10 }
                    },
                    {
                        label: 'practiceabacus',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: ['1+1', '1+2', '1+3', '1+5', '1+6', '1+7', '1+8', '2+1', '2+2', '2+5', '2+6', '2+7', '3+1', '3+5', '3+6', '4+5', '5+1', '5+2', '5+3', '5+4', '6+1', '6+2', '6+3', '7+1', '7+2', '8+1', '2-1', '3-2', '3-1', '4-1', '4-2', '4-3', '6-1', '6-5', '7-1', '7-2', '7-5', '7-6', '8-1', '8-2', '8-3', '8-5', '8-6', '8-7', '9-1', '9-2', '9-3', '9-4', '9-5', '9-6', '9-7', '9-8'], rows: 1, coins: 10, num: 10, errors: '2' }
                    },
                    {
                        label: 'visualizebeads',
                        impl: 'coupleschall',
                        icon: 'reto',
                        params: { coins: 10, num: 1, errors: 0, levels: [{ items: 2, beads: 3, time: 2 }, { items: 3, beads: 3, time: 2 }, { items: 4, beads: 3, time: 2 }] }
                    },
                ]
            },

            {
                uid: 3,
                label: 'strengthenlearnt',//Sumar y restar con el 5,
                icon: 'levels/juega.png',
                exercises: [
                    {
                        label: 'matchchall',//El reto de las parejas,
                        impl: 'memoset',
                        icon: 'heart',
                        params: { series: [{ init: 0, finish: 9, num: 4 }, { init: 10, finish: 99, num: 4 }, { init: 100, finish: 999, num: 4 }], couples: 'random', init: 0, finish: 9, rows: 3, valueinabacus: 0, coins: 10, num: 4, errors: 0 }
                    },
                    {
                        label: 'baloonschall',//El reto de los globos,
                        impl: 'baloonschall',
                        icon: 'globo',
                        params: { couples: ['2+2', '2+5', '2+6', '2+7', '3+5', '3+6', '4+5', '5+2', '5+3', '5+4', '6+2', '6+3', '7+2', '4-2', '4-3', '6-5', '7-2', '7-5', '7-6', '8-2', '8-3', '8-5', '8-6', '8-7', '9-2', '9-3', '9-4', '9-5', '9-6', '9-7', '9-8'], init: 1, finish: 9, rows: 1, valueinabacus: 0, coins: 10, num: 0, errors: 0, levels: [{ num: 2, vel: 20 }, { num: 4, vel: 20 }, { num: 8, vel: 20 }, { num: 6, vel: 10 }, { num: 8, vel: 10 }] }
                    },
                    {
                        label: 'mentalsumchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: ['2+7', '3+6', '4+5', '5+4', '6+1', '6+2', '6+3', '7+1', '7+2', '8+1', '7+2', '1+8', '1+1+2', '1+3+5', '1+5+2', '1+6+1', '2+2+5', '2+6+1', '3+1+5'], valueinabacus: 0, rows: 1, coins: 10, num: 5, errors: 0 }
                    },
                    {
                        label: 'mentalsubchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: ['9-2', '9-4', '9-5', '9-7', '9-8', '8-1', '8-2', '8-3', '8-5', '8-7', '8-6', '7-2', '7-5', '7-6', '6-1', '6-5', '4-1', '4-2', '4-3', '3-1', '3-2', '9-6-1', '9-1-7'], valueinabacus: 0, rows: 1, coins: 10, num: 5, errors: 0, cansubstract: true }
                    },
                ]
            },
        ],
        test:
        {
            tid: 1,
            label: 'upgradelevel',//Acceder al nivel,
            icon: 'levels/sube_nivel.png',
            level: 1,
            exercises: [
                {
                    label: 'idnumbers',//'Identifica',
                    impl: 'whatnumberis',
                    icon: 'identifica',
                    params: { init: 1, finish: 999, rows: 3, coins: 10, num: 3, errors: '2' }
                },
                {
                    label: 'repnumbers',//'Representa',
                    impl: 'representinabacus',
                    icon: 'practica',
                    params: { init: 1, finish: 999, rows: 3, coins: 10, num: 3, errors: 2 }
                },
                {
                    label: 'baloonschall',//El reto de los globos,
                    impl: 'baloonschall',
                    params: { couples: ['2+2', '2+5', '2+6', '2+7', '3+5', '3+6', '4+5', '5+2', '5+3', '5+4', '6+2', '6+3', '7+2', '4-2', '4-3', '6-5', '7-2', '7-5', '7-6', '8-2', '8-3', '8-5', '8-6', '8-7', '9-2', '9-3', '9-4', '9-5', '9-6', '9-7', '9-8'], init: 1, finish: 9, rows: 1, valueinabacus: 0, coins: 10, num: 0, errors: 0, 'maxfails': 0, levels: [{ num: 3, vel: 20 }, { num: 3, vel: 20 }] }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['2+5-6', '4+5-3', '1+6-7', '4+5-8', '3+6-5', '1+8-9', '7-6+3', '8-7+3', '4-3+6', '9-3-6', '2+7-4', '8-5+6', '7+1-5', '3-1+6', '9-5-2', '4+5-8', '3+5-7', '7+1-3', '8-2-5', '6+2-3', '6+2-3', '5+1-6', '9-3-5', '7-2-5', '9-7+5'], rows: 1, coins: 10, num: 4, errors: 0 }
                },
                {
                    label: 'mental',
                    impl: 'mentalsumsubstraction',
                    params: { couples: ['2+5-6', '4+5-3', '1+6-7', '4+5-8', '3+6-5', '1+8-9', '7-6+3', '8-7+3', '4-3+6', '9-3-6', '2+7-4', '8-5+6', '7+1-5', '3-1+6', '9-5-2', '4+5-8', '3+5-7', '7+1-3', '8-2-5', '6+2-3', '6+2-3', '5+1-6', '9-3-5', '7-2-5', '9-7+5'], valueinabacus: 0, rows: 1, coins: 10, num: 1, vel: 'medium', errors: 0 }
                }
            ]
        }
    },

    /**level 2: suma y resta 5 **/
    {
        level: 2,
        label: 'wood',
        open: true,
        units: [
            {
                uid: 4,
                label: 'sumswith5',//Sumas y restas sencillas,
                level: 2,
                icon: 'levels/sumas_5.png',
                exercises: [
                    {
                        label: 'sumboard',//Sumas con el tablero,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: '4jsnWli3mbI', coins: 10 }
                    },
                    {
                        label: 'practiceboard',//Practica con el tablero,
                        impl: 'cartoonlayout',
                        icon: 'representa',
                        params: { couples: ['1+4', '2+3', '2+4', '3+2', '3+3', '4+1', '4+2', '4+3', '4+4', '4+5'], rows: 1, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'sumabacus',//Sumas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'khGV_lEcYeY', coins: 10 }
                    },
                    {
                        label: 'practiceabacus',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: ['1+4', '2+3', '2+4', '3+2', '3+3', '4+1', '4+2', '4+3', '4+4', '4+5'], rows: 1, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                ]
            },

            {
                uid: 5,
                label: 'subswith5',//Sumas y restas sencillas,
                level: 2,
                icon: 'levels/restas_5.png',
                exercises: [
                    {
                        label: 'subsboard',//Restas con el tablero,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'ktdKDJ93F6Y', coins: 10 }
                    },
                    {
                        label: 'practiceboard',//Practica con el tablero,
                        impl: 'cartoonlayout',
                        icon: 'representa',
                        params: { couples: ['5-1', '5-2', '5-3', '5-4', '6-3', '6-4', '7-3', '7-4', '8-4', '9-4'], rows: 1, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'subsabacus',//Restas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: '81g2Jeo80Zg', coins: 10 }
                    },
                    {
                        label: 'practiceabacus',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: ['5-1', '5-2', '5-3', '5-4', '6-3', '6-4', '7-3', '7-4', '8-4', '9-4'], rows: 1, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                ]
            },

            {
                uid: 6,
                label: 'strengthenlearnt',//Sumar y restar con el 5,
                level: 2,
                icon: 'levels/juega.png',
                exercises: [
                    {
                        label: 'sumserieschall',
                        impl: 'sumsubstractionseries',
                        icon: 'sumas',
                        params: { couples: 'random', ops: '+', init: 1, finish: 4, valueinabacus: 0, 'max': 9, rows: 1, coins: 10, num: 4, errors: 0, show_time: true, cansubstract: true }
                    },
                    {
                        label: 'subsserieschall',
                        impl: 'sumsubstractionseries',
                        icon: 'restas',
                        params: { couples: 'random', ops: '-', init: 1, finish: 4, valueinabacus: 0, 'max': 9, rows: 1, coins: 10, num: 4, errors: 0, show_time: true, cansubstract: true }
                    },
                    {
                        label: 'mentalsumchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: ['1+4', '2+3', '2+4', '3+2', '3+3', '3+4', '4+1'], valueinabacus: 0, rows: 1, coins: 10, num: 5, errors: 0, cansubstract: true }
                    },
                    {
                        label: 'mentalsubchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: ['5-1', '5-2', '6-2', '5-3', '6-3', '7-3', '5-4', '6-4', '7-4', '8-4'], valueinabacus: 0, rows: 1, coins: 10, num: 5, errors: 0, cansubstract: true }
                    },
                ]
            },
        ],
        test:
        {
            tid: 2,
            label: 'upgradelevel',//Acceder al nivel,
            level: 2,

            exercises: [
                {
                    label: 'practiceboard',//Practica con el tablero,
                    impl: 'cartoonlayout',
                    params: { couples: ['1+4', '2+4', '3+4', '4+4', '2+3', '3+3', '4+3', '3+2', '4+2', '4+1'], rows: 1, coins: 10, num: 2, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['1+4', '2+4', '3+4', '4+4'], rows: 1, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['2+3', '3+3', '4+3'], rows: 1, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['3+2', '4+2'], rows: 1, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['4+1'], rows: 1, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceboard',//Practica con el tablero,
                    impl: 'cartoonlayout',
                    params: { couples: ['8-4', '7-4', '6-4', '5-4', '7-3', '6-3', '5-3', '6-2', '5-2', '5-1'], rows: 1, coins: 10, num: 2, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['8-4', '7-4', '6-4', '5-4'], rows: 1, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['7-3', '6-3', '5-3'], rows: 1, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['6-2', '5-2'], rows: 1, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['5-1'], rows: 1, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'mentalsumchall',
                    impl: 'mentalsumsubstraction',
                    params: { couples: ['1+4+1', '2+3+2', '1+1+4', '2+1+2', '3+3+3', '3+4+2', '4+1+1'], valueinabacus: 0, rows: 1, coins: 10, num: 5, errors: 0, cansubstract: true }
                },
                {
                    label: 'mentalsubchall',
                    impl: 'mentalsumsubstraction',
                    params: { couples: ['5-1-2', '5-2-1', '6-2-3', '5-3-1', '6-3-1', '7-3-2', '5-4-1', '6-4-1', '7-4-1', '8-4-2'], valueinabacus: 0, rows: 1, coins: 10, num: 5, errors: 0, cansubstract: true }
                }
            ]
        }
    },

    /**level 3: decenas y suma resta 10 **/
    {
        level: 3,
        label: 'iron',
        open: true,
        units: [
            {
                uid: 8,
                label: 'sumswith10',
                level: 3,
                icon: 'levels/sumas_10.png',
                exercises: [
                    {
                        label: 'sumboard',//Sumas con el tablero,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'Ib8lyN0kNI4', coins: 10 }
                    },
                    {
                        label: 'practiceboardI',//Practica con el tablero,
                        impl: 'cartoonlayout',
                        icon: 'representa',
                        params: { couples: ['9+3', '9+4', '9+5', '9+6', '9+7', '9+8', '9+9', '8+2', '8+3', '8+4', '8+5', '8+7', '8+8', '8+9', '7+9', '7+3', '7+4', '7+5', '6+9', '6+5', '6+4', '5+5', '4+9', '4+8', '4+7', '4+6', '3+9', '3+8', '3+7', '2+8', '2+9', '1+9'], rows: 2, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'practiceboardII',//Practica con el tablero,
                        impl: 'cartoonlayout',
                        icon: 'representa',
                        params: { couples: ['8+6', '7+7', '7+6', '6+6', '6+7', '6+8', '5+6', '5+7', '5+8', '5+9'], rows: 2, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'sumabacus',//Sumas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: '2zW7fAVMVQ0', coins: 10 }
                    },
                    {
                        label: 'practiceabacusI',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: ['9+3', '9+4', '9+5', '9+6', '9+7', '9+8', '9+9', '8+2', '8+3', '8+4', '8+5', '8+7', '8+8', '8+9', '7+9', '7+3', '7+4', '7+5', '6+9', '6+5', '6+4', '5+5', '4+9', '4+8', '4+7', '4+6', '3+9', '3+8', '3+7', '2+8', '2+9', '1+9'], rows: 2, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'practiceabacusII',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: ['8+6', '7+7', '7+6', '6+6', '6+7', '6+8', '5+6', '5+7', '5+8', '5+9'], rows: 2, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'visualizebeads',
                        impl: 'coupleschall',
                        icon: 'relaciona',
                        params: { coins: 10, num: 1, errors: 0, levels: [{ items: 3, beads: 3, time: 1.5 }, { items: 4, beads: 3, time: 1.5 }, { items: 2, beads: 3, time: 2 }] }
                    }
                ]
            },


            {
                uid: 9,
                label: 'subswith10',
                level: 3,
                icon: 'levels/restas_10.png',
                exercises: [
                    {
                        label: 'subsboard',//Restas con el tablero,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: '8hfxTJ2Y9fg', coins: 10 }
                    },
                    {
                        label: 'practiceboardI',//Practica con el tablero,
                        impl: 'cartoonlayout',
                        icon: 'representa',
                        params: { couples: ['10-1', '10-2', '10-3', '10-4', '10-5', '10-6', '10-7', '10-8', '10-9', '11-2', '11-3', '11-4', '11-5', '11-7', '11-8', '11-9', '12-3', '12-4', '12-5', '13-4', '13-5', '13-9', '14-5', '15-6', '15-7', '15-8', '15-9', '16-7', '16-8', '16-9', '17-8', '17-9', '18-9'], rows: 2, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'practiceboardII',//Practica con el tablero,
                        impl: 'cartoonlayout',
                        icon: 'representa',
                        params: { couples: ['11-6', '12-6', '12-7', '13-6', '13-7', '13-8', '14-6', '14-7', '14-8', '14-9'], rows: 2, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'subsabacus',//Restas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'zVi2c0d3aSQ', coins: 10 }
                    },
                    {
                        label: 'practiceabacusI',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: ['10-1', '10-2', '10-3', '10-4', '10-5', '10-6', '10-7', '10-8', '10-9', '11-2', '11-3', '11-4', '11-5', '11-7', '11-8', '11-9', '12-3', '12-4', '12-5', '13-4', '13-5', '13-9', '14-5', '15-6', '15-7', '15-8', '15-9', '16-7', '16-8', '16-9', '17-8', '17-9', '18-9'], rows: 2, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'practiceabacusII',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: ['11-6', '12-6', '12-7', '13-6', '13-7', '13-8', '14-6', '14-7', '14-8', '14-9'], rows: 2, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'visualizeabacus',
                        impl: 'mentalabacusfixation',
                        icon: 'visualiza',
                        params: { couples: 'random', init: 10, finish: 99, valueinabacus: 0, rows: 2, coins: 10, num: 5, errors: 2, back: false }
                    }
                ]
            },

            {
                uid: 10,
                label: 'strengthenlearnt',//Sumar y restar con el 5,
                level: 3,
                icon: 'levels/juega.png',
                exercises: [
                    {
                        label: 'matchchall',//El reto de las parejas,
                        impl: 'memoset',
                        icon: 'relaciona',
                        params: { couples: 'random', init: 10, finish: 99, rows: 2, valueinabacus: 0, coins: 10, num: 4, errors: 0 }
                    },
                    {
                        label: 'baloonschall',//El reto de los globos,
                        impl: 'baloonschall',
                        icon: 'globo',
                        params: { couples: ['8+6', '7+7', '7+6', '6+6', '6+7', '6+8', '5+6', '5+7', '5+8', '5+9', '9+3', '9+4', '9+5', '9+6', '9+7', '9+8', '9+9', '8+2', '8+3', '8+4', '8+5', '8+7', '8+8', '8+9', '7+9', '7+3', '7+4', '7+5', '6+9', '6+5', '6+4', '5+5', '4+9', '4+8', '4+7', '4+6', '3+9', '3+8', '3+7', '2+8', '2+9', '1+9', '8+6', '7+7', '7+6', '6+6', '6+7', '6+8', '5+6', '5+7', '5+8', '5+9', '11-6', '12-6', '12-7', '13-6', '13-7', '13-8', '14-6', '14-7', '14-8', '14-9', '10-1', '10-2', '10-3', '10-4', '10-5', '10-6', '10-7', '10-8', '10-9', '11-2', '11-3', '11-4', '11-5', '11-7', '11-8', '11-9', '12-3', '12-4', '12-5', '13-4', '13-5', '13-9', '14-5', '15-6', '15-7', '15-8', '15-9', '16-7', '16-8', '16-9', '17-8', '17-9', '18-9', '11-6', '12-6', '12-7', '13-6', '13-7', '13-8', '14-6', '14-7', '14-8', '14-9'], init: 10, finish: 99, rows: 2, valueinabacus: 0, coins: 10, num: 1, errors: 0, levels: [{ num: 4, vel: 15 }, { num: 6, vel: 15 }, { num: 8, vel: 15 }, { num: 10, vel: 10 }, { num: 12, vel: 10 }] }
                    },
                    {
                        label: 'sumserieschallI',
                        impl: 'sumsubstractionseries',
                        icon: 'sumas',
                        params: { couples: 'random', ops: '+', init: 1, finish: 4, valueinabacus: 0, 'max': 99, rows: 2, coins: 10, num: 4, errors: 0, show_time: true, cansubstract: true }
                    },
                    {
                        label: 'sumserieschallII',
                        impl: 'sumsubstractionseries',
                        icon: 'sumas',
                        params: { couples: 'random', ops: '+', init: 5, finish: 7, valueinabacus: 0, 'max': 99, rows: 2, coins: 10, num: 3, errors: 0, show_time: true, cansubstract: true }
                    },
                    {
                        label: 'sumserieschallIII',
                        impl: 'sumsubstractionseries',
                        icon: 'sumas',
                        params: { couples: 'random', ops: '+', init: 8, finish: 9, valueinabacus: 0, 'max': 99, rows: 2, coins: 10, num: 2, errors: 0, show_time: true, cansubstract: true }
                    },
                    {
                        label: 'subsserieschallI',
                        impl: 'sumsubstractionseries',
                        icon: 'restas',
                        params: { couples: 'random', ops: '-', init: 1, finish: 4, valueinabacus: 0, 'max': 99, rows: 2, coins: 10, num: 4, errors: 0, show_time: true, cansubstract: true }
                    },
                    {
                        label: 'subsserieschallII',
                        impl: 'sumsubstractionseries',
                        icon: 'restas',
                        params: { couples: 'random', ops: '-', init: 5, finish: 7, valueinabacus: 0, 'max': 99, rows: 2, coins: 10, num: 3, errors: 0, show_time: true, cansubstract: true }
                    },
                    {
                        label: 'subsserieschallIII',
                        impl: 'sumsubstractionseries',
                        icon: 'restas',
                        params: { couples: 'random', ops: '-', init: 8, finish: 9, valueinabacus: 0, 'max': 99, rows: 2, coins: 10, num: 2, errors: 0, show_time: true, cansubstract: true }
                    },
                    {
                        label: 'mentalsumchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: 'random', ops: ['+'], init: 1, finish: 9, ispan: 1, fspan: 9, valueinabacus: 0, rows: 2, coins: 10, num: 1, config: [{ digits: 1, num: 3 }], wait: 3, errors: 0, cansubstract: true }
                    },
                    {
                        label: 'mentalsubchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: 'random', ops: ['-'], init: 11, finish: 19, ispan: 1, fspan: 9, valueinabacus: 0, rows: 2, coins: 10, num: 1, config: [{ digits: 1, num: 3 }], wait: 3, errors: 0, cansubstract: true }
                    },
                ]
            },
        ],
        test:
        {
            tid: 3,
            label: 'upgradelevel',//Acceder al nivel,
            level: 3,

            exercises: [
                {
                    label: 'baloonschall',//El reto de los globos,
                    impl: 'baloonschall',
                    params: { couples: ['8+6', '7+7', '7+6', '6+6', '6+7', '6+8', '5+6', '5+7', '5+8', '5+9', '9+3', '9+4', '9+5', '9+6', '9+7', '9+8', '9+9', '8+2', '8+3', '8+4', '8+5', '8+7', '8+8', '8+9', '7+9', '7+3', '7+4', '7+5', '6+9', '6+5', '6+4', '5+5', '4+9', '4+8', '4+7', '4+6', '3+9', '3+8', '3+7', '2+8', '2+9', '1+9', '8+6', '7+7', '7+6', '6+6', '6+7', '6+8', '5+6', '5+7', '5+8', '5+9', '11-6', '12-6', '12-7', '13-6', '13-7', '13-8', '14-6', '14-7', '14-8', '14-9', '10-1', '10-2', '10-3', '10-4', '10-5', '10-6', '10-7', '10-8', '10-9', '11-2', '11-3', '11-4', '11-5', '11-7', '11-8', '11-9', '12-3', '12-4', '12-5', '13-4', '13-5', '13-9', '14-5', '15-6', '15-7', '15-8', '15-9', '16-7', '16-8', '16-9', '17-8', '17-9', '18-9', '11-6', '12-6', '12-7', '13-6', '13-7', '13-8', '14-6', '14-7', '14-8', '14-9'], init: 10, finish: 99, rows: 2, valueinabacus: 0, coins: 10, num: 1, errors: 0, levels: [{ num: 4, vel: 20 }, { num: 4, vel: 10 }] }

                    // params: { couples: 'random', init: 11, finish: 99, rows: 2, valueinabacus: 0, coins: 10, num: 4, errors: 0, 'maxfails': 0, levels: [{ num: 4, vel: 20 }, { num: 4, vel: 10 }] }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['8+6', '7+6', '6+6', '5+6'], rows: 2, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['7+7', '6+7', '5+7'], rows: 2, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['6+8', '5+8', '5+9'], rows: 2, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['9+3', '9+4', '8+3', '8+4', '7+3', '7+4'], rows: 2, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['11-6', '12-6', '13-6', '14-6'], rows: 2, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['12-7', '13-7', '14-7'], rows: 2, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['13-8', '14-8', '14-9'], rows: 2, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: ['11-2', '11-3', '11-4', '12-3', '12-4', '13-4'], rows: 2, coins: 10, num: 1, errors: 0, cansubstract: true }
                },
                {
                    label: 'mentalsumchall',
                    impl: 'mentalsumsubstraction',
                    params: { couples: 'random', ops: ['+'], init: 10, finish: 30, ispan: 1, fspan: 9, valueinabacus: 0, rows: 2, coins: 10, num: 1, config: [{ digits: 2, num: 6 }], wait: 3, errors: 0, cansubstract: true }
                },
                {
                    label: 'mentalsubchall',
                    impl: 'mentalsumsubstraction',
                    params: { couples: 'random', ops: ['-'], init: 70, finish: 90, ispan: 1, fspan: 9, valueinabacus: 0, rows: 2, coins: 10, num: 1, config: [{ digits: 2, num: 6 }], wait: 3, errors: 0, cansubstract: true }
                }
            ]
        }
    },

    /**level 4: centenas y suma resta 2c **/
    {
        level: 4,
        label: 'clay',
        open: true,
        units: [

            {
                uid: 12,
                label: 'sumsandsubswith2',
                level: 4,
                icon: 'levels/sumas_y_restas_2_cifras.png',
                exercises: [
                    {
                        label: 'sumabacus',//Sumas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'ADi-O0QfUbc', coins: 10 }
                    },
                    {
                        label: 'practicesums',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: 'random', ops: ['+'], init: 29, finish: 59, ispan: 19, fspan: 39, rows: 2, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'subsabacus',//Restas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: '7mNFip4-6KY', coins: 10 }
                    },
                    {
                        label: 'practicesubs',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: 'random', ops: ['-'], init: 59, finish: 89, ispan: 19, fspan: 39, rows: 2, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                ]
            },

            {
                uid: 13,
                label: 'strengthenlearnt',//practicar
                level: 4,
                icon: 'levels/juega.png',
                exercises: [
                    {
                        label: 'matchchall',//El reto de las parejas,
                        impl: 'memoset',
                        icon: 'relaciona',
                        params: { couples: 'random', init: 10, finish: 99, rows: 2, valueinabacus: 0, coins: 10, num: 4, errors: 0, style: 'small', 'flipbacktime': 2000 }
                    },
                    {
                        label: 'baloonschall',//El reto de los globos,
                        impl: 'baloonschall',
                        icon: 'globo',
                        params: {
                            couples: 'random', ops: ['-', '+'], 'numops': 2, init: 30, finish: 60, ispan: 11, fspan: 29, rows: 2, valueinabacus: 0, coins: 10, num: 1, errors: 0,
                            levels: [{ num: 6, vel: 15 }, { num: 8, vel: 15 }, { num: 10, vel: 15 }, { num: 12, vel: 10 }, { num: 14, vel: 10 }]
                        }
                    },
                    {
                        label: 'pathchall',//El reto del camino,
                        impl: 'pathchall',
                        icon: 'path',
                        params: { couples: 'random', init: 2, finish: 6, op: '+', ispan: 11, fspan: 19, items: 5, num: 2, cols: 6, coins: 10, errors: 0 }
                    },
                    {
                        label: 'mentalsumchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: 'random', ops: ['+'], init: 11, finish: 29, ispan: 11, fspan: 29, valueinabacus: 0, rows: 2, coins: 10, num: 1, config: [{ digits: 2, num: 3 }, { digits: 1, num: 3 }], wait: 4, errors: 0, cansubstract: true }
                    },
                    {
                        label: 'mentalsubchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: 'random', ops: ['-'], init: 71, finish: 99, ispan: 11, fspan: 19, valueinabacus: 0, rows: 2, coins: 10, num: 1, config: [{ digits: 2, num: 3 }, { digits: 1, num: 3 }], wait: 4, errors: 0, cansubstract: true }
                    },
                ]
            },
        ],
        test:
        {
            tid: 4,
            label: 'upgradelevel',//Acceder al nivel,
            level: 4,

            exercises: [
                {
                    label: 'baloonschall',//El reto de los globos,
                    impl: 'baloonschall',
                    params: { couples: 'random', ops: ['-', '+'], 'numops': 2, init: 30, finish: 60, ispan: 11, fspan: 29, rows: 2, valueinabacus: 0, coins: 10, num: 1, errors: 0, levels: [{ num: 6, vel: 20 }, { num: 6, vel: 10 }] }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: 'random', ops: ['+'], init: 29, finish: 59, ispan: 19, fspan: 39, rows: 2, coins: 10, num: 5, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: 'random', ops: ['-'], init: 59, finish: 89, ispan: 19, fspan: 39, rows: 2, coins: 10, num: 5, errors: 0, cansubstract: true }
                },
                {
                    label: 'mentalsumchall',
                    impl: 'mentalsumsubstraction',
                    params: { couples: 'random', ops: ['+'], init: 11, finish: 29, ispan: 11, fspan: 29, valueinabacus: 0, rows: 2, coins: 10, num: 1, config: [{ digits: 2, num: 4 }, { digits: 1, num: 4 }], wait: 4, errors: 0, cansubstract: true }
                },
                {
                    label: 'mentalsubchall',
                    impl: 'mentalsumsubstraction',
                    params: { couples: 'random', ops: ['-'], init: 71, finish: 99, ispan: 11, fspan: 19, valueinabacus: 0, rows: 2, coins: 10, num: 1, config: [{ digits: 2, num: 4 }, { digits: 1, num: 4 }], wait: 4, errors: 0, cansubstract: true }
                }
            ]
        }
    },

    /**level 5: suma resta 3c **/
    {
        level: 5,
        label: 'bronze',
        open: true,
        units: [
            {
                uid: 14,
                label: 'sumsandsubswith3',
                level: 5,
                icon: 'levels/sumas_y_restas_3_cifras.png',
                exercises: [
                    {
                        label: 'sumabacus',//Sumas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'AvFLuBli3Zo', coins: 10 }
                    },
                    {
                        label: 'practicesums',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: 'random', ops: ['+'], init: 299, finish: 599, ispan: 199, fspan: 399, rows: 3, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'subsabacus',//Restas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: '3tfvd4o_G8s', coins: 10 }
                    },
                    {
                        label: 'practicesubs',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: 'random', ops: ['-'], init: 599, finish: 899, ispan: 199, fspan: 399, rows: 3, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                ]
            },

            {
                uid: 15,
                label: 'strengthenlearnt',//Sumar y restar con el 5,
                level: 5,
                icon: 'levels/juega.png',
                exercises: [
                    {
                        label: 'matchchall',//El reto de las parejas,
                        impl: 'memoset',
                        icon: 'heart',
                        params: { couples: 'random', init: 100, finish: 999, rows: 3, valueinabacus: 0, coins: 10, num: 4, errors: 0, style: 'small', 'flipbacktime': 2000 }
                    },
                    {
                        label: 'baloonschall',//El reto de los globos,
                        impl: 'baloonschall',
                        icon: 'globo',
                        params: {
                            couples: 'random', ops: ['-', '+'], 'numops': 2, init: 300, finish: 600, ispan: 111, fspan: 299, rows: 3, valueinabacus: 0, 'upperlimit': 999, coins: 10, num: 1, errors: 0,
                            levels: [{ num: 6, vel: 15 }, { num: 8, vel: 15 }, { num: 10, vel: 15 }, { num: 12, vel: 10 }, { num: 14, vel: 10 }]
                        }
                    },
                    {
                        label: 'pathchall',//El reto del camino,
                        impl: 'pathchall',
                        icon: 'path',
                        params: { couples: 'random', init: 20, finish: 60, op: '+', ispan: 101, fspan: 199, num: 2, items: 7, cols: 4, coins: 10, errors: 0 }
                    },
                    {
                        label: 'mentalsumchall',
                        icon: 'mental',
                        impl: 'mentalsumsubstraction',
                        params: { couples: 'random', ops: ['+'], init: 111, finish: 299, ispan: 111, fspan: 199, valueinabacus: 0, rows: 3, coins: 10, num: 1, config: [{ digits: 3, num: 3 }, { digits: 2, num: 2 }, { digits: 1, num: 2 }], wait: 5, errors: 0, cansubstract: true }
                    },
                    {
                        label: 'mentalsubchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: 'random', ops: ['-'], init: 711, finish: 999, ispan: 111, fspan: 199, valueinabacus: 0, rows: 3, coins: 10, num: 1, config: [{ digits: 3, num: 3 }, { digits: 2, num: 2 }, { digits: 1, num: 2 }], wait: 5, errors: 0, cansubstract: true }
                    },
                ]
            },
        ],
        test:
        {
            tid: 5,
            label: 'upgradelevel',//Acceder al nivel,
            level: 5,

            exercises: [
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: 'random', ops: ['+'], init: 299, finish: 599, ispan: 199, fspan: 399, rows: 3, coins: 10, num: 3, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: 'random', ops: ['-'], init: 599, finish: 899, ispan: 199, fspan: 399, rows: 3, coins: 10, num: 3, errors: 0, cansubstract: true }
                },
                {
                    label: 'baloonschall',//El reto de los globos,
                    impl: 'baloonschall',
                    params: {
                        couples: 'random', ops: ['-', '+'], 'numops': 2, init: 300, finish: 600, ispan: 111, fspan: 299, rows: 3, valueinabacus: 0, 'upperlimit': 999, coins: 10, num: 1, errors: 0,
                        levels: [{ num: 12, vel: 10 }, { num: 14, vel: 10 }]
                    }
                },
                {
                    label: 'mentalsumchall',
                    impl: 'mentalsumsubstraction',
                    params: { couples: 'random', ops: ['+'], init: 111, finish: 299, ispan: 111, fspan: 199, valueinabacus: 0, rows: 3, coins: 10, num: 1, config: [{ digits: 3, num: 3 }, { digits: 2, num: 3 }, { digits: 1, num: 3 }], wait: 5, errors: 0, cansubstract: true }
                },
                {
                    label: 'mentalsubchall',
                    impl: 'mentalsumsubstraction',
                    params: { couples: 'random', ops: ['-'], init: 711, finish: 999, ispan: 111, fspan: 199, valueinabacus: 0, rows: 3, coins: 10, num: 1, config: [{ digits: 3, num: 3 }, { digits: 2, num: 3 }, { digits: 1, num: 3 }], wait: 5, errors: 0, cansubstract: true }
                }
            ]
        },
    },

    /**level 6: multiplicacionnes 1 c **/
    {
        level: 6,
        label: 'ivory',
        open: true,
        units: [
            {
                uid: 17,
                label: 'multi1',
                level: 7,
                icon: 'levels/multiplicaciones_1_cifra.png',
                exercises: [
                    {
                        label: 'multi1x2',
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'C5jid2zS6Sc', coins: 10 }
                    },
                    {
                        label: 'practiceabacus1x2',//Practica con el ábaco,
                        impl: 'multiplydivide',
                        icon: 'practica',
                        params: { couples: 'random', ops: ['*'], init: 11, finish: 99, ispan: 2, fspan: 9, rows: 8, coins: 10, num: 5, errors: 1, cansubstract: true, markers: true }
                    },
                    {
                        label: 'multi1x3',//Sumas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'eFc2zcz7BQM', coins: 10 }
                    },
                    {
                        label: 'practiceabacus1x3',//Practica con el ábaco,
                        impl: 'multiplydivide',
                        icon: 'practica',
                        params: { couples: 'random', ops: ['*'], init: 111, finish: 999, ispan: 2, fspan: 9, rows: 10, coins: 10, num: 5, errors: 1, cansubstract: true, markers: true }
                    },
                ]
            },

            {
                uid: 18,
                label: 'strengthenlearnt',//Sumar y restar con el 5,
                level: 7,
                icon: 'levels/juega.png',
                exercises: [
                    {
                        label: 'challmultI',//Practica con el ábaco,
                        impl: 'multiplydivide',
                        icon: 'reto',
                        params: { couples: 'random', ops: ['*'], only_answer: true, init: 11, finish: 99, ispan: 2, fspan: 9, rows: 3, coins: 10, num: 5, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                    },
                    {
                        label: 'challmultII',//Practica con el ábaco,
                        impl: 'multiplydivide',
                        icon: 'reto',
                        params: { couples: 'random', ops: ['*'], only_answer: true, init: 111, finish: 999, ispan: 2, fspan: 9, rows: 4, coins: 10, num: 5, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                    },
                    {
                        label: 'baloonschallI',//El reto de los globos,
                        impl: 'baloonschall',
                        icon: 'globo',
                        params: {
                            couples: 'random', ops: ['*'], 'numops': 2, init: 11, finish: 99, ispan: 2, fspan: 9, rows: 3, valueinabacus: 0, 'upperlimit': 9 * 99, coins: 10, num: 1, errors: 0,
                            levels: [{ num: 6, vel: 15 }, { num: 8, vel: 15 }, { num: 10, vel: 15 }, { num: 12, vel: 10 }, { num: 14, vel: 10 }]
                        }

                    },
                    {
                        label: 'baloonschallII',//El reto de los globos,
                        impl: 'baloonschall',
                        icon: 'globo',
                        params: {
                            couples: 'random', ops: ['*'], 'numops': 2, init: 111, finish: 999, ispan: 2, fspan: 9, rows: 4, valueinabacus: 0, 'upperlimit': 999 * 9, coins: 10, num: 1, errors: 0,
                            levels: [{ num: 6, vel: 15 }, { num: 8, vel: 15 }, { num: 10, vel: 15 }, { num: 12, vel: 10 }, { num: 14, vel: 10 }]
                        }
                    },
                ]
            },
        ],
        test:
        {
            tid: 6,
            label: 'upgradelevel',//Acceder al nivel,
            level: 7,

            exercises: [
                {
                    label: 'practiceabacus I',//Practica con el ábaco,
                    impl: 'multiplydivide',
                    params: { couples: 'random', ops: ['*'], init: 11, finish: 99, ispan: 2, fspan: 9, rows: 8, coins: 10, num: 2, errors: 1, cansubstract: true, markers: true }
                },
                {
                    label: 'practiceabacus II',//Practica con el ábaco,
                    impl: 'multiplydivide',
                    params: { couples: 'random', ops: ['*'], init: 111, finish: 999, ispan: 2, fspan: 9, rows: 10, coins: 10, num: 2, errors: 1, cansubstract: true, markers: true }
                },
                {
                    label: 'challmult I',//Practica con el ábaco,
                    impl: 'multiplydivide',
                    params: { couples: 'random', ops: ['*'], only_answer: true, init: 11, finish: 99, ispan: 2, fspan: 9, rows: 3, coins: 8, num: 2, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                },
                {
                    label: 'challmult II',//Practica con el ábaco,
                    impl: 'multiplydivide',
                    params: { couples: 'random', ops: ['*'], only_answer: true, init: 111, finish: 999, ispan: 2, fspan: 9, rows: 4, coins: 10, num: 2, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                },
                {
                    label: 'baloonschall I',//El reto de los globos,
                    impl: 'baloonschall',
                    params: {
                        couples: 'random', ops: ['*'], 'numops': 2, init: 11, finish: 99, ispan: 2, fspan: 9, rows: 3, valueinabacus: 0, 'upperlimit': 9 * 99, coins: 10, num: 1, errors: 0,
                        levels: [{ num: 14, vel: 10 }]
                    }
                },
                {
                    label: 'baloonschall II',//El reto de los globos,
                    impl: 'baloonschall',
                    params: {
                        couples: 'random', ops: ['*'], 'numops': 2, init: 111, finish: 999, ispan: 2, fspan: 9, rows: 4, valueinabacus: 0, 'upperlimit': 999 * 9, coins: 10, num: 1, errors: 0,
                        levels: [{ num: 14, vel: 10 }]
                    }
                }
            ]
        }
    },

    /**level 7: divisiones 1 c **/
    {
        level: 7,
        label: 'glass',
        open: true,
        units: [
            {
                uid: 19,
                label: 'divi1',
                level: 8,
                icon: 'levels/divisiones_1_cifra.png',
                exercises: [
                    {
                        label: 'divide2:1',
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'qDGRuQjY8Vk', coins: 10 }
                    },
                    {
                        label: 'practiceabacus2:1',//Practica con el ábaco,
                        impl: 'multiplydivide',
                        icon: 'practica',
                        params: { couples: 'random', ops: ['/'], init: 11, finish: 99, ispan: 2, fspan: 9, rows: 8, coins: 10, num: 5, errors: 1, cansubstract: true, markers: true }
                    },
                    {
                        label: 'divide3:1',
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'C47INBFxDKY', coins: 10 }
                    },
                    {
                        label: 'practiceabacus3:1',//Practica con el ábaco,
                        impl: 'multiplydivide',
                        icon: 'practica',
                        params: { couples: 'random', ops: ['/'], init: 111, finish: 999, ispan: 2, fspan: 9, rows: 9, coins: 10, num: 5, errors: 1, cansubstract: true, markers: true }
                    },
                ]
            },

            {
                uid: 20,
                label: 'strengthenlearnt',//Sumar y restar con el 5,
                level: 8,
                icon: 'levels/juega.png',
                exercises: [
                    {
                        label: 'challdivI',
                        impl: 'multiplydivide',
                        icon: 'reto',
                        params: { couples: 'random', ops: ['/'], only_answer: true, init: 11, finish: 99, ispan: 2, fspan: 9, rows: 2, coins: 10, num: 5, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                    },
                    {
                        label: 'challdivII',
                        impl: 'multiplydivide',
                        icon: 'reto',
                        params: { couples: 'random', ops: ['/'], only_answer: true, init: 111, finish: 999, ispan: 2, fspan: 9, rows: 3, coins: 10, num: 5, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                    },
                    {
                        label: 'baloonschallI',//El reto de los globos,
                        impl: 'baloonschall',
                        icon: 'globo',
                        params: {
                            couples: 'random', ops: ['/'], 'numops': 2, init: 11, finish: 99, ispan: 2, fspan: 9, rows: 2, valueinabacus: 0, 'upperlimit': 99 / 9, coins: 10, num: 1, errors: 0,
                            levels: [{ num: 6, vel: 15 }, { num: 8, vel: 15 }, { num: 10, vel: 15 }, { num: 12, vel: 10 }, { num: 14, vel: 10 }]
                        }
                    },
                    {
                        label: 'baloonschallII',//El reto de los globos,
                        impl: 'baloonschall',
                        icon: 'globo',
                        params: {
                            couples: 'random', ops: ['/'], 'numops': 2, init: 111, finish: 999, ispan: 2, fspan: 9, rows: 3, valueinabacus: 0, 'upperlimit': 999 / 9, coins: 10, num: 1, errors: 0,
                            levels: [{ num: 6, vel: 15 }, { num: 8, vel: 15 }, { num: 10, vel: 15 }, { num: 12, vel: 10 }, { num: 14, vel: 10 }]
                        }
                    },
                ]
            },
        ],
        test: {
            tid: 7,
            label: 'upgradelevel',//Acceder al nivel,
            level: 8,

            exercises: [
                {
                    label: 'practiceabacus I',//Practica con el ábaco,
                    impl: 'multiplydivide',
                    params: { couples: 'random', ops: ['/'], init: 11, finish: 99, ispan: 2, fspan: 9, rows: 8, coins: 10, num: 2, errors: 1, cansubstract: true, markers: true }
                },
                {
                    label: 'practiceabacus II',//Practica con el ábaco,
                    impl: 'multiplydivide',
                    params: { couples: 'random', ops: ['/'], init: 111, finish: 999, ispan: 2, fspan: 9, rows: 10, coins: 10, num: 2, errors: 1, cansubstract: true, markers: true }
                },
                {
                    label: 'challdiv I',
                    impl: 'multiplydivide',
                    params: { couples: 'random', ops: ['/'], only_answer: true, init: 11, finish: 99, ispan: 2, fspan: 9, rows: 2, coins: 10, num: 2, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                },
                {
                    label: 'challdiv II',
                    impl: 'multiplydivide',
                    params: { couples: 'random', ops: ['/'], only_answer: true, init: 111, finish: 999, ispan: 2, fspan: 9, rows: 3, coins: 10, num: 2, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                },
                {
                    label: 'baloonschall I',//El reto de los globos,
                    impl: 'baloonschall',
                    params: {
                        couples: 'random', ops: ['/'], 'numops': 2, init: 11, finish: 99, ispan: 2, fspan: 9, rows: 2, valueinabacus: 0, 'upperlimit': 99 / 9, coins: 10, num: 1, errors: 0,
                        levels: [{ num: 14, vel: 10 }]
                    }
                },
                {
                    label: 'baloonschall II',//El reto de los globos,
                    impl: 'baloonschall',
                    params: {
                        couples: 'random', ops: ['/'], 'numops': 2, init: 111, finish: 999, ispan: 2, fspan: 9, rows: 3, valueinabacus: 0, 'upperlimit': 999 / 9, coins: 10, num: 1, errors: 0,
                        levels: [{ num: 14, vel: 10 }]
                    }
                }
            ]
        }
    },

    /**level 8: sumas 4c **/
    {
        level: 8,
        label: 'steel',
        open: true,
        units: [
            {
                uid: 21,
                label: 'sumsandsubswith4',
                level: 6,
                icon: 'levels/sumas_y_restas_4_cifras.png',
                exercises: [
                    {
                        label: 'idthous',//'Identifica Unidades',
                        impl: 'whatnumberis',
                        icon: 'identifica',
                        params: { init: 1000, finish: 9999, rows: 4, coins: 10, num: 10, errors: '2' }
                    },
                    {
                        label: 'repthous',//'Representa Unidades',
                        impl: 'representinabacus',
                        icon: 'practica',
                        params: { init: 1000, finish: 9999, rows: 4, coins: 10, num: 10, errors: '2' }
                    },
                    {
                        label: 'sumabacus',//Sumas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'Vbni35G6Xao', coins: 10 }
                    },
                    {
                        label: 'practicesums',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'sumas',
                        params: { couples: 'random', ops: ['+'], init: 2999, finish: 5999, ispan: 1999, fspan: 3999, rows: 4, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'subsabacus',//Restas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'sQ7v39_ULIQ', coins: 10 }
                    },
                    {
                        label: 'practicesubs',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'restas',
                        params: { couples: 'random', ops: ['-'], init: 5999, finish: 8999, ispan: 1999, fspan: 3999, rows: 4, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'visualizeabacus',
                        impl: 'mentalabacusfixation',
                        icon: 'visualiza',
                        params: { couples: 'random', init: 1000, finish: 9999, valueinabacus: 0, rows: 4, coins: 10, num: 5, errors: 2, back: false }
                    }
                ]
            },

            {
                uid: 24,
                label: 'strengthenlearnt',
                level: 6,
                icon: 'levels/juega.png',
                exercises: [
                    {
                        label: 'baloonschall',//El reto de los globos,
                        impl: 'baloonschall',
                        icon: 'globo',
                        params: {
                            couples: 'random', ops: ['-', '+'], 'numops': 2, init: 3000, finish: 6000, ispan: 111, fspan: 2999, rows: 4, valueinabacus: 0, 'upperlimit': 9999, coins: 10, num: 1, errors: 0,
                            levels: [{ num: 6, vel: 15 }, { num: 8, vel: 15 }, { num: 10, vel: 15 }, { num: 12, vel: 10 }, { num: 14, vel: 10 }]
                        }
                    },
                    {
                        label: 'pathchall',//El reto del camino,
                        impl: 'pathchall',
                        icon: 'path',
                        params: { couples: 'random', init: 200, finish: 600, op: '+', ispan: 1001, fspan: 1999, num: 2, items: 7, cols: 4, coins: 10, errors: 0 }
                    },
                    {
                        label: 'mentalsumchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: 'random', ops: ['+'], init: 1111, finish: 2999, ispan: 1111, fspan: 1999, valueinabacus: 0, rows: 4, coins: 10, num: 1, config: [{ digits: 4, num: 3 }, { digits: 3, num: 2 }, { digits: 2, num: 2 }], wait: 6, errors: 0, cansubstract: true }
                    },
                    {
                        label: 'mentalsubchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: 'random', ops: ['-'], init: 7111, finish: 9999, ispan: 1111, fspan: 1999, valueinabacus: 0, rows: 4, coins: 10, num: 1, config: [{ digits: 4, num: 3 }, { digits: 3, num: 2 }, { digits: 2, num: 2 }], wait: 6, errors: 0, cansubstract: true }
                    },
                ]
            },
        ],
        test: {
            tid: 8,
            label: 'upgradelevel',//Acceder al nivel,
            level: 6,

            exercises: [
                {
                    label: 'sumswith4',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: 'random', ops: ['+'], init: 2999, finish: 5999, ispan: 1999, fspan: 3999, rows: 4, coins: 10, num: 1, errors: 2, cansubstract: true }
                },
                {
                    label: 'subswith4',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: 'random', ops: ['-'], init: 5999, finish: 8999, ispan: 1999, fspan: 3999, rows: 4, coins: 10, num: 1, errors: 2, cansubstract: true }
                },
                {
                    label: 'visualizeabacus',
                    impl: 'mentalabacusfixation',
                    params: { couples: 'random', init: 1000, finish: 9999, valueinabacus: 0, rows: 4, coins: 10, num: 2, errors: 2, back: false }
                },
                {
                    label: 'baloonschall',//El reto de los globos,
                    impl: 'baloonschall',
                    params: {
                        couples: 'random', ops: ['-', '+'], 'numops': 2, init: 3000, finish: 6000, ispan: 111, fspan: 2999, rows: 4, valueinabacus: 0, 'upperlimit': 9999, coins: 10, num: 1, errors: 0,
                        levels: [{ num: 14, vel: 10 }]
                    }
                },
                {
                    label: 'mentalsumchall',
                    impl: 'mentalsumsubstraction',
                    params: { couples: 'random', ops: ['+'], init: 1111, finish: 2999, ispan: 1111, fspan: 1999, valueinabacus: 0, rows: 4, coins: 1, num: 1, config: [{ digits: 4, num: 3 }, { digits: 3, num: 2 }, { digits: 2, num: 2 }], wait: 6, errors: 0, cansubstract: true }
                },
                {
                    label: 'mentalsubchall',
                    impl: 'mentalsumsubstraction',
                    params: { couples: 'random', ops: ['-'], init: 7111, finish: 9999, ispan: 1111, fspan: 1999, valueinabacus: 0, rows: 4, coins: 1, num: 1, config: [{ digits: 4, num: 3 }, { digits: 3, num: 2 }, { digits: 2, num: 2 }], wait: 6, errors: 0, cansubstract: true }
                }
            ]
        }
    },

    /**level 9: mult 2c*/
    {
        level: 9,
        label: 'silver',
        open: true,
        units: [
            {
                uid: 25,
                label: 'multi2',
                level: 9,
                icon: 'levels/multiplicaciones_2_cifras.png',
                exercises: [
                    {
                        label: 'multi2x2',//Sumas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: '9MyTXLkdES8', coins: 10 }
                    },
                    {
                        label: 'practiceabacusI',//Practica con el ábaco,
                        impl: 'multiplydivide',
                        icon: 'practica',
                        params: { couples: 'random', ops: ['*'], init: 11, finish: 99, ispan: 11, fspan: 99, rows: 10, coins: 10, num: 5, errors: 1, cansubstract: true, markers: true }
                    },
                    {
                        label: 'multi2x3',//Sumas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'EYxvV-hf4t0', coins: 10 }
                    },
                    {
                        label: 'practiceabacusII',//Practica con el ábaco,
                        impl: 'multiplydivide',
                        icon: 'practica',
                        params: { couples: 'random', ops: ['*'], init: 111, finish: 999, ispan: 11, fspan: 99, rows: 12, coins: 10, num: 10, errors: 1, cansubstract: true, markers: true }
                    },
                ]
            },

            {
                uid: 26,
                label: 'strengthenlearnt',//Sumar y restar con el 5,
                level: 9,
                icon: 'levels/juega.png',
                exercises: [
                    {
                        label: 'challmultI',
                        impl: 'multiplydivide',
                        icon: 'reto',
                        params: { couples: 'random', ops: ['*'], only_answer: true, init: 11, finish: 99, ispan: 11, fspan: 99, rows: 4, coins: 10, num: 5, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                    },
                    {
                        label: 'challmultII',
                        impl: 'multiplydivide',
                        icon: 'reto',
                        params: { couples: 'random', ops: ['*'], only_answer: true, init: 111, finish: 999, ispan: 11, fspan: 99, rows: 5, coins: 10, num: 5, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                    },
                    {
                        label: 'baloonschallI',//El reto de los globos,
                        impl: 'baloonschall',
                        icon: 'globo',
                        params: {
                            couples: 'random', ops: ['*'], 'numops': 2, init: 11, finish: 99, ispan: 11, fspan: 99, rows: 4, valueinabacus: 0, 'upperlimit': 99 * 99, coins: 10, num: 1, errors: 0,
                            levels: [{ num: 6, vel: 15 }, { num: 8, vel: 15 }, { num: 10, vel: 15 }, { num: 12, vel: 10 }, { num: 14, vel: 10 }]
                        }
                    },
                    {
                        label: 'baloonschallII',//El reto de los globos,
                        impl: 'baloonschall',
                        icon: 'globo',
                        params: {
                            couples: 'random', ops: ['*'], 'numops': 2, init: 111, finish: 999, ispan: 11, fspan: 99, rows: 5, valueinabacus: 0, 'upperlimit': 999 * 99, coins: 10, num: 1, errors: 0,
                            levels: [{ num: 6, vel: 15 }, { num: 8, vel: 15 }, { num: 10, vel: 15 }, { num: 12, vel: 10 }, { num: 14, vel: 10 }]
                        }
                    },
                ]
            },
        ],
        test:
        {
            tid: 9,
            label: 'upgradelevel',//Acceder al nivel,
            level: 9,

            exercises: [
                {
                    label: 'practiceabacus I',//Practica con el ábaco,
                    impl: 'multiplydivide',
                    params: { couples: 'random', ops: ['*'], init: 11, finish: 99, ispan: 11, fspan: 99, rows: 13, coins: 10, num: 2, errors: 1, cansubstract: true, markers: true }
                },
                {
                    label: 'practiceabacus II',//Practica con el ábaco,
                    impl: 'multiplydivide',
                    params: { couples: 'random', ops: ['*'], init: 111, finish: 999, ispan: 11, fspan: 99, rows: 13, coins: 10, num: 2, errors: 1, cansubstract: true, markers: true }
                },
                {
                    label: 'challmult I',
                    impl: 'multiplydivide',
                    params: { couples: 'random', ops: ['*'], only_answer: true, init: 11, finish: 99, ispan: 11, fspan: 99, rows: 4, coins: 10, num: 2, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                },
                {
                    label: 'challmult II',
                    impl: 'multiplydivide',
                    params: { couples: 'random', ops: ['*'], only_answer: true, init: 111, finish: 999, ispan: 11, fspan: 99, rows: 5, coins: 10, num: 2, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                },
                {
                    label: 'baloonschall I',//El reto de los globos,
                    impl: 'baloonschall',
                    params: {
                        couples: 'random', ops: ['*'], 'numops': 2, init: 11, finish: 99, ispan: 11, fspan: 99, rows: 4, valueinabacus: 0, 'upperlimit': 99 * 99, coins: 10, num: 1, errors: 0,
                        levels: [{ num: 14, vel: 10 }]
                    }
                },
                {
                    label: 'baloonschall II',//El reto de los globos,
                    impl: 'baloonschall',
                    params: {
                        couples: 'random', ops: ['*'], 'numops': 2, init: 111, finish: 999, ispan: 11, fspan: 99, rows: 5, valueinabacus: 0, 'upperlimit': 999 * 99, coins: 10, num: 1, errors: 0,
                        levels: [{ num: 14, vel: 10 }]
                    }
                }
            ]
        }
    },

    /**level 10: div 2c*/
    {
        level: 10,
        label: 'gold',
        open: true,
        units: [
            {
                uid: 27,
                label: 'divi2',
                level: 10,
                icon: 'levels/divisiones_2_cifras.png',
                exercises: [
                    {
                        label: 'divide:2',
                        impl: 'theory',
                        icon: 'video',
                        params: { video: 'EN5aw3cRc30', coins: 10 }
                    },
                    {
                        label: 'practiceabacusI',
                        impl: 'multiplydivide',
                        icon: 'practica',
                        params: { couples: 'random', ops: ['/'], init: 111, finish: 999, ispan: 11, fspan: 99, rows: 10, coins: 10, num: 5, errors: 1, cansubstract: true, markers: true }
                    },
                    {
                        label: 'practiceabacusII',
                        impl: 'multiplydivide',
                        icon: 'practica',
                        params: { couples: 'random', ops: ['/'], init: 1111, finish: 9999, ispan: 11, fspan: 99, rows: 11, coins: 10, num: 5, errors: 1, cansubstract: true, markers: true }
                    },
                ]
            },

            {
                uid: 28,
                label: 'strengthenlearnt',//Sumar y restar con el 5,
                level: 10,
                icon: 'levels/juega.png',
                exercises: [
                    {
                        label: 'challdivI',
                        impl: 'multiplydivide',
                        icon: 'reto',
                        params: { couples: 'random', ops: ['/'], only_answer: true, init: 111, finish: 999, ispan: 11, fspan: 99, rows: 2, coins: 10, num: 5, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                    },
                    {
                        label: 'challdivII',
                        impl: 'multiplydivide',
                        icon: 'reto',
                        params: { couples: 'random', ops: ['/'], only_answer: true, init: 1111, finish: 9999, ispan: 11, fspan: 99, rows: 3, coins: 10, num: 5, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                    },
                    {
                        label: 'baloonschallI',//El reto de los globos,
                        impl: 'baloonschall',
                        icon: 'globo',
                        params: {
                            couples: 'random', ops: ['/'], 'numops': 2, init: 111, finish: 999, ispan: 11, fspan: 99, rows: 2, valueinabacus: 0, 'upperlimit': 99 / 9, coins: 10, num: 1, errors: 0,
                            levels: [{ num: 6, vel: 15 }, { num: 8, vel: 15 }, { num: 10, vel: 15 }, { num: 12, vel: 10 }, { num: 14, vel: 10 }]
                        }
                    },
                    {
                        label: 'baloonschallII',//El reto de los globos,
                        impl: 'baloonschall',
                        icon: 'globo',
                        params: {
                            couples: 'random', ops: ['/'], 'numops': 2, init: 1111, finish: 9999, ispan: 11, fspan: 99, rows: 3, valueinabacus: 0, 'upperlimit': 9999 / 9, coins: 10, num: 1, errors: 0,
                            levels: [{ num: 6, vel: 15 }, { num: 8, vel: 15 }, { num: 10, vel: 15 }, { num: 12, vel: 10 }, { num: 14, vel: 10 }]
                        }
                    },
                ]
            },
        ],
        test:
        {
            tid: 10,
            label: 'upgradelevel',//Acceder al nivel,
            level: 10,

            exercises: [
                {
                    label: 'practiceabacus I',
                    impl: 'multiplydivide',
                    params: { couples: 'random', ops: ['/'], init: 111, finish: 999, ispan: 11, fspan: 99, rows: 13, coins: 10, num: 2, errors: 1, cansubstract: true, markers: true }
                },
                {
                    label: 'practiceabacus II',
                    impl: 'multiplydivide',
                    params: { couples: 'random', ops: ['/'], init: 1111, finish: 9999, ispan: 11, fspan: 99, rows: 13, coins: 10, num: 2, errors: 1, cansubstract: true, markers: true }
                },
                {
                    label: 'challdiv I',
                    impl: 'multiplydivide',
                    params: { couples: 'random', ops: ['/'], only_answer: true, init: 111, finish: 999, ispan: 11, fspan: 99, rows: 2, coins: 10, num: 2, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                },
                {
                    label: 'challdiv II',
                    impl: 'multiplydivide',
                    params: { couples: 'random', ops: ['/'], only_answer: true, init: 1111, finish: 9999, ispan: 11, fspan: 99, rows: 3, coins: 10, num: 2, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                },
                {
                    label: 'baloonschall I',//El reto de los globos,
                    impl: 'baloonschall',
                    params: {
                        couples: 'random', ops: ['/'], 'numops': 2, init: 111, finish: 999, ispan: 11, fspan: 99, rows: 2, valueinabacus: 0, 'upperlimit': 99 / 9, coins: 10, num: 1, errors: 0,
                        levels: [{ num: 14, vel: 10 }]
                    }
                },
                {
                    label: 'baloonschall II',//El reto de los globos,
                    impl: 'baloonschall',
                    params: {
                        couples: 'random', ops: ['/'], 'numops': 2, init: 1111, finish: 9999, ispan: 11, fspan: 99, rows: 3, valueinabacus: 0, 'upperlimit': 9999 / 9, coins: 10, num: 1, errors: 0,
                        levels: [{ num: 14, vel: 10 }]
                    }
                }
            ]
        }
    }
];


const plan = {
    1: plan1,
    2: plan2,
    3: plan3,
}

export default plan;