import { useEffect, useState } from 'react';

import { Button, Col, Progress, Row } from 'antd';
import { generateRandomNumberSeries } from '../../../utils';
import AbacusView from '../../base/abacus';
import Result from '../../base/result';

import { motion } from 'framer-motion';
import { t } from '../../../languages';
import ChangeOrientation from '../../Utils/ChangeOrientation';
import ExerciseBlocker from '../../Utils/ExerciseBlocker';
import './index.less';

const RepresentInAbacus = ({ lang, params, uid, eid, saveResult, handleCancel }) => {
    const [series, setSeries] = useState([]);
    const [current, setCurrent] = useState(0);
    const [results, setResults] = useState([]);
    const [times, setTimes] = useState([]);
    const [numberinbox, setNumberinbox] = useState(0);
    const [message, setMessage] = useState(null);
    const [message2, setMessage2] = useState(null);
    const [showresults, setShowresults] = useState(false);
    const [moved, setMoved] = useState(0);

    useEffect(() => {
        /* const series = params ? generateRandomNumberSeries(params.num, params.init, params.finish, null) : generateRandomNumberSeries(9, 1, 9, null);
        // console.log('RepresentInAbacus.componentWillMount:', params, series);
        setSeries(series); */
        handleRepeat();
    }, []);

    const handleRate = () => {
        const currentNumber = series[current];
        const current_result = currentNumber === numberinbox ? 1 : 0;
        const message = current_result ? t(lang, 'rightanswer') : `${t(lang, 'yourwrong')} ${currentNumber}`;
        const message2 = current_result ? t(lang, 'great') : t(lang, 'nodiscorage');
        results.push(current_result);
        setResults(results);
        setMessage(message);
        setMessage2(message2);
    }

    const handleNext = () => {
        if (current < series.length - 1) {
            setCurrent(current + 1);
            setNumberinbox(0);
            setMessage(null);
            setMessage2(null);
            setMoved(0);
        } else {
            const num = params.num;
            const hits = results.reduce((acc, aR) => acc += aR);
            const errors = num - hits;
            const passed = errors > params.errors ? false : true;
            const coins = params.coins;

            saveResult(uid, `${uid}-${eid}`, { uid, eid, p: passed, h: hits, e: errors, c: (passed ? coins : 0) });
            setShowresults(true);
        }
    }

    const handleRepeat = () => {
        let series = [];
        const configSeries = params.series;
        // console.log('configSeries:', configSeries);
        if (configSeries) {
            for (let idx = 0; idx < configSeries.length; idx++) {
                const aSerie = configSeries[idx];
                series = series.concat(generateRandomNumberSeries(aSerie.num, aSerie.init, aSerie.finish, null));
            }
        } else {
            series = params ? generateRandomNumberSeries(params.num, params.init, params.finish, null) : generateRandomNumberSeries(9, 1, 9, null);
        }

        // console.log('serires:', series);

        setSeries(series);
        setCurrent(0);
        setNumberinbox(0);
        setResults([]);
        setMessage(null);
        setShowresults(false);
        setMoved(0);
    }

    const handleChange = (num) => {
        setNumberinbox(num);
        setMoved(1);
    }

    const currentNumber = series[current];
    const rate = results[current];
    const messagestyle = message ? (!rate ? ' error' : ' valid') : '';
    const percent = parseInt((current) / params.num * 100);

    const is_test = params.is_test ? params.is_test : false;

    const locparams = { ...params, rows: params ? params.rows : 1, number: numberinbox };
    // console.log('RepresentInAbacus.render:', props, currentNumber, params);

    if (!showresults) {
        return (
            <Row className="exercise" key="2">
                <ChangeOrientation to={'portrait'} />

                {!is_test ? <Col span={22} className='progress-bar'>
                    <Progress percent={percent} showInfo={false} />
                </Col> : null}

                <Col span={24} className="exercise-body">
                    <ExerciseBlocker show={messagestyle} />
                    <section className="representinabacus">

                        <AbacusView params={locparams} isactive={true} hidelatframe={true} hideheaderlabels={true} onChange={handleChange} />

                        <article className="number" key={currentNumber}>
                            <motion.div
                                animate={{ scale: 2 }}
                                transition={{ duration: 1 }}
                                className="the-number"
                            >{currentNumber}</motion.div>
                        </article>
                    </section>
                </Col>
                <Col span={24}>
                    <Row className={'botones' + messagestyle}>
                        <Col span={24} className={'message'}>{message}</Col>
                        {!message ? <Col span={24}><Button key="submit" type="primary" onClick={handleRate} style={{ 'width': '90%' }} disabled={!moved}>{t(lang, 'grade')}</Button></Col> : null}
                        {message ? <Col span={24}><Button key="submit" type="primary" onClick={handleNext} style={{ 'width': '90%' }}>{t(lang, 'next')}</Button></Col> : null}
                        {message2 ? <Col span={24} className={'message'}>{message2}</Col> : null}
                    </Row>
                </Col>
            </Row >
        );
    } else {
        return (
            <Result params={locparams} results={results} handleRepeat={handleRepeat} handleCancel={handleCancel} lang={lang} />
        );
    }
}

export default RepresentInAbacus;

