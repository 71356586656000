const en = {
  /* WELLCOME */
  start: "Begin",
  makeurkids: "Improve your attention and concentration with the soroban",
  funlearning: "Fun learning",
  willlearntoopperate: "Learn how to operate with numbers in a visual and fun way",
  dontworry: "Do not worry",
  secure100pc: "This application is 100% safe, without annoying banners or ads",

  /* REGISTER STEPS */
  language: "Language",
  agelevel: "Age range",
  chooseagerange: "Choose your age range to adapt the course and challanges",
  chooseanavatar: 'Choose your avatar',

  wellcome: "Welcome",
  alwaysyouwant: "Whenever you want you will receive information on your son / daughter's progress",
  yourname: "What is your name?",
  country: "Country",
  continue: "Continue",
  alwaysconnected: "Connect from any mobile or browser with your account",
  changeyourname: 'What is your new name?',

  loginacc: "Login with my account",
  readAndAccept: "I have read and accept the",
  TOS: "Terms and conditions of use.",

  morestars: "MiSoroban is not just for a single son / daughter, you can add more heros",
  howmanystars: "How many heros are they?",

  tellusname: "Tell us what we can call the stars of this adventure",
  gamer: "Player",
  nickname: "Nickname for gamer",
  age: "Age of gamer",
  other: "Other",

  loading: "Loading",
  almostthere: "We are almost there",
  preparing: "We are preparing the final details for your learning zone",

  /*SECCIONES*/
  learn: 'Learn',
  practice: 'Practice',
  challengeyourself: 'Challenge yourself',
  course: 'Course',

  /*NIVELES*/
  levels: "Levels",
  upgradelevel: "Level up",
  paper: "Paper Level",
  wood: "Wood Level",
  iron: "Iron level",
  wool: "Wool level",
  bronze: "Bronze level",
  clay: "Clay level",
  steel: "Steel Level",
  ivory: "Ivory Level",
  glass: "Crystal Level",
  silver: "Silver level",
  pearl: "Pearl Level",
  coral: "Coral Level",
  ruby: "Ruby level",
  sapphire: "Sapphire level",
  gold: "Gold level",
  emerald: "Emerald level",
  diamond: "Diamond Level",

  /*UNITS*/
  numbers1to10: "Numbers 1 to 10",
  knowtheabacus: "Meet the soroban",
  numbersinabacus: "The numbers",
  quantitygame: "Quantity game",
  realtionsgame: "Relationship game",
  idunits: "Identify numbers",
  repunits: "Perform numbers",
  visualizeabacus: "Visualize the soroban",

  easysumsandsubs: "Add and subtract",
  sumsubsboard: "Add and subtract on the board",
  practiceboard: "Practice on the board",
  sumsubsabacus: "Add and subtract on the abacus",
  practiceabacus: "Practice on the abacus",
  visualizebeads: "Beads game",

  strengthenlearnt: "Practice",
  matchchall: "Couples challenge",
  baloonschall: "Balloon challenge",
  mentalsumchall: "Mental sum challenge",
  mentalsubchall: "Mental subtraction challenge",
  mentalsumsubchall: 'Mental sum and subtraction challenge',

  sumswith5: "Add with 5",

  subswith5: "Subtract with 5",

  numberstill99: "The tens",

  sumswith10: "Add with 10",

  subswith10: "Subtract with 10",

  numberstill999: "The hundreds",

  sumsandsubswith2: "Add and subtract 2 figures",

  sumsandsubswith3: "Add and subtract 3 figures",

  multi1: "Multiply x 1 digit",

  divi1: "Divide / 1 digit",

  sumsandsubswith4: "Add and subtract 4 digits",

  multi2: "Multiply x 2 digit",

  divi2: "Divide / 2 digit",

  learnnumbers: "Learn the numbers",

  // Edad 2
  numbers1_2_3_figs: 'The numbers',
  idnumbers: "Identify numbers",
  repnumbers: "Perform numbers",

  /* 1 */
  sumboard: 'Sum on the board',
  sumabacus: 'Sum on the abacus',
  subsboard: 'Subtraction on the board',
  subsabacus: 'Subtraction on the abacus',
  sumserieschall: 'Sum Challenge',
  subsserieschall: 'Subtraction challenge',

  tens: 'Tens',
  idtens: 'Identify tens',
  reptens: 'Perform tens',
  practiceboardI: 'Simple practice on the board',
  practiceboardII: 'Advanced practice on the board',
  practiceabacusI: 'Simple abacus practice',
  practiceabacusII: 'Advanced abacus practice',

  sumserieschallI: 'Basic addition challenge',
  sumserieschallII: 'Intermediate sum challenge',
  sumserieschallIII: 'Advanced addition challenge',
  subsserieschallI: 'Basic subtraction challenge',
  subsserieschallII: 'Intermediate subtraction challenge',
  subsserieschallIII: 'Advanced Subtraction Challenge',

  hundreds: 'Hundreds',
  idhundreds: 'Identify hundreds',
  rephundreds: 'Perform hundreds',
  pathchall: 'Path challenge',
  practicesums: 'Practice additions',
  practicesubs: 'Practice substractions',

  multi1x2: 'Multiplications 1x2 figures',
  practiceabacus1x2: 'Practice 1x2 on the abacus',
  multi1x3: 'Multiplications 1x3 figures',
  practiceabacus1x3: 'Practice 1x3 on the abacus',
  challmultI: 'Basic multiplications challenge',
  challmultII: 'Advanced multiplications challenge',

  baloonschallI: 'Initial balloon challenge',
  baloonschallII: 'Intermediate balloon challenge',

  'divide2:1': 'Divisions 2:1 figures',
  'practiceabacus2:1': 'Practice 2:1 en el ábaco',
  'divide3:1': 'Divisions 3:1 figures',
  'practiceabacus3:1': 'Practice 3:1 en el ábaco',
  challdivI: 'Basic divisions challenge',
  challdivII: 'Advanced divisions challenge',

  idthous: 'Identify thousands',
  repthous: 'Perform thousands',

  'divide:2': 'Divisions between 2 figures',

  /* LOGIN */
  profile: "Profile",
  editProfile: "Edit profile",
  forgotPsw: "have you forgotten your password?",
  login: "Login",
  noId: 'Incorrect access data.',
  loginWith: "Login with",
  createProfile: "Create a user",

  email: "E-mail",
  password: "Password",

  data: 'Data',
  protagonists: 'Heros',
  logout: 'Logout',
  deleteAccount: 'Delete account',
  sureDeleteAccount: '¿Are you sure you want to delete this account?',
  yes: 'Yes',
  no: 'No',

  loginerror: 'Access data are incorrect',

  /* REMEMBER */
  wesendpass: "We have sent you a message to your email with your new password",
  recovepass: "Recover your password",

  /* GAMEZ*/
  next: "Next",
  grade: "Validate",
  ready: "Ready",

  number: "number",

  bravoin: "¡¡Bravo!!, you have overcome the challenge in",
  surpassedin: "You have overcome the challenge in",
  great: 'Great, keep it up!',
  nodiscorage: `Do not be discouraged, cheers!`,

  rightanswer: "Right answer!",
  wrong: "Wrong answer!",
  yourwrong: "Wrong answer, the number is",
  wronganswer: "Oh!, Wrong answer, the number is",
  theansweris: 'Wrong answer, the solution is',
  is: 'is',

  rightserie: 'Right serie!',

  havedone: "You have done",
  exercises: "exercises",
  havegotten: "You have gotten",
  havemade: "You have made",
  hits: "hits",
  errors: "errores",
  in: "In",
  seconds: "seconds",
  repeat: "Repeat",
  accept: "Accept",
  timeout: 'You\'ve run out of time!',
  overcomechall: 'You have overcome the challenge!',
  noovercomechall: 'You have not overcome the challenge',

  bravo: '¡¡Bravo!!',
  oops: 'Oops :-(',
  haveovercomelevel: 'You have passed the level',
  nothaveovercomelevel: 'You have not passed the level',
  watchexers: 'Show exercises',
  watchlevels: 'Show levels',

  rightpath: 'Very Well! You found the path!',
  wrongpath: 'Oh!, Wrong path. Look the right one...',

  changeorientation: 'Switch phone orientation',

  nowcloseyesandvisualize: '...now close your eyes and visualize the abacus',

  module: 'module',

  rightpos: 'Very well :) !',
  wrongpos: 'Oh!, Wrong position :/',

  verywellkeepon: 'Very good! keep it up!',
  yujumakeit: 'Yuju!! You did it :D!',
  almostyou: 'You\'re almost there!',

  /* NEXT VERSION */
  levelnoaviable: 'Level not aviable',
  levelnoaviabletxt: 'This level will be available in the next app version',
  rate: 'Rate MiSoroban',

  /* SUBSCRIPTION */
  try3days: 'Try MiSoroban for free for 3 days.',
  onallplans: 'On all plans.',
  cancelwhenyouwant: 'Cancel at any time.',
  subscribe: 'Subscribe',
  subscribetoactivate: 'Subscribe to activate',
  subscribetoactivateGoogleplay: 'Activate in Google Play',
  subscribetoactivateAppleStore: 'Activate in Apple Store',
  morethan25: 'More than 25 lessons and dozens of exercises to enjoy with the soroban.',
  startnow: 'Start now',
  autorenewable: 'Auto-renewable subscription.',
  month: 'month',
  months: 'months',
  year: 'year',
  montly: 'Monthly Access',
  quaterly: 'Quarterly Access',
  yearly: 'Annual Access',
  managesubscription: 'Manage your subscription',
  howfreetrialworks: 'How does the free trial work?',
  paymentDetailApple: 'Upon confirming the subscription, you will have a 3-day free trial period during which no charges will be made (*). After the free trial period, payment will be charged to your iTunes account. Subscriptions will automatically renew unless auto-renew is turned off at least 24 hours before the end of current period. Your account will be charged according to your plan for renewal within 24 hours prior to the end of the current period. You can manage or turn off auto- renew in your Apple ID account settings at any time after purchase.',
  onlyfirstsubscription: '(* valid only on your first subscription)',
  termsandconditions: 'Terms & Conditions',
  privacypolicy: 'Privacy policy',
  ifalreadysubscribed: 'If you are already subscribed',
  restorePurchases: 'Restore purchases',
  forparentsonly: `This area is for parents only`,
  dragshapes: `Drag the shapes in the following order`,
  airplane: 'airplane',
  automobile: 'automobile',
  cat: 'cat',
  notcorrect: 'That\'s not correct',
  tryagain: 'Try again',
  download_app: 'Download the app',


  /* PRACTICES */
  practicesumsp: 'Sums',
  practicesubstractions: 'Substractions',
  practicemultiplications: 'Multiplications',
  practicedivisions: 'Divisions',
  easysums: '1 and 2 figures',
  intermediatesums: '2 and 3 figures',
  hardsums: '3 and 4 figures',
  easysubs: '1 and 2 figures',
  intermediatesubs: '2 and 3 figures',
  hardsubs: '3 and 4 figures',
  easymults: '1 x 2 figures',
  easymults2: '1 x 3 figures',
  intermediatemults: '2 x 2 figures',
  hardmults: '2 x 3 figures',
  easydivs: '2 / 1 figures',
  easydivs2: '3 / 1 figures',
  intermediatedivs: '3 / 2 figures',
  harddivs: '4 / 2 figures',

  /* CHALLENGES */
  getmorepoints: 'Earn more points',
  completeoperations: 'Complete the operations',
  solveequation: 'Solve the equation',
  findpath: 'Find the path',
  challengevisualmem: 'Challenge your visual memory',
  findvalue: 'Discover the value',
  youearned: 'You earned',

  /* COURSE */
  numbers_sums_subtractions: 'Numbers, sums and subtractions',
  multiply_divide: 'Multiply and divide',
  numbers: 'Numbers',
  simplesumsubs: 'Simple addition and subtraction',
  sumsubwith5: 'Addition and subtraction with 5',
  sumsubwith10: 'Addition and subtraction with 10',
  sumsub2c: '2-digit addition and subtraction',
  sumsub3c: '3-digit addition and subtraction',
  mult1c: '1-digit multiplication',
  div1c: '1-digit division',
  mult2c: '2-digit multiplication',
  div2c: '2-digit division',
  welcome: 'Welcome',
  welcomefromtutor: 'Welcome from the tutor',
  presentationModule1:'Module 1 intro',
  presentationModule2:'Module 2 intro',
  module1title: 'Module 1 - Represent, identify, add and subtract',
  module1intro: `In this module you will learn to move the beads to represent numbers on the Japanese abacus, and also to add and subtract with 1, 2 and 3 digit numbers. The exercises, in addition to helping you rediscover numbers and operations with this tool, will help you work on mental calculations, attention and concentration.
  Access the course platform with your username and password. Enter the first unit of the module and after watching the training videos, practice with the exercises. When you think you are ready, access the next unit and advance at your own pace.
  At the end you will find a special unit where you can test yourself and see everything you have learned.`,
  module2title: 'Module 2 - Multiply and divide',
  module2intro: `In this module you will learn to perform different multiplications and divisions on the Japanese abacus. You will learn to estimate results and visualize the operation process.
  Access the units of this second module. Start with the first one and move on to the next one when you feel you are ready. In each unit, after watching the videos, practice with the exercises.
  The last unit will serve to test you and see everything you have learned.`,

  /* RESOURCES */
  resources: 'Teaching resources',
  howtouseresources: 'How to use these resources',
  intro: 'Intro',
  discover: 'Discover',

  /* RANKING */
  yourpoints: 'Your points',
  yourposition: 'Your position',
  top10ranking: 'Top ranking', //'Top 10 ranking',

  /* REVIEW */
  issatisfied: 'Are you enjoying MiSoroban?',
  wannarate: 'Great! We would love for you to rate us.',
  howcanimprove: 'We are sorry to hear that. How can we improve?',
  tellushowimprove: 'Type your response...',
  thanksforyourfeedback: 'Thank you for your feedback!',
  ok: 'OK',
  ratenow: 'Rate Now',
  later: 'Later',
  send: 'Send',

  /* DAILY GOAL */
  dailygoal: 'Daily goal',
  hi: 'Hi',
  selectdailygoal: 'Select a daily goal to keep your brain active.',
  done: 'Done!',
  dailygoalreached: 'Daily goal reached!!',
  youreachurdailygoal: 'you have achieved your daily goal',
  youwon: 'You have won',
  match: 'Match',
  youhavebeenworking: 'You have been working',
  outof: 'out of',
  keepon: 'Keep it up!',
  halfway: 'You\'re already halfway there!',
  almosthere: 'You\'re almost there!',

  /* SHARE */
  sharemis: 'Share MiSoroban',
};

export default en;